import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import NumberFormat from 'react-number-format'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import RiepilogoEseguiti from '../components/RiepilogoEseguiti'
import amnp from '../utils/array_ma_non_posso'
import Tooltip from '@material-ui/core/Tooltip'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close';

function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    );
  }

class Trattamenti extends Component {
    state = {
        trattamenti_paziente: [],
        consensi_paziente: [],
        value: 'tutti'
    }
    
      

    loadTrattamenti = (value) => {
        const url_trattamenti_paziente = `${process.env.REACT_APP_API_URL}/dc/trattamenti_paziente/${this.props.paziente.id}/${value}`
        console.log(url_trattamenti_paziente)  
        fetch(url_trattamenti_paziente, {
            headers: {
              Authorization: `JWT ${localStorage.getItem('token')}`
            }
          })
              .then(result => result.json())
              .then(result => {
                this.setState({
                  trattamenti_paziente: result.results
                })
            })
    }

    loadConsensi = (value) => {
        const url_consensi_paziente = `${process.env.REACT_APP_API_URL}/dc/consensi_paziente/${this.props.paziente.id}/`

        fetch(url_consensi_paziente, {
            headers: {
              Authorization: `JWT ${localStorage.getItem('token')}`
            }
          })
              .then(result => result.json())
              .then(result => {
                this.setState({
                  consensi_paziente: result.results
                })
            })
    }

    componentDidMount() {
        this.loadTrattamenti(this.state.value);
        this.loadConsensi();
    }

    componentDidUpdate(propsPrecedenti) {
      if (this.props.paziente_id !== propsPrecedenti.paziente_id) {
        this.loadTrattamenti(this.state.value);
        this.loadConsensi();
      }
    }

    handleChange = (event, value) => {
        this.loadTrattamenti(value)
        this.setState({ value })
    }

    eseguito = (id, esecuzione_id) => {
        const url_eseguito = `${process.env.REACT_APP_API_URL}/dc/trattamento_eseguito/${id}/${esecuzione_id}` 
          fetch(url_eseguito, {
            headers: {
              Authorization: `JWT ${localStorage.getItem('token')}`
            }
          })
              .then(result => {
                this.loadTrattamenti(this.state.value)
            })
    }

    annulla = (id) => {
      const url_annulla = `${process.env.REACT_APP_API_URL}/dc/trattamento_annulla/${id}` 
        fetch(url_annulla, {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`
          }
        })
            .then(result => {
              this.loadTrattamenti(this.state.value)             
          })
  }

    clickPiano = (piano_id) => {
        this.props.clickPianoUtente(piano_id)
        return false
    }


    getDataAppuntamento(tratt) {
      let da = undefined
      tratt.stati_esecuzione.map((se) => {
        if(se.stato === "Eseguito" && se.data_appuntamento !== null && se.data_appuntamento !== undefined) {
          da = se.data_appuntamento
        }
      })
      return da
    }



    render() {
        const { value }  = this.state
    return(
        <div className="trattamenti">
            <Paper elevation={5} style={{ margin: '0 15px 5px 0' }}>
                  <Tabs value={value} onChange={this.handleChange}>
                    <Tab value='tutti' label="Trattamenti"/>
                    <Tab value='da_eseguire' label="Trattamenti da eseguire"/>
                    <Tab value='eseguiti' label="Trattamenti eseguiti"/>
                    <Tab value='parziali' label="Eseguiti parziali"/>
                    <Tab value='consensi' label="Consensi"/>
                  </Tabs>
            </Paper>
            <Paper elevation={5} style={{ margin: '0 15px 5px 0' }}>
            {value === 'tutti' && <TabContainer>
            {this.state.trattamenti_paziente.length === 0 && <h2>Nessun trattamento</h2>}
            {this.state.trattamenti_paziente.length > 0 && <h2>Elenco trattamenti</h2>}
            <Table dense={false}>
                <TableRow>
                    <TableCell>Piano di cure</TableCell>
                    <TableCell>Elementi</TableCell>
                    <TableCell>Descrizione</TableCell>
                    <TableCell>Importo</TableCell>
                    {/*<TableCell>Appuntamenti</TableCell>
                    <TableCell>&nbsp;</TableCell>*/}
                    <TableCell>&nbsp;</TableCell>
                </TableRow>
                {this.state.trattamenti_paziente.map((item) => {
                  let data_esecuzione = undefined

                 console.log("TATTAMENTI", JSON.stringify(item));
                  
                  let prodotto = ""
                  if(item.prodotto_assicurativo !== null && item.prodotto_assicurativo !== undefined) {  
                    prodotto = " - " + item.prodotto_assicurativo.nome
                  }
                  let stile = {}
                  if (item.annullato) {
                    stile = {
                      color: "#888888",
                      "text-decoration": "line-through"
                    }
                  }

                  return (<TableRow style={stile}>
                    <TableCell>
                    <Link onClick={() => { this.clickPiano(item.piano_di_cura.id) }}>{item.piano_di_cura.nome} #{item.piano_di_cura.id}</Link>
                    </TableCell>    
                    <TableCell>
                    {item.elementi_eseguiti.length > 0 &&<Tooltip title={"Eseguito: " + item.date_esecuzione}><small style={{color: "#008500"}}>{amnp.join(item.elementi_eseguiti, ", ")}&nbsp;</small></Tooltip>}

                    {item.elementi_da_eseguire.length > 0 &&<Tooltip title={"Da eseguire"}><small style={{color: "#A60000"}}>{amnp.join(item.elementi_da_eseguire, ", ")}&nbsp;</small></Tooltip>}
                    {item.eseguiti_parziali.length > 0 && <Tooltip title={"Eseguiti parziali"}><small style={{color: "#FFA500"}}>({amnp.join(item.eseguiti_parziali, ", ")})</small></Tooltip>}
                    </TableCell>
                    <TableCell>
                      {item.trattamento.nome} {prodotto}<br/>
                      <small>aggiunto {new Intl.DateTimeFormat('it-IT', { 
                              year: 'numeric', 
                              month: '2-digit', 
                              day: '2-digit',
                              timeZone: 'Europe/Rome'
                            }).format(new Date(item.create_ts))}</small>
                    </TableCell>
                    
                    <TableCell align="right">
                      <NumberFormat value={item.prezzo_scontato} displayType={'text'} fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                    {/*<TableCell>
                      <small>nessun<br/>appuntamento</small>
                    </TableCell>
                    
                    <TableCell align="right">
                      {item.stati_esecuzione[0].stato === "Eseguito" &&
                        <Button variant="contained" color="secondary" onClick={() => { this.eseguito(item.id, item.stati_esecuzione[0].id) }}>Annulla Eseguito</Button>}
                      {item.stati_esecuzione[0].stato === "Da eseguire" &&
                        <Button variant="contained" color="default" onClick={() => { this.eseguito(item.id, item.stati_esecuzione[0].id) }}>Segnala Eseguito</Button>}  
                    </TableCell>
                    */}
                    <TableCell align="right">
                      {!item.annullato && (item.elementi_eseguiti.length === 0 || item.elementi_da_eseguire.length > 0) &&
                        <Button variant="contained" color="default" onClick={() => { this.annulla(item.id) }}>Annulla</Button>}  
                        {item.annullato &&
                        <Button variant="contained" color="default" onClick={() => { this.annulla(item.id) }}>Ripristina</Button>}
                        {(item.lista_fatture && item.lista_fatture.length > 0) && <Tooltip title={"Lista Fatture"}><small style={{color: "#008500", 'font-weight': 'bold'}}>Fatt. {amnp.join(item.lista_fatture, ", ")}</small></Tooltip>}
                    </TableCell>
                  </TableRow>)
                  }
                )
                }
              </Table>
              <RiepilogoEseguiti paziente_id={this.props.paziente_id} />
              </TabContainer>}




                {value === 'da_eseguire' && <TabContainer>
            {this.state.trattamenti_paziente.length === 0 && <h2>Nessun trattamento da eseguire</h2>}
            {this.state.trattamenti_paziente.length > 0 && <h2>Elenco trattamenti da eseguire</h2>}
            <Table dense={true}>
            <TableRow>
                    <TableCell>Piano di cure</TableCell>
                    <TableCell>Elementi</TableCell>
                    <TableCell>Descrizione</TableCell>
                    <TableCell>Importo</TableCell>
                    {/*<TableCell>Appuntamenti</TableCell>
                    <TableCell>&nbsp;</TableCell>*/}
                    <TableCell>&nbsp;</TableCell>
                </TableRow>
                {this.state.trattamenti_paziente.filter(item => item.elementi_da_eseguire.length > 0).map((item) => {
                  item.applicato_a = item.elementi_da_eseguire
                  let prodotto = ""
                  
                  if(item.prodotto_assicurativo !== null && item.prodotto_assicurativo !== undefined) {  
                    prodotto = " - " + item.prodotto_assicurativo.nome
                  }
                  let stile = {}
                  if (item.annullato) {
                    stile = {
                      color: "#888888",
                      "text-decoration": "line-through"
                    }
                  }  
                  return (<TableRow style={stile}>
                    <TableCell>
                        <Link onClick={() => { this.clickPiano(item.piano_di_cura.id) }}>{item.piano_di_cura.nome} #{item.piano_di_cura.id}</Link>
                    </TableCell>  
                    <TableCell>
                      {item.elementi_da_eseguire.length > 0 &&<Tooltip title={"Da eseguire"}><small style={{color: "#A60000"}}>{amnp.join(item.elementi_da_eseguire, ", ")}&nbsp;</small></Tooltip>}
                      {item.eseguiti_parziali.length > 0 && <Tooltip title={"Eseguiti parziali"}><small style={{color: "#FFA500"}}>({amnp.join(item.eseguiti_parziali, ", ")})</small></Tooltip>}
                    </TableCell>
                    <TableCell>
                      {item.trattamento.nome} {prodotto}<br/>
                      <small>aggiunto {new Intl.DateTimeFormat('it-IT', { 
                              year: 'numeric', 
                              month: 'long', 
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: false,
                              timeZone: 'Europe/Rome'
                            }).format(new Date(item.create_ts))}</small>
                    </TableCell>
                    
                    <TableCell align="right">
                      <NumberFormat value={item.importo_da_eseguire} displayType={'text'} fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                    {/*<TableCell>
                      <small>nessun<br/>appuntamento</small>
                    </TableCell>
                    <TableCell align="right">
                    {item.stati_esecuzione[0].stato === "Da eseguire" &&
                      <Button variant="contained" color="default" onClick={() => { this.eseguito(item.id, item.stati_esecuzione[0].id) }}>Segnala Eseguito</Button>
                    }
                    </TableCell>*/}
                    <TableCell align="right">
                      {!item.annullato && (item.elementi_eseguiti.length === 0 || item.elementi_da_eseguire.length > 0) &&
                        <Button variant="contained" color="default" onClick={() => { this.annulla(item.id) }}>Annulla</Button>}  
                        {item.annullato &&
                        <Button variant="contained" color="default" onClick={() => { this.annulla(item.id) }}>Ripristina</Button>}
                        {(item.lista_fatture && item.lista_fatture.length > 0) && <Tooltip title={"Lista Fatture"}><small style={{color: "#008500", 'font-weight': 'bold'}}>Fatt. {amnp.join(item.lista_fatture, ", ")}</small></Tooltip>}
                    </TableCell>
                  </TableRow>)
                  }
                )
                }
              </Table>
              <RiepilogoEseguiti paziente_id={this.props.paziente_id} />
              </TabContainer>}

              {value === 'eseguiti' && <TabContainer>
            {this.state.trattamenti_paziente.length === 0 && <h2>Nessun trattamento eseguito</h2>}
            {this.state.trattamenti_paziente.length > 0 && <h2>Elenco trattamenti eseguiti</h2>}
            <Table dense={false}>
                <TableRow>
                    <TableCell>Piano di cure</TableCell>
                    <TableCell>Elementi</TableCell>
                    <TableCell>Descrizione</TableCell>
                    <TableCell>Importo</TableCell>
                    {/*<TableCell>Appuntamenti</TableCell>
                    <TableCell>&nbsp;</TableCell>*/}
                    <TableCell>&nbsp;</TableCell>
                </TableRow>
                {this.state.trattamenti_paziente.filter(item => item.elementi_eseguiti.length > 0).map((item) => {
                  let prodotto = ""
                  item.applicato_a = item.elementi_eseguiti
                  if(item.prodotto_assicurativo !== null && item.prodotto_assicurativo !== undefined) {  
                    prodotto = " - " + item.prodotto_assicurativo.nome
                  }
                  let stile = {}
                  if (item.annullato) {
                    stile = {
                      color: "#888888",
                      "text-decoration": "line-through"
                    }
                  }  
                  return (<TableRow syle={stile}>
                    <TableCell>
                    <Link onClick={() => { this.clickPiano(item.piano_di_cura.id) }}>{item.piano_di_cura.nome} #{item.piano_di_cura.id}</Link>
                    </TableCell>    
                    <TableCell>
                    {item.elementi_eseguiti.length > 0 &&<Tooltip title={"Eseguito: " + item.date_esecuzione}><small style={{color: "#008500"}}>{amnp.join(item.elementi_eseguiti, ", ")}</small></Tooltip>}
                    {item.eseguiti_parziali.length > 0 && <Tooltip title={"Eseguiti parziali"}><small style={{color: "#FFA500"}}>&nbsp;({amnp.join(item.eseguiti_parziali, ", ")})</small></Tooltip>}
                    </TableCell>
                    <TableCell>
                      {item.trattamento.nome} {prodotto}<br/>
                      <small style={{color: "#008500"}}>Eseguito: {item.date_esecuzione}</small>
                    </TableCell>
                    
                    <TableCell align="right">
                      <NumberFormat value={item.importo_eseguito} displayType={'text'} fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                    {/*<TableCell>
                      <small>nessun<br/>appuntamento</small>
                    </TableCell>
                    <TableCell align="right">
                        <Button variant="contained" color="secondary" onClick={() => { this.eseguito(item.id, item.stati_esecuzione[0].id) }}>Annulla Eseguito</Button>
                    </TableCell>*/}
                    <TableCell>
                    {(item.lista_fatture && item.lista_fatture.length > 0) && <Tooltip title={"Lista Fatture"}><small style={{color: "#008500", 'font-weight': 'bold'}}>Fatt. {amnp.join(item.lista_fatture, ", ")}</small></Tooltip>}
                    </TableCell>
                  </TableRow>)
                  }
                )
                }
              </Table>
              <RiepilogoEseguiti paziente_id={this.props.paziente_id} />
              </TabContainer>}

              {value === 'parziali' && <TabContainer>
            {this.state.trattamenti_paziente.length === 0 && <h2>Nessun trattamento eseguito parzialmente</h2>}
            {this.state.trattamenti_paziente.length > 0 && <h2>Elenco trattamenti eseguiti parzialmente</h2>}
            <Table dense={false}>
                <TableRow>
                    <TableCell>Piano di cure</TableCell>
                    <TableCell>Elementi</TableCell>
                    <TableCell>Descrizione</TableCell>
                    <TableCell>Importo eseguito</TableCell>
                    {/*<TableCell>Appuntamenti</TableCell>
                    <TableCell>&nbsp;</TableCell>*/}
                    <TableCell>&nbsp;</TableCell>
                </TableRow>
                {this.state.trattamenti_paziente.filter(item => item.eseguiti_parziali.length > 0).map((item) => {
                  let prodotto = ""
                  item.applicato_a = item.eseguiti_parziali
                  if(item.prodotto_assicurativo !== null && item.prodotto_assicurativo !== undefined) {
                    prodotto = " - " + item.prodotto_assicurativo.nome
                  }
                  let stile = {}
                  if (item.annullato) {
                    stile = {
                      color: "#888888",
                      "text-decoration": "line-through"
                    }
                  }
                  return (<TableRow syle={stile}>
                    <TableCell>
                    <Link onClick={() => { this.clickPiano(item.piano_di_cura.id) }}>{item.piano_di_cura.nome} #{item.piano_di_cura.id}</Link>
                    </TableCell>
                    <TableCell>
                    {item.eseguiti_parziali.length > 0 && <Tooltip title={"Eseguiti parziali"}><small style={{color: "#FFA500"}}>&nbsp;{amnp.join(item.eseguiti_parziali, ", ")}</small></Tooltip>}
                    </TableCell>
                    <TableCell>
                        <b>{item.trattamento.nome} {prodotto}</b><br/>
                      Da fare: {amnp.join(item.da_fare, ", ")}
                    </TableCell>

                    <TableCell align="right">
                      <NumberFormat value={item.importo_eseguito} displayType={'text'} fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                    {/*<TableCell>
                      <small>nessun<br/>appuntamento</small>
                    </TableCell>
                    <TableCell align="right">
                        <Button variant="contained" color="secondary" onClick={() => { this.eseguito(item.id, item.stati_esecuzione[0].id) }}>Annulla Eseguito</Button>
                    </TableCell>*/}
                    <TableCell>
                    {(item.lista_fatture && item.lista_fatture.length > 0) && <Tooltip title={"Lista Fatture"}><small style={{color: "#008500", 'font-weight': 'bold'}}>Fatt. {amnp.join(item.lista_fatture, ", ")}</small></Tooltip>}
                    </TableCell>
                  </TableRow>)
                  }
                )
                }
              </Table>
              <RiepilogoEseguiti paziente_id={this.props.paziente_id} />
              </TabContainer>}

              {value === 'consensi' && <TabContainer>
            {this.state.consensi_paziente.length === 0 && <h2>Nessun consenso</h2>}
            {this.state.consensi_paziente.length > 0 && <h2>Elenco consensi</h2>}
            <Table dense={false}>
                <TableRow>
                    <TableCell>Data documento</TableCell>
                    <TableCell>Trattamenti</TableCell>
                    <TableCell>Firmato</TableCell>
                    <TableCell>Archiviato</TableCell>
                    <TableCell>&nbsp;</TableCell>
                </TableRow>
                {this.state.consensi_paziente.map((item) => {
                  return (<TableRow>
                    <TableCell>
                        {new Intl.DateTimeFormat('it-IT', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                              timeZone: 'Europe/Rome'
                            }).format(new Date(item.data))}
                    </TableCell>
                    <TableCell>
                        {item.esecuzione_trattamenti.map((cons) => {
                            return (<span>{cons.str_consenso}<br/></span>)
                        })}
                    </TableCell>
                    <TableCell style={{'text-align': 'center'}}>
                        {item.ts_upload && <CheckIcon style={{color: 'green'}}/>}
                        {!item.ts_upload && <CloseIcon style={{color: 'red'}}/>}
                    </TableCell>
                    <TableCell style={{'text-align': 'center'}}>
                        {item.ts_archiviazione && <CheckIcon style={{color: 'green'}}/>}
                        {!item.ts_archiviazione && <CloseIcon style={{color: 'red'}}/>}
                    </TableCell>
                    <TableCell>
                        <a href={`${process.env.REACT_APP_API_URL}/dc/stampa_consenso/${item.id}.pdf`} target="_blank" download>scarica documento</a>
                    </TableCell>
                  </TableRow>)
                  }
                )
                }
              </Table>
              <RiepilogoEseguiti paziente_id={this.props.paziente_id} />
              </TabContainer>}
              
              </Paper>
        </div>
    )
    }
      

}
export default Trattamenti;
