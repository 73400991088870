import React, { Fragment, useState } from 'react'
import {
    Button,
 } from '@material-ui/core/'
import Fab from '@material-ui/core/Fab'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import { SingleSelect } from "react-select-material-ui"
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import history from '../components/history'
import Container from '@material-ui/core/Container'

import {Add, Edit} from '@material-ui/icons'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers"
import "moment/locale/it"
import api from "../api_calls/api"
import { withSnackbar } from 'notistack'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress';



class DialogModuli extends React.Component {
    state = {
        open: false,
        validate: false,
        paziente: undefined,
        allsets: undefined,
        altri: [],
        scelti: [],
        inUpload: false
    }



    constructor(props) {
        super(props)
        console.log("constructor...");

      }

      componentDidMount () {
        console.log("mount...")
      }

      componentDidUpdate (old) {
        console.log("update...")
      }

afterSuccess() {
    try {
        if(this.props.afterSave) this.props.afterSave();
    } catch (e) {
        console.log('Errore afterSuccess: ' + JSON.stringify(e));
    }
    this.setState({altri: [], selectedFile: undefined, inUpload: false}, this.props.toggleOpen);
}

getTrattamenti() {
        let res = [];

        return res;
}

  statoModulo(paziente, tipo) {
        let res = {
            caricato: false,
            archiviato: false
        }
        if(!paziente) return res;
        for(let k=0; k < paziente.moduli.length; ++k) {
            const m = paziente.moduli[k];
            if (m.tipo === tipo) {
                res = {
                    caricato: (m.ts_upload != null),
                    archiviato: (m.ts_archiviazione != null)
                }
            }
        }
        return res;
  }


  salvaFile() {
      const paziente = this.props.paziente;
      const url = `${process.env.REACT_APP_API_URL}/dc/carica_moduli/${paziente.id}/`
      this.setState({
          inUpload: true
      });
      let method = 'POST'
      const data = new FormData()
      data.append('file', this.state.selectedFile);
      data.append('altri', this.state.altri);
      api.call_multipart_post(
          url,
          data,
          (res) => {
            this.props.enqueueSnackbar(`Modulo caricato.`, {variant:  'success', autoHideDuration: 4000})
            this.afterSuccess();
        },
        (err) => {
          try {
            let msg = err.response.data.split("\n")[1];
            if(msg === undefined) {
                msg = 'Il codice a barre atteso non è stato trovato.'
            }
            this.props.enqueueSnackbar(`Errore salvataggio documento: ${msg}`, {variant:  'error', autoHideDuration: 8000})
          } catch(e) {
            this.props.enqueueSnackbar(`CATCH Errore salvataggio dati: ${e}`, {variant:  'error', autoHideDuration: 8000})
          }
          this.setState({inUpload: false});
        },
        method)

    }


  handleChangeCons = (event) => {
        let altri = this.state.altri;
        if(event.target.checked) {
            altri.push(event.target.value);
        } else {
            altri = altri.filter((item) => { return (item != event.target.value) });
        }
        this.setState({ altri: altri});
  };

  handleChangeDate(date) {
    try {
          this.setState({
            pdc: {
                ...this.state.pdc,
                'data_firma_paziente': date.format('YYYY-MM-DD')
            }
        })
    } catch(e) {
      ;
    }
  }


  onChangeHandler = (event) => {
    const name = event.target.files[0] && event.target.files[0].name;
    if (!name) {
        return;
    }
    if(name && !name.toLowerCase().endsWith(".pdf")) {
        this.props.enqueueSnackbar(`Sono consentiti solo file pdf.`, {variant:  'error', autoHideDuration: 4000});
        return;
    }
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    });
  }

  render() {

  const {
    paziente
  } = this.props

      let sels = '';
      const trats = this.getTrattamenti();
      for (let k=0; k < trats.length; ++k) {
          sels += '-' + trats[k].id;
      }

      const stato_informativa = this.statoModulo(paziente, 'informativa');
      const stato_anamnestica = this.statoModulo(paziente, 'anamnestica');
      const stato_accettazione = this.statoModulo(paziente, 'accettazione');


  return (
    <Dialog open={this.props.open}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'it'}>
              <DialogTitle id="form-dialog-title">Moduli {paziente?.cognome} {paziente?.nome}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Carica moduli
                </DialogContentText>
                  <form method="post" action="#" id="#">
                  <div className="row">
                      <div className="offset-md-3 col-md-6">

                          {!this.state.inUpload && <div className="form-group files">
                                  <div style={{
                                      position: "relative",
                                      overflow: "hidden",
                                      width: "100%",
                                      border: "3px dashed  #008500",
                                      "text-align": "center",
                                      padding: "70px 0",
                                      color: "#008500"
                                  }}>Clicca o trascina file
                                      {this.state.selectedFile &&
                                      <><br/><span style={{color: "#000"}}><b>File scelto: {this.state.selectedFile.name}</b></span></>}
                                  <input  style={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      "min-width": "100%",
                                      "min-height": "100%",
                                      "font-size": "100px",
                                      "text-align": "right",
                                      filter: "alpha(opacity=0)",
                                      opacity: 0,
                                      outline: "none",
                                      background: "white",
                                      cursor: "inherit",
                                      display: "block"
                                  }} type="file" accept="application/pdf" id="file_id" className="form-control" multiple="" onChange={this.onChangeHandler} />
                                  </div>
                              </div>}
                          {this.state.inUpload && <div className="form-group files"><div style={{
                                      position: "relative",
                                      overflow: "hidden",
                                      width: "100%",
                                      border: "3px dashed  #008500",
                                      "text-align": "center",
                                      padding: "60px 0",
                                      color: "#008500"
                          }}><CircularProgress color="green" /><br/>Attendi il caricamento...</div></div>}
                      </div>
                  </div>
                  </form><br/><br/>
                  <table border={1}>
                      <tr>
                          <th>modulo</th><th>caricamento</th><th>archviazione</th><th>&nbsp;</th>
                      </tr>
                      <tr>
                        <td border={1}>accettazione</td>

                            {stato_accettazione.caricato && <td>caricato</td>}
                            {!stato_accettazione.caricato && <td>da caricare</td>}

                            {stato_accettazione.archiviato && <td>archiviato</td>}
                            {!stato_accettazione.archiviato && <td>da archiviare</td>}

                        <td><a target='_blank' href={ process.env.REACT_APP_API_URL + '/dc/download_moduli/accettazione_' + paziente?.id + '.pdf'}  color="primary" download>
                          scarica
                        </a></td>
                      </tr>
                      <tr>
                        <td>anamnestica</td>
                          {stato_anamnestica.caricato && <td>caricato</td>}
                          {!stato_anamnestica.caricato && <td>da caricare</td>}

                          {stato_anamnestica.archiviato && <td>archiviato</td>}
                          {!stato_anamnestica.archiviato && <td>da archiviare</td>}

                        <td><a target='_blank' href={ process.env.REACT_APP_API_URL + '/dc/download_moduli/anamnestica_' + paziente?.id + '.pdf'}  color="primary" download>
                          scarica
                        </a></td>
                      </tr>
                      <tr>
                        <td>informativa</td>
                        {stato_informativa.caricato && <td>caricato</td>}
                        {!stato_informativa.caricato && <td>da caricare</td>}

                        {stato_informativa.archiviato && <td>archiviato</td>}
                        {!stato_informativa.archiviato && <td>da archiviare</td>}

                        <td><a target='_blank' href={ process.env.REACT_APP_API_URL + '/dc/download_moduli/informativa_' + paziente?.id + '.pdf'}  color="primary" download>
                          scarica
                        </a></td>
                      </tr>
                  </table>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => { this.setState({altri: [], selectedFile: undefined}, this.props.toggleOpen); }} variant="contained" color="secondary">
                  Chiudi
                </Button>

                <Button variant="contained" disabled={this.state.selectedFile === undefined || this.state.selectedFile === null || this.state.inUpload} onClick={() => { this.salvaFile() }} color="primary">
                  Salva
                </Button>
              </DialogActions>

      </MuiPickersUtilsProvider>
    </Dialog>
  )}
}

export default withSnackbar(DialogModuli)
