import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import NumberFormat from 'react-number-format'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import RiepilogoEseguiti from '../components/RiepilogoEseguiti'
import { SingleSelect } from "react-select-material-ui"
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Checkbox from '@material-ui/core/Checkbox'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ListIcon from '@material-ui/icons/List'
import CreateIcon from '@material-ui/icons/Edit'
import PdfIcon from '@material-ui/icons/PictureAsPdf'
import PreviewPdf from '../components/PreviewPdf'
import PreviewImg from '../components/PreviewImg'
import VisibilityIcon from '@material-ui/icons/Visibility'
import MomentUtils from '@date-io/moment'
import Container from '@material-ui/core/Container'
import api from '../api_calls/api'
import { withStyles } from '@material-ui/styles'
import { withSnackbar } from 'notistack'

const styles = {
  root: {
    "font-size": "8pt",
  },
};

function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    );
  }

class FatturaSingola extends Component {
    state = {
        curr_fattura: undefined,
        in_preview: false,
        edit: false,
        id_fattura: undefined,
        metodi_pagamento: [],
        paziente: {}
    }  

    loadFattura = (fattura_id) => {
        const url_fattura = `${process.env.REACT_APP_API_URL}/dc/fattura_paziente/${fattura_id}/`
          api.call_get(url_fattura,(result) => {
                this.setState({
                  curr_fattura: result,
                  in_preview: false
                })
                this.loadPaziente(result.paziente_id)
            })
            
    }
    
    loadPaziente(paziente_id) {
        const url_paziente = `${process.env.REACT_APP_API_URL}/dc/api_paziente/${paziente_id}`
          api.call_get(url_paziente,(result) => {
                this.setState({
                  paziente: result.results[0]
                })
            })
    }

    
    loadMetodi = () => {
      const url_metodi = `${process.env.REACT_APP_API_URL}/dc/metodi_pagamento/`
        fetch(url_metodi, {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`
          }
        })
            .then(result => result.json())
            .then(result => {
              this.setState({
                metodi_pagamento: result.results
              })
          })
    }  

    componentDidMount() {
        this.loadMetodi()
        if (this.props.match && this.props.match.params && this.props.match.params.fattura_id) {
            const fattura_id = this.props.match.params.fattura_id
            this.loadFattura(fattura_id)
            
        }
    }

    componentDidUpdate(propsPrecedenti) {
      if (this.props.paziente !== propsPrecedenti.paziente) {
        this.loadMetodi()
        this.loadFattura()
      }
    }

    setPreview = () => {
        this.setState({
          in_preview: !this.state.in_preview
        })
    }

    
    getImporto(curr_fattura, con_acconti) {
      let res = 0.0
      try {
          curr_fattura.voci.map((row) => {
            res += parseFloat(row.importo)
          })
          if (con_acconti) {
            curr_fattura.anticipi.map((row) => {
              res -= parseFloat(row.importo)
            })
          }
      } catch (e) {;}
      return res
    }

    urlPdf() {
      if (this.state.curr_fattura !== undefined) {
        const url_pdf = `${process.env.REACT_APP_API_URL}/dc/stampa_fattura/${this.state.curr_fattura.id}.pdf`
        console.log(url_pdf)
        return url_pdf
      }
      return ""
    }

    getMetodoPagamento(id) {
      for (let k = 0; k < this.state.metodi_pagamento.length; ++k) {
          if(this.state.metodi_pagamento[k].id == id) {
            return this.state.metodi_pagamento[k]
          }
      }
      return undefined
    }      

    render() {
      const { classes } = this.props
        const moment = new MomentUtils()
        const { curr_fattura }  = this.state
        const { in_preview }  = this.state
        const { metodi_pagamento } = this.state
        const { edit } = this.state
        const { paziente }  = this.state
        const url_pdf = this.urlPdf()
        const nomeSede = api.sedeLogin()
        
        const options_metodi = metodi_pagamento.map((row) => {
          return ({
                value: row.id,
                label: row.nome
              })
        })
    return(
      <Container component="main" maxWidth="md">
            {curr_fattura !== undefined && <Grid container spacing={12}>
            <Grid item xs={10}>
            {curr_fattura.id === undefined && <h5>Nuova fattura <span style={{color: "#A60000"}}>SEDE: {nomeSede}</span></h5>}
            {curr_fattura.id !== undefined &&  <h5>Fattura #{curr_fattura.numero_fattura}</h5>}
            </Grid>
            <Grid item xs={2} align="right">
              <Button onClick={() => { this.setPreview() }} variant="contained" color="default"><PdfIcon/></Button>
            </Grid>
            {(curr_fattura.id === undefined || !in_preview) && <>
            <Grid item xs={3}>
              <Paper style={{padding: "8px"}}>
                <Typography variant="subtitle1">Destinatario: </Typography>
                <Typography variant="body1">
                  {paziente.cognome} {paziente.nome}<br/>
                  { paziente.indirizzo_residenza || 'indirizzo residenza' }<br/>
                  {paziente.cap || 'CAP'} {paziente.citta_residenza_testo_libero || 'citta'}<br/>
                  Codice Fiscale: {paziente.nic || 'codice fiscale'}
                </Typography>
              </Paper>  
            </Grid>
            <Grid item xs={9}>
            <Paper style={{padding: "8px", "margin-left": "12px"}}>
              <FormLabel component="legend">Tipo Fattura</FormLabel>
              {edit && <RadioGroup
                aria-label=""
                name="tipo_fattura"
                className={classes.group}
                
                onChange={(val)=> {this.handleTipo(val)}}
                row
              >
              <FormControlLabel value="SALDO" control={<Radio />} label="Saldo" />
              <FormControlLabel value="ACCONTO" control={<Radio />} label="Acconto" />  
              </RadioGroup>}
              {!edit && curr_fattura.tipo_fattura}
            
            </Paper>
            </Grid>    
            {curr_fattura.voci.length === 0 && <h4>Nessuna voce</h4>}
            {curr_fattura.voci.length > 0 && <h4>Voci Fattura:</h4>}
            <Table dense={true} border={0}>
            {curr_fattura.voci.length > 0 && <>
            <TableRow border={0}>
                <TableCell border={0}><b>VOCI</b></TableCell>
                <TableCell border={0} align="right"><b>IMPORTI</b></TableCell>
                <TableCell></TableCell>
            </TableRow>
            {curr_fattura.voci && curr_fattura.voci.map((voce, idx) => {
                  const descrizione_display = [voce.descrizione , <br/>, <small>{voce.descrizione_piano}</small>]
                  return (<TableRow>
                    <TableCell>
                    {edit && voce.tipo_voce !== "TRATTAMENTO" &&  
                    <TextField 
                    style={{width: "100%"}}
                    value={voce.descrizione} 
                    onChange={(evt)=> {this.cambiaDescr(evt.target.value, idx)}}
                    />
                    }
                    {!edit && voce.tipo_voce !== "TRATTAMENTO" && voce.descrizione}
                    {voce.tipo_voce === "TRATTAMENTO" && descrizione_display}
                    </TableCell>    
                    <TableCell align="right">
                    {edit && voce.tipo_voce !== "TRATTAMENTO" &&  
                    <NumberFormat 
                    value={voce.importo} 
                    defaultValue={0.00}
                    allowEmptyFormatting={true}    
                    customInput={TextField} 
                    fixedDecimalScale={2} 
                    decimalScale={2} 
                    suffix={' EUR'}
                    onValueChange={(values)=> {this.cambiaImporto(values.floatValue, idx)}}
                    />}
                    {!edit && voce.tipo_voce !== "TRATTAMENTO" &&  
                    <NumberFormat 
                    value={voce.importo} 
                    displayType={'text'}
                    fixedDecimalScale={2} 
                    decimalScale={2} 
                    suffix={' EUR'}
                    />}
                    {voce.tipo_voce === "TRATTAMENTO" &&  
                    <NumberFormat 
                    value={voce.importo} 
                    displayType={'text'}
                    fixedDecimalScale={2} 
                    decimalScale={2} 
                    suffix={' EUR'}
                    />}
                    </TableCell>
                    {edit && voce.tipo_voce !== "TRATTAMENTO" &&  
                    <TableCell>
                    <Button color="default" onClick={() => { this.rimuoviVoce(idx) }}><DeleteIcon/></Button>
                    </TableCell>
                    }
                    {!edit && voce.tipo_voce !== "TRATTAMENTO" &&  
                    <TableCell>
                    
                    </TableCell>
                    }
                    {voce.tipo_voce === "TRATTAMENTO" &&  
                    <TableCell>
                    
                    </TableCell>
                    }
                  </TableRow>)
                  }
                )
            }
            {edit && curr_fattura.tipo_fattura === "ACCONTO" &&
            <TableRow>
              <TableCell colSpan={3}>
                <Button color="secondary" onClick={() => { this.nuovaVoce() }}>Aggiungi voce</Button>
              </TableCell>
            </TableRow>
            }
            <TableRow>
                <TableCell><b>TOTALE CONCORDATO</b></TableCell>
                <TableCell align="right">
                  <b><NumberFormat value={this.getImporto(curr_fattura, false)} displayType={'text'} fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} /></b>
                </TableCell>
                <TableCell> </TableCell>
            </TableRow>
            </>}
            {curr_fattura.tipo_fattura === "SALDO" && curr_fattura.anticipi && curr_fattura.anticipi.map((fatt) => {
                return (<TableRow>
                  <TableCell>
                  {fatt.descrizione_anticipo}
                  </TableCell>    
                  <TableCell align="right">
                  - <NumberFormat value={fatt.importo} displayType={'text'} fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>)
            })}
            {curr_fattura.tipo_fattura === "SALDO" && curr_fattura.voci.length > 0 && <TableRow>
                <TableCell><b>TOTALE</b></TableCell>
                <TableCell align="right">
                <b><NumberFormat value={this.getImporto(curr_fattura, true)} displayType={'text'} fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} /></b>
                </TableCell>
                <TableCell></TableCell>
            </TableRow>}
            </Table>
            </>}
            {curr_fattura.id !== undefined && in_preview &&
            <Grid item xs={12}>
              <PreviewPdf url_pdf={url_pdf}/> 
            </Grid>  
            }
            <h3>Incassi</h3>
            <Table>
            {!edit && curr_fattura.incassi.map((incasso, idx) => {
                  const curr_metodo = this.getMetodoPagamento(incasso.metodo_pagamento)      
                  return (<div><TableRow>
                    <TableCell>
                     {curr_metodo.nome}
                    </TableCell>    
                    <TableCell>
                      {moment.moment(incasso.data).format("DD/MM/YYYY")}
                    </TableCell>    
                    <TableCell align="right">
                    <NumberFormat 
                    label={'importo'}
                    value={incasso.importo} 
                    displayType={'text'}
                    fixedDecimalScale={2} 
                    decimalScale={2} 
                    suffix={' EUR'}
                    onValueChange={(values)=> {this.cambiaImportoInc(values.floatValue, idx)}}
                    />
                    </TableCell>
                    <TableCell>
                    
                    </TableCell>
                   
                  </TableRow>
                  {metodi_pagamento[incasso.metodo_pagamento -1].note_obbligatorie && <TableRow>
                    <TableCell colSpan={4}>
                    <TextField
                      style={{width: "100%"}}
                      label={"note (" + metodi_pagamento[incasso.metodo_pagamento -1].suggerimento_note + ")"}
                      value={incasso.note || metodi_pagamento[incasso.metodo_pagamento -1].suggerimento_note}
                      onChange={(evt)=> {this.handleIncassoNote(evt.target.value, idx)}}
                      />
                    </TableCell>
                    
                  </TableRow>}</div>)
                  }
                )
            }

              {edit && curr_fattura.incassi.map((incasso, idx) => {      
                  return (<div><TableRow>
                    <TableCell>
                    <SingleSelect
                      name={'metodo'}
                      label={'metodo di pagamento'}
                      options ={options_metodi}
                      value ={incasso.metodo_pagamento}
                      onChange={(val)=> {this.handleMetodoPagamento(val, idx)}}
                    />
                    </TableCell>    
                    <TableCell align="right">
                    <NumberFormat 
                    label={'importo'}
                    value={incasso.importo} 
                    defaultValue={0.00}
                    allowEmptyFormatting={true}    
                    customInput={TextField} 
                    fixedDecimalScale={2} 
                    decimalScale={2} 
                    suffix={' EUR'}
                    onValueChange={(values)=> {this.cambiaImportoInc(values.floatValue, idx)}}
                    />
                    </TableCell>
                    <TableCell>
                    <Button color="default" onClick={() => { this.rimuoviIncasso(idx) }}><DeleteIcon/></Button>
                    </TableCell>
                   
                  </TableRow>
                  {metodi_pagamento[incasso.metodo_pagamento -1].note_obbligatorie && <TableRow>
                    <TableCell colSpan={4}>
                    <TextField
                      style={{width: "100%"}}
                      label={"note (" + metodi_pagamento[incasso.metodo_pagamento -1].suggerimento_note + ")"}
                      value={incasso.note || metodi_pagamento[incasso.metodo_pagamento -1].suggerimento_note}
                      onChange={(evt)=> {this.handleIncassoNote(evt.target.value, idx)}}
                      />
                    </TableCell>
                    
                  </TableRow>}</div>)
                  }
                )
            }

            {edit && <TableRow>
              <TableCell colSpan={2}>
                <Button color="secondary" onClick={() => { this.nuovoIncasso() }}>Aggiungi incasso</Button>
              </TableCell>
            </TableRow>}
            </Table>
            
            {curr_fattura.stato === "BOZZA" &&
            <Button variant="contained" color="primary" onClick={() => { this.salvaFattura() }}>Salva fattura</Button>
            }
            &nbsp;
            {curr_fattura.stato === "BOZZA" && curr_fattura.id !== undefined &&
            <Button variant="contained" color="primary" onClick={() => { this.emettiFattura() }}>Emetti fattura</Button>}
            </Grid>
          }


              
              
        </Container>
    )
    }
      

}
export default withStyles(styles)(withSnackbar(FatturaSingola))