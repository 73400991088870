import React from 'react'
import {
    Button,
 } from '@material-ui/core/'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import "moment/locale/it"
import api from "../api_calls/api"
import { withSnackbar } from 'notistack'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {Table, TableRow, TableCell} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import {FormControl} from "@material-ui/core";
import {InputLabel} from "@material-ui/core";
import {Select} from "@material-ui/core";
import {MenuItem} from "@material-ui/core";
import {TextField} from "@material-ui/core";

class DialogContatti extends React.Component {
    state = {
        open: false,
        validate: false,
        curr_contatto: undefined,
        esiti: [],
        curr_chiamata: {}
    }
    moment = new MomentUtils()

    loadEsiti = () => {
      console.log("loadEsiti...");
      const url = `${process.env.REACT_APP_API_URL}/contatti_social/esiti_chiamate`
        api.call_get(url, (result) => {
              this.setState({
                esiti: result.results
              })
          });
    }

    getEsito = (id_esito) => {
        const esiti = this.state.esiti;
        const res = esiti.filter(el => el.id === id_esito).map((item) => { return item });
        if(res.length > 0) {
            return res[0];
        }
        return null;
    }

    formatStrDate(strdt) {
        //sono fatte così: 2023-07-01T08:27:49.377233
        const data = strdt.split("T")[0];
        const ora = strdt.split("T")[1];
        let res = data.split("-")[2] + "/" + data.split("-")[1] + "/" + data.split("-")[0] + " " + ora.split(":")[0] + ":" + ora.split(":")[1];
        return res;
    }

    getRowColor(strdt) {
        const data = strdt.split("T")[0];
        const ora = strdt.split("T")[1];
        let res = data.split("-")[2] + "/" + data.split("-")[1] + "/" + data.split("-")[0] + " " + ora.split(":")[0] + ":" + ora.split(":")[1];
        return res;
    }

    handleSubmitChiamate = () => {
        let curr_chiamata = this.state.curr_chiamata;
        curr_chiamata.contatto = this.state.curr_contatto.id;
      this.setState({validate: true}, api.call_post(
        `${process.env.REACT_APP_API_URL}/contatti_social/chiamate`,
        this.state.curr_chiamata,
        (response) => {
          this.props.enqueueSnackbar("Chiamata salvata correttamente.", {variant:  'success', autoHideDuration: 4000})
          this.setState({
              validate: false,
              curr_chiamata: {},
              curr_contatto: undefined
          }, this.props.refreshContatti());
        },
        (error) => {
          this.props.enqueueSnackbar("Errore nel salvataggio della chiamata.", {variant:  'error', autoHideDuration: 4000})
        }));
};


    handleChangeChiamate = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        //setChiamata((values) => ({ ...values, [name]: value }));
        this.setState({
            curr_chiamata: { ...this.state.curr_chiamata, [name]: value }
        })
    };

    constructor(props) {
        super(props)
        console.log("constructor...");

      }

      componentDidMount () {
        //this.loadEsiti();
      }

      componentDidUpdate (old) {
        console.log("update...")
      }

afterSuccess() {
    try {
        if(this.props.afterSave) this.props.afterSave();
    } catch (e) {
        ;
    }
    this.setState({inUpload: false, altri: [], selectedFile: undefined}, this.props.toggleOpen);
}

getDachiamare() {
        let res = [];
        let sres = [];
        for(let k=0; k < this.props.allsets.length; ++k) {
            const item = this.props.allsets[k];
            if( this.props.set && !item.has_consenso && item.id !=  this.props.set.id && sres.indexOf(item.str_consenso) < 0 && item.tipo_consenso === this.props.set.tipo_consenso) {
                sres.push(item.str_consenso);
                res.push({id: item.id, text: item.str_consenso, selected: true});
            }
        }
        return res;
}



  salvaFile() {
      const set = this.props.set;
      const url = `${process.env.REACT_APP_API_URL}/dc/consenso_informato/${set.id}/`
      this.setState({inUpload: true});
      let method = 'POST'
      const data = new FormData()
      data.append('file', this.state.selectedFile);
      data.append('altri', this.state.altri);
      api.call_multipart_post(
          url,
          data,
          (res) => {
            this.props.enqueueSnackbar(`Consenso caricato.`, {variant:  'success', autoHideDuration: 4000})
            this.afterSuccess();
        },
        (err) => {
          try {
            console.log("=== ERRORE salvaFile ===");
            console.log(JSON.stringify(err));
            let msg = err.response.data.split("\n")[1];
            if(msg === undefined) {
                msg = 'Il codice a barre atteso non è stato trovato.'
            }
            this.props.enqueueSnackbar(`Errore salvataggio documento: ${msg}`, {variant:  'error', autoHideDuration: 8000})
          } catch(e) {
            this.props.enqueueSnackbar(`Errore salvataggio dati: ${e}`, {variant:  'error', autoHideDuration: 8000})
          }
          this.setState({inUpload: false})
        },
        method)

    }


  handleChangeCons = (event) => {
        let altri = this.state.altri;
        if(event.target.checked) {
            altri.push(event.target.value);
        } else {
            altri = altri.filter((item) => { return (item != event.target.value) });
        }
        this.setState({ altri: altri});
  };

  handleChangeDate(date) {
    try {
          this.setState({
            pdc: {
                ...this.state.pdc,
                'data_firma_paziente': date.format('YYYY-MM-DD')
            }
        })
    } catch(e) {
      ;
    }
  }


  onChangeHandler = (event) => {
    const name = event.target.files[0] && event.target.files[0].name;
    if (!name) {
        return;
    }
    if(name && !name.toLowerCase().endsWith(".pdf")) {
        this.props.enqueueSnackbar(`Sono consentiti solo file pdf.`, {variant:  'error', autoHideDuration: 4000});
        return;
    }
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    });
  }

  render() {

  const {
    set
  } = this.props

      const {
            altri
        } = this.state

      let sels = '';
      if(set) sels = set.id;


    const curr = this.state.curr_contatto;
    const curr_chiamata = this.state.curr_chiamata;
    const validate = this.state.validate;
  return (
    <Dialog open={this.props.open} fullWidth={true} maxWidth='lg'>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'it'}>
              <DialogTitle id="form-dialog-title">Contatti social da chiamare</DialogTitle>
              <DialogContent>
                  {!curr && <Table>
                      <TableRow>
                          <TableCell>Provenienza</TableCell>
                          <TableCell>Cognome</TableCell>
                          <TableCell>Nome</TableCell>
                          <TableCell>Telefono</TableCell>
                          <TableCell>Richiesta il</TableCell>
                          <TableCell>Sede</TableCell>
                          <TableCell align='right'>Chiamate Fatte</TableCell>
                          <TableCell> </TableCell>
                      </TableRow>
                      {this.props.listaContatti.map((item) => {
                          const arrivoRichiesta = this.formatStrDate(item.created);
                          const rcolor = this.getRowColor(item.created);
                          //const dt  = this.moment.moment(item.created);
                          return (<TableRow>
                                    <TableCell>{item.provenienza_contatto}</TableCell>
                                    <TableCell>{item.cognome}</TableCell>
                                    <TableCell>{item.nome}</TableCell>
                                    <TableCell>{item.telefono}</TableCell>
                                    <TableCell>{arrivoRichiesta}</TableCell>
                                    <TableCell>{item.nome_clinica}</TableCell>
                                    <TableCell  align='right'>{item.chiamate.length}</TableCell>
                                    <TableCell align='right'>
                                        <Button onClick={() => { this.setState({'curr_contatto': item}) }} variant="contained" color="primary">
                                            CHIAMA
                                        </Button>
                                    </TableCell>
                                </TableRow>);
                      })}
                  </Table>}
                  {curr &&  <Grid container>
        <Grid item xs={6}>
              <strong>Nome e cognome:</strong> {curr.nome}{" "} {curr.cognome}
        </Grid>
        <Grid item xs={6} align='right'>
              <strong>Telefono:</strong> {curr.telefono}
        </Grid>
                      {curr.chiamate.length === 0 && <Grid item xs={12}><h3>Nessuna chiamata precedente</h3></Grid>}
                      {curr.chiamate.length > 0 && <Grid item xs={12}><h3>Chiamate precedenti</h3>
                        <Table>
                            <TableRow>
                                <TableCell>N. Chiamata</TableCell>
                                <TableCell>Esito</TableCell>
                                <TableCell>Note</TableCell>
                                <TableCell>Ora</TableCell>
                            </TableRow>
                        {curr.chiamate.map((chiamata) => {
                            const labelEsito = this.getEsito(chiamata.esito)?.descrizione;
                            const currOra = this.formatStrDate(chiamata.created);
                          return(<TableRow>
                              <TableCell>{chiamata.numero_chiamata}</TableCell>
                              <TableCell>
                                  {labelEsito}
                              </TableCell>
                              <TableCell>
                                  {chiamata.note}
                              </TableCell>
                              <TableCell>
                                  {currOra}
                              </TableCell>
                          </TableRow>);
                        })}
                      </Table>
                      </Grid>}
                      <Grid item xs={12}><h3>Registra chiamata</h3>



            <FormControl fullWidth variant='outlined'>
              <div>
                <InputLabel id='esito-chiamata'>Esito</InputLabel>
                <Select
                  fullWidth
                  labelId='esito-chiamata'
                  label='Esito'
                  id='otlined-basic'
                  name='esito'
                  value={curr_chiamata.esito}
                  error={validate && !curr_chiamata.esito}
                  helperText={validate && !curr_chiamata.esito ? 'Seleziona un esito!' : ' '}

                  onChange={this.handleChangeChiamate}>
                  <MenuItem value={1}>Non Risponde</MenuItem>
                  <MenuItem value={2}>Ha chiesto di essere richiamato</MenuItem>
                  <MenuItem value={3}>Non Chiamare Più</MenuItem>
                  <MenuItem value={4}>Ha fissato un appuntamento</MenuItem>
                  <MenuItem value={5}>Numero Errato</MenuItem>
                  <MenuItem value={6}>
                    Non Ha mai richiesto di essere Contattato
                  </MenuItem>
                </Select>
                <TextField
                  style={{ margin: "10px 0" }}
                  id='otlined-basic'
                  label='Note'
                  name='note'
                  variant='outlined'
                  fullWidth
                  value={curr_chiamata.note || ""}
                  onChange={this.handleChangeChiamate}
                  error={validate && curr_chiamata.note?.length < 20}
                  helperText={validate && curr_chiamata.note?.length < 20 ? 'Inserisci almeno 20 caratteri' : ' '}
                />

              </div>
              <div>
                <Button type='submit' variant='contained' color='primary' onClick={() => { this.handleSubmitChiamate(); }} >
                  Salva esito
                </Button>
              </div>
            </FormControl>

                      </Grid>


      </Grid>}
              </DialogContent>
              <DialogActions>
                  {curr &&<Button onClick={() => { this.setState({curr_contatto: undefined}); }} variant="contained" color="secondary">
                    Torna alla lista
                    </Button>}
                  <Button onClick={() => { this.setState({validate: false, curr_contatto: undefined, curr_chiamata: {}}, ()=>{this.props.toggleOpen();}) }} variant="contained" color="secondary">
                  Ricordamelo più tardi
                </Button>
              </DialogActions>

      </MuiPickersUtilsProvider>
    </Dialog>
  )}
}

export default withSnackbar(DialogContatti)
