//import React from 'react'
import axios from 'axios'
//import { useSnackbar } from 'notistack'

const forceLogout = () => {
    localStorage.clear();
    window.location.href = '/gui/'
    return false
}

const profilo_operatore = (data, callback, errorcallback) => {
    
    axios({
        url: `${process.env.REACT_APP_API_URL}/dc/profilo_operatore/`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('token')}`
        }
    })
    .then(res => {
        const operatore = res.data.results[0]
        console.log("profilo_operatore: " + JSON.stringify(operatore))
        localStorage.setItem("operatore", JSON.stringify(operatore))
        if(callback) {
            callback(data)
        }
        loadCurrTurno()
    })
    .catch(err => {
        if(errorcallback) {
            errorcallback(err)
        }
        if(err.response && 400 <= err.response.status && err.response.status < 500 && 404 != err.response.status) {
            forceLogout()
        }
    })

}

const loadCurrTurno = (callback, errorcallback) => {
    const url_turno = `${process.env.REACT_APP_API_URL}/dc/curr_turno_cassa/` 
    const operatore = JSON.parse(localStorage.getItem("operatore"))
    api.call_get(url_turno, (result) => {
      let turno = {utente: {username: undefined}}
      let can_edit = false
      if(result.results && result.results.length > 0) {
          turno = result.results[0]
      }
      localStorage.setItem("curr_turno", JSON.stringify(turno))  
    }, callback, errorcallback)
  }

const api = {
    login: (data, callback, errorcallback) => {
        axios({
            url: `${process.env.REACT_APP_API_URL}/token-auth/`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        })
            .then(res => {
                console.log("RISPOSTA LOGIN: " + JSON.stringify(res.data.user))
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('sede_corrente_id', res.data.user.sede_corrente_id);
                localStorage.setItem('sede_corrente_nome_breve', res.data.user.sede_corrente_nome_breve);
                localStorage.setItem('sede_corrente_nome', res.data.user.sede_corrente_nome);
                localStorage.setItem('sede_corrente_bloccata', res.data.sede_corrente_bloccata);
                localStorage.setItem('backend_version', res.data.backend_version);
                profilo_operatore(res.data, callback, errorcallback)
            })
            .catch(err => {
                //se il server ha risposto ho alert(JSON.stringify(err.response.status))
                if (errorcallback) {
                    errorcallback(err)
                }
                if (err.response && 400 <= err.response.status && err.response.status < 500 && 404 != err.response.status) {
                    localStorage.clear()
                }
            })
    },
    get_operatore: () => {
        return JSON.parse(localStorage.getItem("operatore"))
    },
    call_get: (url, callback, errorcallback) => {
        axios({
            url: url,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`
            }
        })
        .then(res => {
            if(callback) {
                callback(res.data)
            }
        })
        .catch(err => {
            if(errorcallback) {
                errorcallback(err)
            }
            if(err.response && 400 <= err.response.status &&  err.response.status < 500 && 404 != err.response.status) {
                forceLogout()
            }
        })
    },
    call_post: (url, data, callback, errorcallback, method = 'POST') => {
        axios({
            url: url,
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`
            },
            data: JSON.stringify(data)
        })
        .then(res => {
            if(callback) {
                callback(res.data)
            }
        })
        .catch(err => {
            if(errorcallback) {
                errorcallback(err)
            }
            if(err.response && err.response.status >= 401 && err.response.status < 500 && 404 != err.response.status) {
                forceLogout()
            }
        })
    },
    call_multipart_post: (url, data, callback, errorcallback, method = 'POST') => {
        axios({
            url: url,
            method: method,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `JWT ${localStorage.getItem('token')}`
            },
            data: data
        })
        .then(res => {
            if(callback) {
                callback(res.data)
            }
        })
        .catch(err => {
            if(errorcallback) {
                errorcallback(err)
            }
            if(err.response && err.response.status >= 401 && err.response.status < 500 && 404 != err.response.status) {
                forceLogout()
            }
        })
    },
    reloadOperatore(callback) {
        try {
          axios({
              url: `${process.env.REACT_APP_API_URL}/dc/profilo_operatore/`,
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `JWT ${localStorage.getItem('token')}`
              }
          })
          .then(res => {
              const operatore = res.data.results[0];
              callback(operatore);
          })
        } catch(e) {
            return false;
        }
        return true;
    },
    canEditCassa() {
        let can_edit = false
        try {
            loadCurrTurno()
            const turno = JSON.parse(localStorage.getItem("curr_turno"))
            const operatore = JSON.parse(localStorage.getItem("operatore"))
            can_edit = (turno.utente.username === operatore.utente.username)
        } catch(e) {
            ;
        }
        return can_edit
    },
    canOpenCassa() {
            return false
            //D.P. disabilitato meccanismo cassa in attesa di implementare i turni
            loadCurrTurno()
            const turno = JSON.parse(localStorage.getItem("curr_turno"))
            return turno.utente.username === undefined
    },
    getTurno() {
        loadCurrTurno()
        const turno = JSON.parse(localStorage.getItem("curr_turno"))
        return turno
    },
    getOperatore() {
        const operatore = JSON.parse(localStorage.getItem("operatore"))
        return operatore
    },
    reloadCurrTurno: (callback, errorcallback) => {
        loadCurrTurno(callback, errorcallback)
    },
    apriCassa: (callback, errorcallback) => {
        const url = `${process.env.REACT_APP_API_URL}/dc/apri_cassa/`
        axios({
            url: url,
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`
            },
            data: JSON.stringify({'a': 'b'})
        })
        .then(res => {
            if(callback) {
                callback(res)
            }
        })
        .catch(err => {
            //alert(JSON.stringify(err))
            if(errorcallback) {
                errorcallback(err)
            }
            if(err.response && err.response.status >= 401 && err.response.status < 500 && 404 != err.response.status) {
                forceLogout()
            }
        })
    },
    chiudiCassa: (callback, errorcallback) => {
        const url = `${process.env.REACT_APP_API_URL}/dc/chiudi_cassa/`
        axios({
            url: url,
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`
            },
            data: JSON.stringify({'a': 'b'})
        })
        .then(res => {
            if(callback) {
                callback(res)
            }
        })
        .catch(err => {
            //alert(JSON.stringify(err))
            if(errorcallback) {
                errorcallback(err)
            }
            if(err.response && err.response.status >= 401 && err.response.status < 500 && 404 != err.response.status) {
                forceLogout()
            }
        })
    },
    loadSaldi: (id_paziente, callback, errorcallback) => {
        const url_saldi_paziente = `${process.env.REACT_APP_API_URL}/dc/saldi_paziente/${id_paziente}`
        api.call_get(url_saldi_paziente, callback, errorcallback)
    },
    contattiSocialDaChiamare: (callback, errorcallback) => {
        const url = `${process.env.REACT_APP_API_URL}/contatti_social/contatti_da_chiamare`;
        api.call_get(url, callback, errorcallback)
    },
    datiSede: (callback, errorcallback) => {
        const url = `${process.env.REACT_APP_API_URL}/dc/dati_sede/`;
        api.call_get(url, callback, errorcallback)
    },
    datiSedeCorrente: (callback, errorcallback) => {
        const url = `${process.env.REACT_APP_API_URL}/dc/dati_sede_corrente/`;
        api.call_get(url, callback, errorcallback)
    },
    nomeOperatore: () => {
        try {
//            console.log("OPERATORE: " + localStorage.getItem('operatore'))
            const op = JSON.parse(localStorage.getItem('operatore'))
            return op.utente.username
        } catch(e) {
            return ""
        }
    },
    sedeLogin: () => {
        try {
//            console.log("OPERATORE: " + localStorage.getItem('operatore'))
          const op = JSON.parse(localStorage.getItem('operatore'))
          console.log("sede login: " + op.utente.sede_login.nome.toUpperCase())
          return op.utente.sede_login.nome.toUpperCase()
        } catch(e) {
            return ""
        }
    },
    listaSedi: () => {
        return JSON.parse("[{\"id\":1,\"nome\":\"San Francesco\",\"nome_breve\":\"FRA\"},{\"id\":2,\"nome\":\"Sant'Antonio\",\"nome_breve\":\"ANT\"},{\"id\":3,\"nome\":\"Fornacette\",\"nome_breve\":\"FOR\"},{\"id\":4,\"nome\":\"Lucca\",\"nome_breve\":\"LUC\"},{\"id\":6,\"nome\":\"Livorno\",\"nome_breve\":\"LIV\"},{\"id\":7,\"nome\":\"Pistoia\",\"nome_breve\":\"PTO\"},{\"id\":8,\"nome\":\"Empoli\",\"nome_breve\":\"EMP\"},{\"id\":10,\"nome\":\"Alessandria\",\"nome_breve\":\"ALE\"},{\"id\":11,\"nome\":\"Massa\",\"nome_breve\":\"MAS\"},{\"id\":12,\"nome\":\"Livorno Ricasoli\",\"nome_breve\":\"LRI\"},{\"id\":13,\"nome\":\"Pavia\",\"nome_breve\":\"PAV\"},{\"id\":14,\"nome\":\"Viareggio\",\"nome_breve\":\"VIA\"}]")
        /* 
        TODO: risolvere il fatto che lo carica in modo asincrono e mi serve subito
        if (!localStorage.getItem("lista_sedi")) {
            const url_sedi = `${process.env.REACT_APP_API_URL}/dc/lista_sedi/`
            api.call_get(url_sedi, (result) => {
                localStorage.setItem("lista_sedi", JSON.stringify(result)) 
                alert(JSON.stringify(result))   
            })
        }
        alert(JSON.stringify(localStorage.getItem("lista_sedi")))   
        return localStorage.getItem("lista_sedi")*/
    } 
} 
export default api
