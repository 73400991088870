import React, { Component } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

class Elemento extends Component {
    state = {
        options: []
    }
    
    getDisabled = () => {
        const elemento = [
            "11", "12", "13", "14", "15", "16", "17", "18",
            "21", "22", "23", "24", "25", "26", "27", "28",
            "31", "32", "33", "34", "35", "36", "37", "38",
            "41", "42", "43", "44", "45", "46", "47", "48",
            "81", "82", "83", "84", "85",
            "71", "72", "73", "74", "75",
            "61", "62", "63", "64", "65",
            "51", "52", "53", "54", "55"
          ]
        const arcate = ['AS', 'AI']
        const quadrante = ['Q1', 'Q2', 'Q3', 'Q4']
        const generale = ['GEN']
        const e = this.props.elemento
        if (!this.props.parentState.curr_prestazione || this.props.parentState.curr_prestazione === "") {
            return true
        }
        const cp = this.props.parentState.curr_prestazione
        //se non ho nessuna posizione valorizzata imposto almeno posizione_genrale
        if(!cp.posizione_elemento && !cp.posizione_quadrante && !cp.posizione_arcata) {
            cp.posizione_generale = true
        } 

        if (elemento.includes(e)) {
            return !cp.posizione_elemento
        }
        if (quadrante.includes(e)) {
            return !cp.posizione_quadrante
        }
        if (arcate.includes(e)) {
            return !cp.posizione_arcata
        }
        if (generale.includes(e)) {
            return !cp.posizione_generale
        }

        return false
    }

    getChecked = () => {
        let res =  this.props.parentState.elementi.includes(this.props.elemento)
        return res
    }

    render() {
    return(
        <div className="elem">
            <FormControlLabel
          value="end"
          control={<Checkbox
            style={{ width: 16, height: 16 }}
            checked={this.getChecked()}
            onChange={() => {this.props.handleClick(this.props.elemento)}}
            
            inputProps={{
                'aria-label': 'primary checkbox',
            }}
            label="12"   
        /> }
          label={this.props.elemento}
          labelPlacement="bottom"
          disabled={this.getDisabled()}
        /> 
        </div>
    )
    }
      

}
export default Elemento;