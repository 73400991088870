import React, { Component } from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Container from '@material-ui/core/Container'
import api from "../api_calls/api"
import FormControl from '@material-ui/core/FormControl';
import { withSnackbar } from 'notistack'
import withConfirm from 'material-ui-confirm'
import MomentUtils from '@date-io/moment'
import {SingleSelect} from "react-select-material-ui";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";



const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
      palette: {
        primary: {
          light: '#0000',
          main: '#00000',
          dark: '#0000',
          contrastText: '#fff',
        },
        secondary: {
          light: '#ff7961',
          main: '#f44336',
          dark: '#ba000d',
          contrastText: '#000',
        },
      },
}));






function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

class SedeBloccata extends Component {

  constructor(props) {
    super(props);

    this.state = {
      logged_in: localStorage.getItem('token') ? true : false,
      operatore: api.getOperatore(),
      criticita: undefined,
      sede_corrente: {
        id: localStorage.getItem('sede_corrente_id'),
        nome_breve: localStorage.getItem('sede_corrente_nome_breve'),
        nome: localStorage.getItem('sede_corrente_nome')
      },
      lista_criticita: [],
      messaggi: [],
      messaggio: {
        tipo: '1',
        testo: ''
      },
      messaggio_sblocco: ''
    }
  }

  strToDate(str) {
      try {
          const dt = str.split('T')[0];
          const parts = dt.split('-');
          return parts[2] + '/' + parts[1] + '/' + parts[0];
      } catch (e) {
          return '--';
      }
  }

  componentDidMount() {
      this.readCriticita();
  }

  componentDidUpdate(old) {
    //this.readFatturatoSede()
  }

  cancellaMessaggio(id) {
    const url = `${process.env.REACT_APP_API_URL}/dc/messaggi_delete/` + id
    api.call_post(url, undefined, (res) => {
            this.props.enqueueSnackbar(`Messaggio cancellato`, {variant: 'success', autoHideDuration: 4000});
            this.setState({
              messaggio: {tipo: '1', testo: ''}
            }, this.readMessaggi);
          }, (err) => {
            let msg = ""
            try {
              msg = JSON.stringify(err.response.data)
            } catch (e) {
              msg = JSON.stringify(e)
            }
            this.props.enqueueSnackbar(`Errore invio messaggio: ${msg}`, {
              variant: 'error',
              autoHideDuration: 4000
            })
          }, 'DELETE');
  }

  inviaMessaggio() {
    const url = `${process.env.REACT_APP_API_URL}/dc/messaggi/`
    let messaggio = {...this.state.messaggio}
    messaggio.criticita = this.state.criticita.id
    api.call_post(url, messaggio, (res) => {
            this.props.enqueueSnackbar(`Messaggio inviato`, {variant: 'success', autoHideDuration: 4000});
            this.setState({
              messaggio: {tipo: '1', testo: ''}
            }, this.readMessaggi);
          }, (err) => {
            let msg = ""
            try {
              msg = JSON.stringify(err.response.data)
            } catch (e) {
              msg = JSON.stringify(e)
            }
            this.props.enqueueSnackbar(`Errore invio messaggio: ${msg}`, {
              variant: 'error',
              autoHideDuration: 4000
            })
          });
  }


  inviaMessaggioSblocco() {
    const url = `${process.env.REACT_APP_API_URL}/dc/messaggio_sblocco/`
    let messaggio_sblocco = this.state.messaggio_sblocco
    api.call_post(url, {testo: messaggio_sblocco, sede: localStorage.getItem('sede_corrente_id'), tipo: 1}, (res) => {
            this.props.enqueueSnackbar(`Messaggio inviato`, {variant: 'success', autoHideDuration: 4000});
            this.setState({
              messaggio_sblocco: ''
            }, this.readMessaggi);
          }, (err) => {
            let msg = ""
            try {
              msg = JSON.stringify(err.response.data)
            } catch (e) {
              msg = JSON.stringify(e)
            }
            this.props.enqueueSnackbar(`Errore invio messaggio: ${msg}`, {
              variant: 'error',
              autoHideDuration: 4000
            })
          });
  }

  readCriticita() {
    const operatore = api.getOperatore()
    const sede_corrente = {
      id: localStorage.getItem('sede_corrente_id'),
      nome_breve: localStorage.getItem('sede_corrente_nome_breve'),
      nome: localStorage.getItem('sede_corrente_nome')
    }
    const url = `${process.env.REACT_APP_API_URL}/dc/criticita`
    api.call_get(url, (result) => {
      this.setState({
        lista_criticita: result.results,
      });
      this.readMessaggi();
    })
  }


  readMessaggi() {
    if(this.state.criticita !== undefined) {
      const url = `${process.env.REACT_APP_API_URL}/dc/messaggi_list/${this.state.criticita.id}`
      api.call_get(url, (result) => {
        this.setState({
          messaggi: result.results,
        });
      })
    } else {
      this.setState({
          messaggi: [],
        });
    }
  }

  setCriticita(criticita) {
    //console.log("SET criticita: " + JSON.stringify(criticita));
    this.setState({
        criticita: criticita,
      }, this.readMessaggi);
  }

  setTestoMessaggio(value) {
    this.setState({
      messaggio: {tipo: this.state.messaggio.tipo, testo: value}
    });
  }

  setTestoMessaggioSblocco(value) {
    this.setState({
      messaggio_sblocco: value
    });
  }


  setTipoMessaggio(value) {
    this.setState({
      messaggio: {tipo: value, testo: this.state.messaggio.testo}
    });
  }



  render() {
    const { operatore } = this.state
    const { sede_corrente } = this.state
    const { fatturato } = this.state
    const backend_version = localStorage.getItem('backend_version');
    const moment = new MomentUtils()
    const { criticita } = this.state
    const { messaggi } = this.state
    const { messaggio } = this.state
    const lista_criticita_sbloccotmp = this.state.lista_criticita.filter((el) => { return el.stato == 3 });
    const lista_criticita_attive = this.state.lista_criticita.filter((el) => { return el.stato == 1 });
    const lista_criticita_scadenza = this.state.lista_criticita.filter((el) => { return el.stato == 0 });
    const lista_criticita_riesame = this.state.lista_criticita.filter((el) => { return el.stato == 5 });
    const lista_criticita_sistemate = this.state.lista_criticita.filter((el) => { return el.stato == 7 });

    const le_liste= [
        lista_criticita_attive,
        lista_criticita_riesame,
        lista_criticita_sistemate,
        lista_criticita_scadenza,
        lista_criticita_sbloccotmp
    ];

    return (
      <Container component="main" maxWidth="md">
          <Grid container >
              <Grid item xs={12}>
                <h3>Sede {sede_corrente.nome} - BLOCCATA</h3>
              </Grid>
              {le_liste.map((lista_cr) => {
               if (criticita === undefined && lista_cr !== undefined && lista_cr.length > 0) {
                   let curr_tipo = "";
                   switch (lista_cr[0].stato) {
                    case 0:
                        curr_tipo = "IN SCADENZA"
                        break;
                    case 1:
                        curr_tipo = "ATTIVE"
                        break;
                    case 3:
                        curr_tipo = "SBLOCCATE TEMPORANEAMENTE"
                        break;
                    case 5:
                        curr_tipo = "PIANI DI CURA DA RIFARE A MANO"
                        break;
                    case 7:
                        curr_tipo = "SISTEMATA DA EMETTERE FATTURA A SALDO"
                        break;
                    default:
                        curr_tipo = "";
                    };

                   return (<Grid item xs={12}>
                       <h3>Criticità {curr_tipo}:</h3>
                       <Table dense={false}>
                           <TableHead>
                               <TableRow>
                                   <TableCell>Paziente</TableCell>
                                   <TableCell>Criticità</TableCell>
                                   <TableCell>Blocco</TableCell>
                                   <TableCell>Azione richiesta</TableCell>
                                   <TableCell>Tel.</TableCell>
                                   <TableCell>
                                       &nbsp;
                                   </TableCell>
                               </TableRow>
                           </TableHead>
                           <TableBody>
                               {lista_cr.map((item) => {
                                   return (<TableRow>
                                      <TableCell>{item.paziente?.cognome} {item.paziente?.nome}</TableCell>
                                       <TableCell>{item.motivo.nome}</TableCell>
                                       <TableCell>{this.strToDate(item.blocco)}</TableCell>
                                       <TableCell dangerouslySetInnerHTML={{ __html: item.descrizione }} />
                                       <TableCell>{item.paziente.cellulare || item.paziente.telefono}</TableCell>

                                       <TableCell>
                                         {item.descrizione.indexOf("AZIONE DA COMPIERE") < 0 &&
                                           <Tooltip title="dettagli criticità">
                                               <Button variant="contained" size="small" color="secondary"
                                                       onClick={() => {
                                                           this.setCriticita(item)
                                                       }}>
                                                   Dettagli
                                               </Button>
                                           </Tooltip>}
                                       </TableCell>
                                       <TableCell>
                                         {item.stato == 1 && item.descrizione.indexOf("AZIONE DA COMPIERE") < 0 && <Tooltip title="risolvi criticità">
                                               <Button variant="contained" size="small" color="primary"
                                                       href={"/gui/pazienti/?pid=" + item.paziente.id}>
                                                   Risolvi
                                               </Button>
                                           </Tooltip>}
                                       </TableCell>
                                   </TableRow>)
                               })
                               }
                           </TableBody>
                       </Table>
                   </Grid>);
               }
            })}
              {criticita !== undefined && <Grid item xs={12}>
                <h3>Criticità:</h3>
                <Grid container >
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Tooltip title="lista criticità">
                    <Button variant="contained" size="small" color="secondary" onClick={() => { this.setCriticita(undefined) }}>
                      Report Criticità
                    </Button>
                  </Tooltip>
                </Grid>
                </Grid>
                <Table dense={false}>
                  <TableRow>
                          <TableCell>Paziente</TableCell>
                          <TableCell>{criticita.paziente.cognome} {criticita.paziente.nome} (tel. {criticita.paziente.cellulare || criticita.paziente.telefono})</TableCell>
                  </TableRow>
                  <TableRow>
                          <TableCell>Criticità</TableCell>
                          <TableCell>{criticita.motivo.nome}</TableCell>
                  </TableRow>
                  <TableRow>
                          <TableCell>Azione richiesta</TableCell>
                          <TableCell>{criticita.descrizione}</TableCell>
                  </TableRow>

                </Table>
                {messaggi.length > 0 && <h5>Messaggi:</h5>}
                {messaggi.length > 0 && <Table dense={false}>
                    <TableHead>
                        <TableRow>
                          <TableCell>Utente</TableCell>
                          <TableCell>Tipo messaggio</TableCell>
                          <TableCell style={{width: '60%'}}>Testo</TableCell>
                          <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.messaggi.map((item, idx) => {
                      return (<TableRow>
                        <TableCell>{item.user?.first_name} {item.user?.last_name}</TableCell>
                          <TableCell>
                            {item.tipo === 1 && <span>Richiesta sblocco</span>}
                            {item.tipo !== 1 && <span>Messaggio</span>}
                          </TableCell>
                          <TableCell  style={{width: '60%'}}>{item.testo}</TableCell>
                          <TableCell>
                            {idx === 0 && item.user?.username === operatore.utente.username && <Button variant="contained" size="small" color="secondary"
                            onClick={this.props.confirm(() => { this.cancellaMessaggio(item.id) }, {
                              title: 'Task',
                              description: "Vuoi cancellare questo messaggio?",
                              confirmationText: 'Sì',
                              cancellationText: 'No'
                              })}
                            >cancella</Button>}
                          </TableCell>
                      </TableRow>)
                    })
                    }
                    </TableBody>
                  </Table>}
                <br/><br/>
                <Grid container spacing={0}>
                  <Grid item xs={12}><b>Invia richiesta:</b></Grid>
                  <Grid item xs={12}>
                      <FormControl >
                        <SingleSelect name={'tipo_messaggio'}
                                  label={'tipo'}
                                  style={{width: 470}}
                                  value={'1'}
                                  defaltValue={messaggio.tipo}
                                  options={[{value: '1', label: 'Richiesta sblocco'}, {value: '2', label: 'Messaggio'}]}
                                  onInput={this.handleInputPrestazione}
                                  onChange={(val)=> {this.setTipoMessaggio(val)}}
                                  />
                      </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                      <FormControl >
                        <TextareaAutosize
                    id='testo_libero'
                    label='Messaggio'
                    value={messaggio.testo}
                    rows={3}
                    style={{width: 470}}
                    placeholder="Messaggio"
                    onChange={(evt) => {
                      this.setTestoMessaggio(evt.target.value)
                    }}
                    />
                      </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={6} align="right">
                  <br/>
                <Tooltip title="invia messaggio">
                              <Button disabled={messaggio.testo.trim()=== ''} variant="contained" size="small" color="primary" onClick={() => { this.inviaMessaggio() }}>
                                Invia
                              </Button>
                            </Tooltip>
                </Grid>


              </Grid>}



          </Grid>
      </Container>
    )
  }
}
export default withConfirm(withSnackbar(SedeBloccata))
