import React, { Fragment, useState } from 'react'
import {
    Button,
 } from '@material-ui/core/'
import Fab from '@material-ui/core/Fab'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import { SingleSelect } from "react-select-material-ui"
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import history from '../components/history'
import Container from '@material-ui/core/Container'

import { Add, Edit } from '@material-ui/icons'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers"
import "moment/locale/it"
import api from "../api_calls/api"
import { withSnackbar } from 'notistack'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from "@material-ui/core/Typography";
import {Link} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CheckIcon from "@material-ui/icons/Check";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PianoTerapeutico from "../components/PianoTerapeutico";
import PianiTerapeutici from "../components/PianiTerapeutici";
import AppuntamentiPaziente from "../components/AppuntamentiPaziente";
import TaskPaziente from "../components/TaskPaziente";
import Trattamenti from "../components/Trattamenti";
import Fatture from "../components/Fatture";
import Accoglienza from "../components/Accoglienza";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";



class DialogIntestatario extends React.Component {
    state = {
        open: false,
        validate: false,
        cerca_paz: '',
        lista_pazienti: [],
        curr_paziente: {
            id: null,
            cognome: '',
            nome: '',
            email: '',
            data_nascita: '',
            genere: 'm',
            nic: '',
            cellulare: '',
            citta_residenza_testo_libero: '',
            indirizzo_residenza: '',
            cap: ''
        }
    }

    cercaPazienti = () => {
        if(!this.state.cerca_paz || this.state.cerca_paz.length === 0) {
            return
        }
        const url_paz = `${process.env.REACT_APP_API_URL}/dc/ricerca_pazienti?q=` + this.state.cerca_paz;
        try {
          clearTimeout(this.sp);
        } catch(e) {;}
        this.sp = setTimeout( () => {
            api.call_get(url_paz, (result) => {
                const pazienti = result.results
                this.setState({
                    lista_pazienti: pazienti
                });
            }, 350);
        });
    }


    constructor(props) {
        super(props)
        if(props.paziente !== null && props.paziente !== undefined) {
            this.state.curr_paziente = JSON.parse(JSON.stringify(props.paziente))
        }
      }

      componentDidMount () {
        try {
            if(this.props.match.params.paziente_id) {
                let url_paziente = `${process.env.REACT_APP_API_URL}/dc/api_paziente/` + this.props.match.params.paziente_id
                fetch(url_paziente, {
                    headers: {
                        Authorization: `JWT ${localStorage.getItem('token')}`
                    }
                }).then(res => res.json())
                .then(res => {
                    const paz  = res.results[0]
                    if(paz.cellulare === '') {
                      if (Array.isArray(paz.telefono)) {
                        paz.cellulare = paz.telefono[0]
                      } else {
                        paz.cellulare = JSON.stringify(paz.telefono)
                      }
                    }
                    this.setState({
                        curr_paziente: paz,
                        open: this.props.open
                    })
                })
            }
        } catch (e) {
          console.log("Errore init: " + JSON.stringify(e))
        }
      }

      componentDidUpdate (old) {
        try {
          if(this.props.match.params.paziente_id !== undefined && (old.match.params.paziente_id !== this.props.match.params.paziente_id)) {
            if(this.props.match.params.paziente_id) {
                let url_paziente = `${process.env.REACT_APP_API_URL}/dc/api_paziente/` + this.props.match.params.paziente_id
                fetch(url_paziente, {
                    headers: {
                        Authorization: `JWT ${localStorage.getItem('token')}`
                    }
                }).then(res => res.json())
                .then(res => {
                    const paz  = res.results[0]
                    this.setState({ curr_paziente: paz})
                })
            }
          }
      } catch (e) {
        console.log("Errore init: " + JSON.stringify(e))
      }
      }

    getCheckCode = (codiceFiscale) => {
      const CHECK_CODE_ODD = {
        0: 1,
        1: 0,
        2: 5,
        3: 7,
        4: 9,
        5: 13,
        6: 15,
        7: 17,
        8: 19,
        9: 21,
        A: 1,
        B: 0,
        C: 5,
        D: 7,
        E: 9,
        F: 13,
        G: 15,
        H: 17,
        I: 19,
        J: 21,
        K: 2,
        L: 4,
        M: 18,
        N: 20,
        O: 11,
        P: 3,
        Q: 6,
        R: 8,
        S: 12,
        T: 14,
        U: 16,
        V: 10,
        W: 22,
        X: 25,
        Y: 24,
        Z: 23
      }

      const CHECK_CODE_EVEN = {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        A: 0,
        B: 1,
        C: 2,
        D: 3,
        E: 4,
        F: 5,
        G: 6,
        H: 7,
        I: 8,
        J: 9,
        K: 10,
        L: 11,
        M: 12,
        N: 13,
        O: 14,
        P: 15,
        Q: 16,
        R: 17,
        S: 18,
        T: 19,
        U: 20,
        V: 21,
        W: 22,
        X: 23,
        Y: 24,
        Z: 25
      }

      const CHECK_CODE_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

      codiceFiscale = codiceFiscale.toUpperCase();
        let val = 0
        for (let i = 0; i < 15; i = i + 1) {
          const c = codiceFiscale[i]
          val += i % 2 !== 0 ? CHECK_CODE_EVEN[c] : CHECK_CODE_ODD[c]
        }
        val = val % 26
        return CHECK_CODE_CHARS.charAt(val)
    }

  validaCodiceFiscale = (cf) => {
    let res = false
    if(cf.length === 0) {
      res = true
    } else {
      res = /[A-Z]{6}\d{2}[ABCDEHLMPRST]\d{2}[A-Z]\d{3}[A-Z]/i.test(cf)
      res = res && (cf[15] === this.getCheckCode(cf))
      console.log(cf[15] + " === " + this.getCheckCode(cf))
    }
    console.log("validaCodiceFiscale: " + res)
    return res
  }


    handleSave = () => {
        const paz = this.state.curr_paziente
        const invalidCapLength = paz.cap.length > 0 && paz.cap.length !== 5
        const capIsNum = paz.cap.length === 0 || /^\d+$/.test(paz.cap)
        const invalidCF = !this.validaCodiceFiscale(paz.nic)

        if (paz.cognome === "" || paz.nome === "" || paz.cellulare === "" || paz.email === ""|| paz.data_nascita === "" || invalidCapLength || !capIsNum || invalidCF) {
            this.setState({ validate: true })
            return false
        } else {
            this.salvaPaziente()
        }
    }

    handleChange = (name) => ({target: { value } }) => {
        if(name === 'nic') {
          value = value.toUpperCase()
        }
        this.setState({
            curr_paziente: {
                ...this.state.curr_paziente,
                [name]: value
            }
        })
    }



    handleClickOpen = () => {
        this.setState({open: true})
    }

    handleClose = () => {
        this.setState({open: false})
    }

    getLastPaz() {
        //TODO: smettere di usarlo una volta capito come leggere la risposta dopo il create
        let url_paziente = `${process.env.REACT_APP_API_URL}/dc/get_last_paziente_id/`
        fetch(url_paziente, {
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`
            }
        }).then(res => res.json())
        .then(res => {
            console.log(JSON.stringify(res))
            const pid = res.results[0].id
        })
    }

  salvaPaziente() {
      let { curr_paziente } = this.state
      let url_paziente = `${process.env.REACT_APP_API_URL}/dc/api_modifica_paziente/${curr_paziente.id}/`
      let method = 'PUT'
      if (curr_paziente.data_nascita === "") {
        curr_paziente.data_nascita = null
      }
      api.call_post(
        url_paziente,
        curr_paziente, (res) => {
          this.props.enqueueSnackbar(`Dati paziente ${res.cognome} ${res.nome} inseriti correttamente.`, {variant:  'success', autoHideDuration: 4000})
          this.props.setPaziente(curr_paziente)
          this.props.toggleOpen()
        },
        (err) => {
          try {
            let msg = ''
            for (const c in err.response.data) {
              msg += err.response.data[c] + " "
            }
            this.props.enqueueSnackbar(`Errore salvataggio dati: ${msg}`, {variant:  'error', autoHideDuration: 4000})
          } catch(e) {
            this.props.enqueueSnackbar(`Errore salvataggio dati: ${e}`, {variant:  'error', autoHideDuration: 4000})
          }
        },
        method)
    }


  handleChangeDate(date) {
    try {
          this.setState({
            curr_paziente: {
                ...this.state.curr_paziente,
                'data_nascita': date.format('YYYY-MM-DD')
            }
        })
    } catch(e) {
      ;
    }
  }

  render() {
  console.log("PROPS: " + JSON.stringify(this.props))
  const {
    open,
    validate,
    curr_paziente: {
      cognome,
      nome,
      email,
      cellulare,
      data_nascita,
      nic,
      citta_residenza_testo_libero,
      indirizzo_residenza,
      cap
     }
  } = this.state
  let tt = "nuovo paziente"
  if(this.state.curr_paziente.id) {
    tt = "modifica paziente"
  }

  const invalidCF = !this.validaCodiceFiscale(nic)
  const invalidCapLength = cap.length > 0 && cap.length < 5
  const capIsNum = cap.length === 0 || /^\d+$/.test(cap)
  let capErrorMsg = invalidCapLength ? 'Lunghezza non valida!' : ' '
  if (!invalidCapLength) {
    capErrorMsg = !capIsNum ? 'Valore non valido!' : ' '
  }

  return (
    <Dialog open={this.props.open}>
      <DialogTitle id="form-dialog-title">Scegli destinatario</DialogTitle>
      <DialogContent>
          <Grid container xs={12}>
            <Grid item xs={12} >
                    <TextField
                    name={'lista_pazienti'}
                    label={'Cerca destinatario'}
                    style={{ width: "80%" }}
                    onChange={(evt) => this.setState({
                      cerca_paz:  evt.target.value
                    })}
                    onKeyDown={(evt) => { if (evt.key === 'Enter') this.cercaPazienti(); }}
                    InputProps={{endAdornment:  <IconButton
                                aria-label="Cerca"
                                title="Cerca"
                                color="primary"
                                size="small"
                                onClick={this.cercaPazienti}
                                edge="end"><SearchIcon />
                              </IconButton>}}
                    />
            </Grid>
            <Grid item xs={12} >
              <Paper elevation={5} style={{ margin: '0 15px 5px 0' }}>
                  <h3>Destinatari</h3>
                  <Table dense={false}>
                    <TableHead>
                        <TableRow>
                          <TableCell>Paziente</TableCell>
                          <TableCell>CF</TableCell>
                          <TableCell>
                          <Link color="inherit" component={RouterLink} to="/nuovo_paziente/">
                          <Tooltip title="nuovo destinatario">
                              <Fab size="small" color="secondary">
                                <Add/>
                              </Fab>
                            </Tooltip>
                          </Link>
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.lista_pazienti.map((item) => {
                      return (<TableRow>
                          <TableCell>{item.cognome} {item.nome}</TableCell>
                          <TableCell>{item.nic || 'N.D'}</TableCell>
                          <TableCell>

                              {item.nic && <Tooltip title="seleziona destinatario">
                              <Fab size="small" color="secondary" onClick={() => { this.props.setIntestatario(item) }}>
                                <CheckIcon/>
                              </Fab>
                            </Tooltip>}

                          </TableCell>
                      </TableRow>)
                    })
                    }
                    </TableBody>
                  </Table>
              </Paper>
            </Grid>
          </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
        <Grid item xs={6} align="left">
          <Button  variant="contained" onClick={this.props.toggleOpen} color="secondary">
            Annulla
          </Button>
        </Grid>
          <Grid item xs={6} align="right">

          </Grid>
        </Grid>
        </DialogActions>
    </Dialog>
  )}
}

export default withSnackbar(DialogIntestatario)
