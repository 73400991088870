import React, { Component } from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ListIcon from '@material-ui/icons/List'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
//import DialogPaziente from './dialogs/DialogPaziente'
import Fab from '@material-ui/core/Fab' 
import Tooltip from '@material-ui/core/Tooltip'
import NumberFormat from 'react-number-format'
import { BrowserRouter as Router, Route, Link as RouterLink } from "react-router-dom"
import { Link } from '@material-ui/core'
import { Add, Edit, EuroSymbol } from '@material-ui/icons'
import Container from '@material-ui/core/Container'
import api from "../api_calls/api"
import PreviewPdf from "../components/PreviewPdf"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';


const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    }, 
      palette: {
        primary: {
          light: '#0000',
          main: '#00000',
          dark: '#0000',
          contrastText: '#fff',
        },
        secondary: {
          light: '#ff7961',
          main: '#f44336',
          dark: '#ba000d',
          contrastText: '#000',
        },
      },
}));







class StampaIncassi extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      logged_in: localStorage.getItem('token') ? true : false,
      lista_incassi: [],
      metodi_pagamento: [],
      view: false
    } 
  }



  urlPdf() {
      const operatore = api.getOperatore()
      const sid = operatore.sedi[0].id
      const url_pdf = `${process.env.REACT_APP_API_URL}/dc/stampa_incassi/${sid}`
      return url_pdf
  }

  urlHtml() {
    const operatore = api.getOperatore()
    const sid = operatore.sedi[0].id
    const url_pdf = `${process.env.REACT_APP_API_URL}/dc/stampa_incassi_html/${sid}`
    return url_pdf
  }

  openReport() {
    window.open(this.urlHtml(), '_blank');
  }
  
  componentDidMount() {
    this.loadMetodi()    
    //this.cercaIncassi()
  }


  cercaIncassi = () => {
    const q = ''  
    const url_paz = `${process.env.REACT_APP_API_URL}/dc/ricerca_incassi/today/?q=` 
    api.call_get(url_paz + q, (result) => {
      this.setState({
        lista_incassi: result.results
      })
    })
  }

  loadMetodi = () => {
    const url_metodi = `${process.env.REACT_APP_API_URL}/dc/metodi_pagamento/`
      api.call_get(url_metodi, (result) => {
            this.setState({
              metodi_pagamento: result.results
            })
        })
  }

  changeDate = (evt) => {
    //this.cercaIncassi(this.state.q, evt.target.value)
  }


  handleChange = (event, value) => {
    if (value === 'piano') {
      this.setState({ curr_piano: null })
    }
    this.setState({ value });
  };

  setCurrIncasso = (incasso) => {
    this.setState({
      curr_incasso: incasso
    })
  }

  caricaPiano = (id) => {
    let piano = undefined
    const url_piani = `${process.env.REACT_APP_API_URL}/dc/piani_terapeutici/${this.props.pid}`
      fetch(url_piani, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(result => result.json())
        .then(result => {
          const piani  = result.results
          for (let k = 0; k < piani.length; ++k) {
            if (piani[k].id = id) {
              piano = piani[k]
              break
            }
          }
          this.setState({
            curr_piano: id,
            piano: piano,
            value: 'piano'
          })
      }) 
  }

  clickPianoUtente(id) {
    this.caricaPiano(id)
  }

  descrIncasso(id_metodo) {
     const descr = this.state.metodi_pagamento.filter(el => el.id === id_metodo).map((item) => { return item.nome })
     return descr[0]
  }


  render() {
    const classes = {}
    const { view } = this.state
    return (
      <Container component="main" maxWidth="md">
          <Grid container xs={12}>
            <Grid item xs={12} >
              
                  <h3>Stampa turno cassa</h3>
                  
              
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={() => { this.openReport() }}>VISUALIZZA REPORT</Button>
              &nbsp;<Button variant="contained" color="primary" onClick={() => { this.setState({view: !view}) }}>VISUALIZZA PDF</Button>
            </Grid>  
            {view && <Grid item xs={12}>
              <PreviewPdf url_pdf={this.urlPdf()}/> 
            </Grid>}  
          </Grid>
          
      </Container>
    )
  }
}
export default StampaIncassi