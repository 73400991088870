import React from 'react'

const PreviewPdf = props => {
    const url_pdf = props.url_pdf + "#page=1&view=Fit";
    return (
        <object
        data={url_pdf}
        type="application/pdf"
        width="100%"
        height="900px"
        >
        <param name="view" value="Fit" />
        <p>Your browser does not support PDFs.
        <a href="{props.url_pdf}">Download the PDF</a>.</p>
      </object>
    )
  }


  export default PreviewPdf 
