import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import NumberFormat from 'react-number-format'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import RiepilogoEseguiti from '../components/RiepilogoEseguiti'
import { SingleSelect } from "react-select-material-ui"
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Checkbox from '@material-ui/core/Checkbox'
import DeleteIcon from '@material-ui/icons/Delete'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import ListIcon from '@material-ui/icons/List'
import CreateIcon from '@material-ui/icons/Edit'
import PdfIcon from '@material-ui/icons/PictureAsPdf'
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import PreviewPdf from '../components/PreviewPdf'
import PreviewImg from '../components/PreviewImg'
import VisibilityIcon from '@material-ui/icons/Visibility'
import MomentUtils from '@date-io/moment'
import Container from '@material-ui/core/Container'
import api from '../api_calls/api'
import { withStyles } from '@material-ui/styles'
import { withSnackbar } from 'notistack'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import { KeyboardDatePicker } from "@material-ui/pickers"
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
//import TurnoCassa from './TurnoCassa'
import Tooltip from '@material-ui/core/Tooltip'
import withConfirm from 'material-ui-confirm'
import calc from '../utils/calc'
import DialogPaziente from '../dialogs/DialogPaziente.js'
import DialogIntestatario from '../dialogs/DialogIntestatario.js'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { MultipleSelect } from "react-select-material-ui"
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EuroIcon from "@material-ui/icons/EuroSymbol";


const styles = {
  root: {
    "font-size": "8pt",
  },
};

function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    );
  }

class Fatture extends Component {
    state = {
        fatture_paziente: [],
        preventivi_paziente: [],
        metodi_pagamento: [],
        fatturabili_acconto: [],
        fatturabili_saldo: [],
        fatturabili: [],
        fatture_precedenti: [],
        anche_non_eseguito: false,
        tipo_fattura: "ACCONTO",
        curr_fattura: undefined,
        isLoading: false,
        in_preview: false,
        edit: false,
        validate: false,
        canEdit: false,
        open: false,
        paziente: undefined,
        //in futuro conf_contributo si potrà caricare dalla configurazione del server "ablilitato_globale" dovrebbe venire da lì
        //conf_contributo viene abilitato per il saldo se un acconto utilizzabile ha un contributo
        conf_contributo: {
          abilitato: false,
          abilitato_globale: false,
          soglia: 200.0
        },
        salva_in_corso: false,
        assicurazioni: [{
            "id": 0,
            "nome": "A carico del paziente",
            "attiva": true
        }]
    }

    setEditCassa(val) {
      this.setState({
        canEdit: val
      })
    }

    loadFatturabiliSaldo = (value) => {
      /*
      let url_fatturabili = `${process.env.REACT_APP_API_URL}/dc/fatturabili_saldo/${this.props.paziente.id}/`
      api.call_get(url_fatturabili, (result) => {
          console.log(JSON.stringify(result.results))
          this.setState({
            fatturabili_saldo: result.results
          })
        })*/
    }

    loadFatturabiliAcconto = (value) => {
      /*
      let url_fatturabili = `${process.env.REACT_APP_API_URL}/dc/fatturabili_acconto/${this.props.paziente.id}/`

      //url_fatturabili = `${process.env.REACT_APP_API_URL}/dc/fatturabili_paziente/${this.props.paziente.id}/`

      api.call_get(url_fatturabili, (result) => {
          console.log(JSON.stringify(result.results))
          this.setState({
            fatturabili_acconto: result.results
          })
        })*/
    }

    caricaAssicurazioni = () => {
        const url = `${process.env.REACT_APP_API_URL}/dc/compagnie_assicurative/`;
        api.call_get(url, (result) => {
            let assicurazioni = [{
            "id": 0,
            "nome": "A carico del paziente",
            "attiva": true
            }];
            result.results.map((el) => {
                assicurazioni.push(el);
            });
            this.setState({
                assicurazioni: assicurazioni
            });
        });
    }

    getPiano = (id) => {
      const p = this.state.preventivi_paziente.filter(piano => { return piano.id === id});
      if (p.length > 0) {
        return p[0]; 
      } else {
        return undefined; 
      }
    }

    caricaPiani = () => {
        const url_piani = `${process.env.REACT_APP_API_URL}/dc/piani_terapeutici/${this.props.paziente_id}`
          fetch(url_piani, {
            headers: {
              Authorization: `JWT ${localStorage.getItem('token')}`
            }
          })
            .then(result => result.json())
            .then(result => {
              const piani  = result.results
              this.setState({
                preventivi_paziente: piani
              })
          })
      }

    loadFatture = (value) => {
        const url_fatture_paziente = `${process.env.REACT_APP_API_URL}/dc/fatture_paziente/${this.props.paziente.id}/`
          fetch(url_fatture_paziente, {
            headers: {
              Authorization: `JWT ${localStorage.getItem('token')}`
            }
          })
              .then(result => result.json())
              .then(result => {
                this.setState({
                  fatture_paziente: result.results,
                  fatture_precedenti: this.getFatturePrecedenti(result.results),
                  curr_fattura: undefined,
                  in_preview: false,
                  salva_in_corso: false,
                  conf_contributo: this.getConfContributo(result.results)
                })
            })

    }


    loadMetodi = () => {
      const url_metodi = `${process.env.REACT_APP_API_URL}/dc/metodi_pagamento/`
        fetch(url_metodi, {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`
          }
        })
            .then(result => result.json())
            .then(result => {
              this.setState({
                metodi_pagamento: result.results
              })
          })
    }

    setNuovaFattura() {

      this.setState({
        nuova_fattura: {
          "numero_fattura": null,
          "titolo_del_documento": "Fattura",
          "tipo_fattura": "ACCONTO",
          "data_fattura": new Date().toISOString().substring(0, 10),
          "paziente_id": this.props.paziente_id,
          "stato": "BOZZA",
          "voci": [],
          "scadenze": [],
          "incassi": [
            {
                "data": new Date().toISOString().substring(0, 10),
                "importo_currency": "EUR",
                "importo": 0.00,
                "note": "",
                "metodo_pagamento": 1
            }
          ],
          "importo": 0.0,
          "incassato": 0.0,
          "concordato": 0.0,
          "eseguito": 0.0,
          "testo_aggiuntivo": "",
          "piani_di_cura": [],
          "fatture_di_riferimento": [],
          "saldo_finale": false
        }
      })
    }

    handleNote(val) {
      this.setState({
        curr_fattura: {
          ...this.state.curr_fattura,
          ["testo_aggiuntivo"]: val
        }
      })
    }

    controllaNote() {
      let res = !(this.state.curr_fattura.tipo_fattura === "NOTA_DI_CREDITO" && this.state.curr_fattura.testo_aggiuntivo === "")
      if(!res) {
        this.props.enqueueSnackbar(`ATTENZIONE: inserire il testo dele note.`, {variant:  'error', autoHideDuration: 8000})
      }
      return res
    }

    controllaDati() {
      let paz = this.props.paziente
      if(this.state.paziente !== undefined) {
        paz  = this.state.paziente
      }
      let nic = false
      let cap = false
      let indirizzo = false
      let citta = false

      if(paz.nic !== undefined && paz.nic.length === 16) {
        nic = true
      }

      if (paz.cap !== undefined && paz.cap.length === 5) {
        cap = true
      }

      if (paz.indirizzo_residenza !== undefined && paz.indirizzo_residenza.length >= 3) {
        indirizzo = true
      }

      if (paz.citta_residenza_testo_libero !== undefined && paz.citta_residenza_testo_libero.length >= 2) {
        citta = true
      }

      if(! (nic && cap && indirizzo && citta)) {
          let campi = ""
          if (!nic) {
            campi = "codice fiscale"
          }
          if (!cap) {
            if(campi.length > 0) {
              campi += ", "
            }
            campi += "CAP"
          }
          if (!citta) {
            if(campi.length > 0) {
              campi += ", "
            }
            campi += "città"
          }
          if (!indirizzo) {
            if(campi.length > 0) {
              campi += ", "
            }
            campi += "indirizzo"
          }
          this.setState({open: true})
          this.props.enqueueSnackbar(`ATTENZIONE: per poter emettere la fattura inserire le seguenti informazioni nell'anagrafica del paziente: ${campi}.`, {variant:  'error', autoHideDuration: 8000})

          return false;
      }

      return (nic && cap && indirizzo && citta);
    }

    normalizzaImporti(fatt) {
      for(let k=0; k < fatt.voci.length; ++k) {
        fatt.voci[k].importo = Number(fatt.voci[k].importo.toFixed(2))
      }
      for(let k=0; k < fatt.anticipi.length; ++k) {
        fatt.anticipi[k].importo = Number(fatt.anticipi[k].importo.toFixed(2))
      }
      for(let k=0; k < fatt.incassi.length; ++k) {
        fatt.incassi[k].importo = Number(fatt.incassi[k].importo.toFixed(2))
      }
      for(let k=0; k < fatt.scadenze.length; ++k) {
        fatt.scadenze[k].importo = Number(fatt.scadenze[k].importo.toFixed(2))
      }
    }

    loadPaziente() {
      if(this.state.paziente !== undefined && this.props.paziente.id === this.state.paziente.id) {
        return
      }
      let url_paziente = `${process.env.REACT_APP_API_URL}/dc/api_paziente/` + this.props.paziente.id
      fetch(url_paziente, {
                    headers: {
                        Authorization: `JWT ${localStorage.getItem('token')}`
                    }
      }).then(res => res.json())
                .then(res => {
                    const paz  = res.results[0]
                    if(paz.cellulare === '') {
                      if (Array.isArray(paz.telefono)) {
                        paz.cellulare = paz.telefono[0]
                      } else {
                        paz.cellulare = JSON.stringify(paz.telefono)
                      }
                    }
                    this.setState({
                        paziente: paz
                    })
      })
    }


    controllaPiani(fatt) {
      if (fatt.tipo_fattura === "ACCONTO" && fatt.piani_di_cura.length === 0) {
          this.props.enqueueSnackbar([`La fattura di ACCONTO deve essere associata almeno ad un piano di cure.`],
            {
              variant:  'error',
              autoHideDuration: 7000
            })
      }
      return (fatt.tipo_fattura !== "ACCONTO" || fatt.piani_di_cura.length > 0)
    }

    controllaFattureDiRiferimento(fatt) {
      if (fatt.tipo_fattura === "NOTA_DI_CREDITO" && fatt.fatture_di_riferimento.length === 0) {
          this.props.enqueueSnackbar([`La NOTA DI CREDITO deve essere associata almeno ad una fattura.`],
            {
              variant:  'error',
              autoHideDuration: 7000
            })
      }
      return (fatt.tipo_fattura !== "NOTA_DI_CREDITO" || fatt.fatture_di_riferimento.length > 0)
    }

    descriviPiani(piani) {
      let res = "";
      for(let k=0; k < piani.length; ++k) {
        let conn = ", ";
        if (k === piani.length - 1) {
          conn = " e ";
        };
        if (k === 0) {
          conn = "";
        };
        const p = piani[k];
        res += conn + "\"" + "#" + p.id + " " + p.nome + "\""; 
        
      }
      return res;
    }

    controllaImportiPiani(fatt) {
      if (fatt.tipo_fattura !== "ACCONTO") {
        return true;
      } else {
        const importo = this.getImportoAcconto(fatt);
        let importo_piani = 0.0;
        for(let k=0; k < fatt.piani_di_cura.length; ++k) {
          const piano = this.getPiano(fatt.piani_di_cura[k].id);
          importo_piani += Number((piano.prezzo_scontato_totale - piano.prezzo_scontato_eseguito).toFixed(2));
        }
        if(importo > importo_piani) {
          this.props.enqueueSnackbar([`Controlla gli importi inseriti e i piani di cura associati alla fattura.`, <br/>, `L'acconto non può superare la cifra di ${importo_piani} EUR.`],
            {
              variant:  'error',
              autoHideDuration: 7000
            })
        }
        return (importo <= importo_piani);
      }
    }



    controllaImporti(fatt) {
      this.normalizzaImporti(fatt)
      if (fatt.tipo_fattura === "NOTA_DI_CREDITO") {
        return true
      }
      if(fatt.voci.length === 0) {
        this.props.enqueueSnackbar(`Per salvare occorre inserire delle voci in fattura.`,
          {
            variant:  'error',
            autoHideDuration: 5000
          })
        return false;
      }
      if(this.getImporto(fatt, false) === 0) {
          this.props.enqueueSnackbar(`Per salvare occorre inserire degli importi in fattura.`,
          {
            variant:  'error',
            autoHideDuration: 5000
          })
        return false;
      }
      let pagamenti = 0.0
      const importo = this.getImporto(fatt, true)
      let bollo = 0.0
      if(this.hasBollo(fatt)) {
        bollo = 2.0
      }
      const tot_fatt = Number((importo + bollo).toFixed(2))
      if (tot_fatt < 0 && fatt.tipo_fattura !== "NOTA_DI_CREDITO") {
          this.props.enqueueSnackbar([`Controlla gli importi inseriti e gli sconti.`, <br/>, `L'importo della fattura non può essere negativo.`],
          {
            variant:  'error',
            autoHideDuration: 5000
          });
          return false;
      }
      fatt.incassi.map((row, idx) => {
        pagamenti += Number(row.importo)
      })
      fatt.scadenze.map((row, idx) => {
        pagamenti += Number(row.importo)
      })
      pagamenti = Number(pagamenti.toFixed(2))
      if (tot_fatt !== pagamenti) {
        this.props.enqueueSnackbar([`Controlla gli importi inseriti.`, <br/>, `Devi inserire ${tot_fatt} EUR fra incassi e scadenze.`],
          {
            variant:  'error',
            autoHideDuration: 5000
          })
      }
      
      return (tot_fatt === pagamenti)
    }

    controllaScadenze(fatt) {
      for(var k=0; k < fatt.scadenze.length; ++k) {
        const s = fatt.scadenze[k]
        if(s.importo <= 0) {
          this.props.enqueueSnackbar(`Inserire un importo per la scadenza.`, {variant:  'error', autoHideDuration: 5000})
          return false;
        }
        if(s.note.length == 0) {
          this.props.enqueueSnackbar(`Inserire le note per la scadenza.`, {variant:  'error', autoHideDuration: 5000})
          return false;
        }
      }
      return true;
    }

    salvaFattura() {
      let {curr_fattura} = this.state
      if(!this.controllaNote()) {
        this.setState({
          validate: true
        })
        return
      }
      if(!this.controllaDati()) {
        this.setState({
          validate: true
        })
        return
      }
      if(!this.controllaFattureDiRiferimento(curr_fattura)) {
        this.setState({
          validate: true
        })
        return
      }
      if(!this.controllaPiani(curr_fattura)) {
        this.setState({
          validate: true
        })
        return
      }
      if(false && !this.controllaImportiPiani(curr_fattura)) {
        this.setState({
          validate: true
        })
        return
      }
      if(!this.controllaImporti(curr_fattura)) {
        this.setState({
          validate: true
        })
        return
      }
      if(!this.controllaScadenze(curr_fattura)) {
        this.setState({
          validate: true
        })
        return
      }

      curr_fattura.voci = curr_fattura.voci.filter((voce) => { return voce.importo > 0 || (voce.tipo_voce !== "ACCONTO" && voce.tipo_voce !== "CONTRIBUTO")})

      curr_fattura.scadenze.map((sc) => {
          if (sc.compagnia_assicurativa_id === 0) {
              //sc.compagnia_assicurativa_id = null;
              ;
          }
      });

      if (curr_fattura.id === undefined || curr_fattura.id === null || curr_fattura.id === "") {
        const url_nuova_fattura = `${process.env.REACT_APP_API_URL}/dc/nuova_fattura_paziente/${this.props.paziente.id}/`
        //curr_fattura.numero_fattura = null
        this.normalizzaImporti(curr_fattura);
        this.setState({salva_in_corso: true}, () => {
          api.call_post(url_nuova_fattura, curr_fattura, (res) => {
            this.props.enqueueSnackbar(`Fattura inserita`, {variant: 'success', autoHideDuration: 4000})
            //this.loadFatturabiliSaldo()
            //this.loadFatturabiliAcconto()
            this.loadFatture()
          }, (err) => {
            this.setState({salva_in_corso: false});
            let msg = ""
            try {
              msg = JSON.stringify(err.response.data)
            } catch (e) {
              msg = JSON.stringify(e)
            }
            this.props.enqueueSnackbar(`Errore salvataggio dati: ${msg}`, {
              variant: 'error',
              autoHideDuration: 4000
            })
          })
        });
      } else {
        const url_modifica_fattura = `${process.env.REACT_APP_API_URL}/dc/fattura_paziente/${curr_fattura.id}/`
        this.normalizzaImporti(curr_fattura)
        this.setState({salva_in_corso: true}, () => {
          api.call_post(url_modifica_fattura, curr_fattura, (res) => {
            this.props.enqueueSnackbar(`Fattura salvata`, {variant: 'success', autoHideDuration: 4000})
            //this.loadFatturabiliSaldo()
            //this.loadFatturabiliAcconto()
            this.loadFatture()
          }, (err) => {
            this.setState({salva_in_corso: false});
            let msg = ""
            try {
              msg = JSON.stringify(err.response.data)
            } catch (e) {
              msg = JSON.stringify(e)
            }
            this.props.enqueueSnackbar(`Errore salvataggio dati: ${msg}`, {variant: 'error', autoHideDuration: 4000})
          }, "PUT")
        })
      }
    }

    emettiFattura() {
      let {curr_fattura} = this.state
      if (curr_fattura.id !== undefined) {
                  const url_emetti_fattura = `${process.env.REACT_APP_API_URL}/dc/emetti_fattura/${curr_fattura.id}/`
                  fetch(url_emetti_fattura, {
                    method: "POST",
                    headers: {
                      "Accept": "application/json",
                      "Content-Type": "application/json"
                    }
                  }).then((res) => {
                    this.loadFatture()
                  }).catch(function(ex) {
                    alert("parsing failed", ex);
                })
      }
    }

    handleChangeScadenzaDate(date, idx) {
      console.log(JSON.stringify(date))
    }


    cancellaFattura(id) {
      const url_cancella_fattura = `${process.env.REACT_APP_API_URL}/dc/cancella_fattura_paziente/` + id + "/"
      api.call_post(url_cancella_fattura, {}, (res) => {
        this.props.enqueueSnackbar(`Fattura cancellata`, {variant: 'success', autoHideDuration: 4000})
        this.loadFatture()
      }, (err) => {
        this.setState({salva_in_corso: false});
        let msg = ""
        try {
          msg = JSON.stringify(err.response.data)
        } catch (e) {
          msg = JSON.stringify(e)
        }
        this.props.enqueueSnackbar(`Errore: ${msg}`, {
          variant: 'error',
          autoHideDuration: 4000
        })
      });
    }

    componentDidMount() {
        this.loadPaziente()
        this.caricaAssicurazioni()
        //this.loadFatturabiliSaldo()
        //this.loadFatturabiliAcconto()
        this.caricaPiani()
        this.loadFatture()
        this.loadMetodi()
        this.setNuovaFattura()
        /*
        const metodi = this.loadApiMetodi()
        Promise.all([ metodi ]).then((responses) => {
            alert(JSON.stringify(responses[0]))
        });
        this.setState({
          metodi_pagamento: metodi,
          trattamenti_paziente: trattamenti,
          preventivi_paziente: piani,
          fatture_paziente: fatture,
          fatture_precedenti: this.getFatturePrecedenti(fatture),
          curr_fattura: undefined,
          in_preview: false,
          saldi: saldi,
          nuova_fattura: nuova_fattura
        })*/

    }

    componentDidUpdate(propsPrecedenti) {
      if (this.props.paziente && this.props.paziente.id !== propsPrecedenti.paziente.id) {
        this.loadPaziente()
        this.caricaPiani()
        this.loadFatture()
        this.loadMetodi()
        this.setNuovaFattura()
      } else if (this.state.paziente === undefined) {
        this.loadPaziente()
      } else if (this.props.curr_cod_fattura !== propsPrecedenti.curr_cod_fattura) {
          this.setFattura(undefined);
      }
    }

    getFatturePrecedenti(fatture_paziente) {
      const fatture_precedenti = fatture_paziente.filter((row) => {
        return (row.tipo_fattura === "ACCONTO" && row.importo_residuo > 0)
      }).map((row, idx) => {
        return {
          id: row.id,
          numero_fattura: row.numero_fattura,
          ordine: idx,
          descrizione: row.titolo_del_documento + " (" + row.tipo_fattura + ")",
          importo: -1 * row.importo_residuo
        }
      })
      return fatture_precedenti
    }

    getConfContributo(fatture_paziente) {
      //se un acconto utilizzabile ha un contributo, abilito il contributo anche per il saldo
      let res = {
        abilitato: this.state.conf_contributo.abilitato,
        abilitato_globale: this.state.conf_contributo.abilitato_globale,
        soglia: this.state.conf_contributo.soglia
      };
      if (!res.abilitato) {
      fatture_paziente.map((row) => {
        if (row.tipo_fattura.indexOf("ACCONTO") >= 0) {
            console.log(JSON.stringify(row))
            if (row.importo_residuo > 0) {
              row.voci.map((voce) => {
                if(voce.tipo_voce === "CONTRIBUTO" && voce.importo > 0) {
                  res.abilitato = true;
                }
                return false;
              });
            }
          }
      })
      }
      return res
    }


    getAcconti(fatture_precedenti, importo) {
      const acconti = []
      fatture_precedenti.map((row, idx) => {
        if (importo - row.importo_residuo >= 0) {
          acconti.push(row)
          importo -= row.importo_residuo
        }
      })
      return acconti
    }


    handleChange = (event, value) => {
        this.loadFatture()
        this.setState({ value })
    }

    setFattura = (fattura, edit) => {
      this.setState({
        curr_fattura: fattura,
        edit: edit
      })
    }

    setPreview = (fattura) => {
      if(fattura !== undefined) {
        this.setState({
          curr_fattura: fattura,
          in_preview: !this.state.in_preview
        })
      } else {
        this.setState({
          in_preview: !this.state.in_preview
        })
      }
    }

    setPaziente = (paziente) => {
        this.setState({
          paziente: paziente
        })
    }

    setIntestatario = (intestatario) => {
        this.setState({
          curr_fattura: {
                  ...this.state.curr_fattura,
                  'intestatario_id': intestatario.id,
                  'intestatario': intestatario,
          },
          edit_intestatario: false
        }, this.getVociFattura);
    }

    getVociFattura() {
      let {curr_fattura} = this.state
      //alert(JSON.stringify(curr_fattura.voci))
      this.loadFatturabili(curr_fattura, curr_fattura.voci)
      curr_fattura.voci = []
      this.setState({
        curr_fattura: curr_fattura,
        isLoading: true
      })
    }

    loadFatturabili = (curr_fattura, old_voci) => {
        let url_fatturabili = `${process.env.REACT_APP_API_URL}/dc/fatturabili_saldo/${this.props.paziente.id}/`
        if(curr_fattura.tipo_fattura === "ACCONTO") {
            url_fatturabili = `${process.env.REACT_APP_API_URL}/dc/fatturabili_acconto/${this.props.paziente.id}/`
        }
        api.call_get(url_fatturabili, (result) => {
          if(curr_fattura.tipo_fattura === "NOTA_DI_CREDITO") {
            this.getVociFatturaAsync([], old_voci)
          } else {
            this.getVociFatturaAsync(result.results, old_voci)
          }
        })
    }

    getVociFatturaAsync(fatturabili, old_voci) {
      let importo = 0.0
      let {curr_fattura} = this.state

      /*let fatturabili = this.state.fatturabili_saldo
      if(curr_fattura.tipo_fattura === "ACCONTO") {
        fatturabili = this.state.fatturabili_acconto
      }*/
      let voci_libere = old_voci.filter((row) => {return row.tipo_voce === "ACCONTO"})
      if (voci_libere.length === 0) {
        voci_libere = [
              {
                "tipo_voce": "ACCONTO",
                "descrizione": 'Caparra per cure odontoiatriche',
                "importo_currency": "EUR",
                "importo": 0.0,
                "ordine": curr_fattura.voci.length + 1,
                "fattura": curr_fattura.id,
                "stato_esecuzione_trattamento": null
              }]
      }
      let importo_libere = 0.0;
      voci_libere.map((row, idx) => {
        importo_libere += row.importo;
      });

      if (!this.state.edit) return
      const moment = new MomentUtils()
      try {
      const voci = fatturabili.map((row, idx) => {
        let descrizione = row.descrizione
        let prezzo = row.fatturare_come_anticipo ?  row.anticipo_per_fattura : row.prezzo_per_fattura
        if(curr_fattura.tipo_fattura.indexOf("SALDO") >= 0) {
          prezzo = row.prezzo_per_saldo
          descrizione = row.descrizione_saldo
        }


        importo += prezzo
        return {
          "tipo_voce": "TRATTAMENTO",
          "descrizione_piano": row.descrizione_piano,
          "descrizione": descrizione,
          "importo_currency": "EUR",
          "importo": prezzo,
          "ordine": idx,
          "stato_esecuzione_trattamento": row.id,
          "anticipo": row.fatturare_come_anticipo
        }
      })
      
      const abilita_contributo = (curr_fattura.tipo_fattura.indexOf("SALDO") >= 0 && this.state.conf_contributo.abilitato && importo + importo_libere >= this.state.conf_contributo.soglia) || (curr_fattura.tipo_fattura === "ACCONTO" && this.state.conf_contributo.abilitato_globale);

      if (abilita_contributo) {
        voci.push({
          "tipo_voce": "CONTRIBUTO",
          "descrizione_piano": '',
          "descrizione": 'Contributo ex art 1, comma 442 - legge 27 dicembre 2017 N. 205',
          "importo_currency": "EUR",
          "importo": this.calcContrib(curr_fattura, importo + importo_libere),
          "ordine": voci.length,
          "stato_esecuzione_trattamento": null,
          "anticipo": false
        })
        importo += this.calcContrib(curr_fattura, importo + importo_libere);
      }
      curr_fattura["voci"] = voci
      curr_fattura.anticipi = [];
      curr_fattura.altri_anticipi = []
      const fatture = this.state.fatture_paziente.slice(0)
      fatture.sort((a,b) => {
        return a.id - b.id
      })
      for(let k = 0; k < fatture.length; ++k) {
          const f = fatture[k]
          const ir = parseFloat(f.importo_residuo)
          if (ir === 0) {
            continue
          }
          if (curr_fattura.id === undefined && ((f.intestatario && f.intestatario_id !== curr_fattura.intestatario_id) || (!f.intestatario && f.paziente_id !== curr_fattura.intestatario_id))) {
            let altro_intestatario = "";
            if(f.intestatario) {
              altro_intestatario = f.intestatario.cognome + " " + f.intestatario.nome;
            } else {
              altro_intestatario = this.state.paziente.cognome + " " + this.state.paziente.nome;
            }
            curr_fattura.altri_anticipi.push(
              {
                  "fattura_anticipo_id": f.id,
                  "descrizione_anticipo": f.tipo_fattura + " Fattura n. " + f.numero_fattura + " del " + moment.moment(f.data_fattura).format("DD/MM/YYYY"),
                  "importo": ir,
                  "residuo": parseFloat(f.importo_residuo) - ir,
                  "residuo_iniziale": ir,
                  "in_uso": false,
                  "altro_intestatario": altro_intestatario
              });
              continue;
          }
          //qui dovrei gestire i casi con acconti di un altro intestatario
          if (f.tipo_fattura === "ACCONTO" && f.su_piano_attivo) {
                  if (importo - ir > 0) {
                      curr_fattura.anticipi.push(
                          {
                              "fattura_anticipo_id": f.id,
                              "descrizione_anticipo": f.tipo_fattura + " Fattura n. " + f.numero_fattura + " del " + moment.moment(f.data_fattura).format("DD/MM/YYYY"),
                              "importo": ir,
                              "residuo": parseFloat(f.importo_residuo) - ir,
                              "residuo_iniziale": ir,
                              "in_uso": true,
                              "altro_intestatario": undefined
                          }
                      )
                      importo -= ir
                  } else {
                      curr_fattura.anticipi.push(
                          {
                              "fattura_anticipo_id": f.id,
                              "descrizione_anticipo": f.tipo_fattura + " Fattura n. " + f.numero_fattura + " del " + moment.moment(f.data_fattura).format("DD/MM/YYYY"),
                              "importo": importo,
                              "residuo": parseFloat(f.importo_residuo) - importo,
                              "residuo_iniziale": parseFloat(f.importo_residuo),
                              "in_uso": (importo > 0),
                              "altro_intestatario": undefined
                          }
                      )
                      importo = 0
                  }
              }

      }
    } catch(e) {
        //alert(JSON.stringify(e));
    }
    if(curr_fattura.tipo_fattura === "ACCONTO") {
          voci_libere.map((voce) => {
            curr_fattura.voci.push(voce)
            return false
          })
          curr_fattura.anticipi = []
    }

    if(curr_fattura.tipo_fattura === "NOTA_DI_CREDITO") {
      curr_fattura.voci.push({
        "tipo_voce": "CREDITO",
        "descrizione": 'Importo nota di credito',
        "importo_currency": "EUR",
        "importo": 0.0,
        "ordine": curr_fattura.voci.length + 1,
        "fattura": curr_fattura.id,
        "stato_esecuzione_trattamento": null
      })
      curr_fattura.anticipi = []
    }

    if(curr_fattura.incassi.length === 1) {
        const val = this.getImporto(curr_fattura, true)
        if(val >= 77.47) {
          curr_fattura.incassi[0].importo =  val + 2.0
        } else {
          curr_fattura.incassi[0].importo = val
        }
    }

      this.setState({
        curr_fattura: curr_fattura,
        isLoading: false
      })

    }

    cambiaDescr(val, idx) {
      let {curr_fattura} = this.state
      curr_fattura.voci[idx].descrizione = val
      this.setState({curr_fattura: curr_fattura})
    }

    cambiaImporto(val, idx, tipo_voce) {
      let {curr_fattura} = this.state
      if(curr_fattura.tipo_fattura === "NOTA_DI_CREDITO" && val > 0) {
        val *= -1
      }
      if(tipo_voce === 'SCONTO' && val > 0) {
          val = val * -1;
      }
      curr_fattura.voci[idx].importo = val

      let contributo = undefined;
      let parz = val;
      curr_fattura.voci.map((row, idx) => {
          if(row.tipo_voce === "CONTRIBUTO") {
              contributo = row;
          }
      });
      if(contributo) {
          contributo.importo = 0.0;
          let nc = this.getImporto(curr_fattura, true);
          contributo.importo = this.calcContrib(curr_fattura, nc);
      }


      if(curr_fattura.incassi.length === 1) {
        if(val >= 77.47) {
          curr_fattura.incassi[0].importo = this.getImporto(curr_fattura, true) + 2.0
        } else {
          curr_fattura.incassi[0].importo = this.getImporto(curr_fattura, true)
        }
      }

      this.setState({curr_fattura: curr_fattura})
    }

    cambiaImportoInc(val, idx) {
      let {curr_fattura} = this.state
      curr_fattura.incassi[idx].importo = val
      this.setState({curr_fattura: curr_fattura})
    }

    handleIncassoNote(val, idx) {
      let {curr_fattura} = this.state
      curr_fattura.incassi[idx].note = val
      this.setState({curr_fattura: curr_fattura})
    }

    handleScadenzaNote(val, idx) {
      let {curr_fattura} = this.state
      curr_fattura.scadenze[idx].note = val
      this.setState({curr_fattura: curr_fattura})
    }

    handleTipo = (evt) => {
      let { curr_fattura } = this.state
      const tipo_fattura = evt.target.value
      curr_fattura.tipo_fattura = tipo_fattura
      if(tipo_fattura === "SALDO_FINALE") {
        curr_fattura.tipo_fattura = "SALDO"
        curr_fattura.saldo_finale = true
      } else {
        curr_fattura.saldo_finale = false
      }
      curr_fattura.testo_aggiuntivo = ""
      this.getVociFattura()
      this.setState({
        curr_fattura: curr_fattura,
        tipo_fattura: tipo_fattura
      })
      this.getVociFattura()
    }

    getImporto(curr_fattura, con_acconti) {
      let res = 0.0
      try {
          curr_fattura.voci.map((row) => {
            res += parseFloat(row.importo)
          })
          if (con_acconti) {
            curr_fattura.anticipi.map((row) => {
              res -= parseFloat(row.importo)
            })
          }
      } catch (e) {;}
      res = parseFloat(res.toFixed(2))
      return res
    }

    getImportoAcconto(curr_fattura) {
      let res = 0.0;
      try {
          curr_fattura.voci.map((row) => {
            if(row.tipo_voce !== "CONTRIBUTO") {
              res += parseFloat(row.importo)
            }
          })
      } catch (e) {;}
      res = parseFloat(res.toFixed(2))
      return res
    }

    hasBollo(curr_fattura) {
      const importo = this.getImporto(curr_fattura, true)
      return (importo >= 77.47)
    }

    handleChangeDate(date) {
      try {
            this.setState({
              curr_fattura: {
                  ...this.state.curr_fattura,
                  'data_fattura': date.format('YYYY-MM-DD')
              }
          })
      } catch(e) {
        ;
      }
    }

    handleChangePiani(values) {
      const piani = values.map(id => {
        let nome = 'piano'
        this.state.preventivi_paziente.map(prev => {
          if (prev.id === id && prev.nome !== "") nome = prev.nome
        })
        return {id: id, nome: nome} }
      )
      this.setState({
        curr_fattura: {
          ...this.state.curr_fattura,
          piani_di_cura: piani
        }
      })
    }

    handleChangeFattureAssociate(values) {
      let fatts = []
      for (let k=0; k < this.state.fatture_paziente.length; ++k) {
          const curr_fatt = this.state.fatture_paziente[k]
          for(let j=0; j < values.length; ++j) {
            if(values[j] === curr_fatt.id) {
              fatts.push(curr_fatt)
            }
          }
      }
      this.setState({
        curr_fattura: {
          ...this.state.curr_fattura,
          fatture_di_riferimento: fatts
        }
      })
    }

    handleChangeNumero = (ev) => {
      const value = ev.target.value
      this.setState({
        curr_fattura: {
            ...this.state.curr_fattura,
            numero_fattura: value
        }
    })
  }

    nuovaVoce(sconto) {
      let { curr_fattura } = this.state
        if (sconto) {
            curr_fattura.voci.push({
                  "tipo_voce": "SCONTO",
                  "descrizione": 'Omaggio al cliente',
                  "importo_currency": "EUR",
                  "importo": 0.0,
                  "ordine": curr_fattura.voci.length,
                  "fattura": curr_fattura.id,
                  "stato_esecuzione_trattamento": null
                });
        } else {
            curr_fattura.voci.push({
                  "tipo_voce": "ACCONTO",
                  "descrizione": 'Caparra per cure odontoiatriche',
                  "importo_currency": "EUR",
                  "importo": 0.0,
                  "ordine": curr_fattura.voci.length,
                  "fattura": curr_fattura.id,
                  "stato_esecuzione_trattamento": null
                });
        }
      this.setState({
        curr_fattura: curr_fattura
      })
    }

    ricalcolaIncasso(curr_fattura) {
      if(curr_fattura.incassi.length === 1) {
        const val = this.getImporto(curr_fattura, true)
        if(val >= 77.47) {
          curr_fattura.incassi[0].importo =  val + 2.0
        } else {
          curr_fattura.incassi[0].importo = val
        }
      }
    }

    ricalcolaAnticipi(curr_fattura, importo) {
      for (let k=0; k < curr_fattura.anticipi.length; ++k) {
        let  fatt = curr_fattura.anticipi[k]
        if(fatt.in_uso) {
          const ir = fatt.residuo_iniziale
          if(importo - ir > 0) {
                fatt.importo = ir
                fatt.residuo = 0
                importo -= ir
              } else {
                fatt.importo = importo
                fatt.in_uso = (importo > 0)
                fatt.residuo = ir - importo
                importo = 0
              }
          }
      }
    }

    calcContrib(curr_fattura, importo) {
        let res = Number(0).toFixed(0);
        try {
          let contributo_abilitato = this.state.conf_contributo.abilitato; 
          if (curr_fattura.tipo_fattura === "ACCONTO") {
            contributo_abilitato = this.state.conf_contributo.abilitato_globale;
          }
          if (contributo_abilitato && importo >= this.state.conf_contributo.soglia) {
            res = Number((importo * 0.5 / 100).toFixed(0));
          }
        } catch (e) {
            ;
        }
        return res;
    }

    rimuoviVoce(idx) {
      let { curr_fattura } = this.state
      const daRimuovere = curr_fattura.voci[idx]
      curr_fattura.voci.splice(idx, 1)
      if (curr_fattura.tipo_fattura.indexOf("SALDO") >= 0) {
        let importo = 0.0
        if(daRimuovere.tipo_voce !== "CONTRIBUTO") {
          curr_fattura.voci = curr_fattura.voci.filter((row) => {return (row.tipo_voce !== "CONTRIBUTO")})
          importo = this.getImporto(curr_fattura, false)
          //aggiunge il contributo se abilitato e se saldo e il totale supera la soglia
          const aggiungi_contributo = (curr_fattura.tipo_fattura === "ACCONTO" && this.state.conf_contributo.abilitato_globale) || (importo >= this.state.conf_contributo.soglia && this.state.conf_contributo.abilitato && curr_fattura.tipo_fattura.indexOf("SALDO") >= 0);
          if (aggiungi_contributo) {
            curr_fattura.voci.push({
              "tipo_voce": "CONTRIBUTO",
              "descrizione_piano": '',
              "descrizione": 'Contributo ex art 1, comma 442 - legge 27 dicembre 2017 N. 205',
              "importo_currency": "EUR",
              "importo": this.calcContrib(curr_fattura, importo),
              "ordine": curr_fattura.voci.length,
              "stato_esecuzione_trattamento": null,
              "anticipo": false
            })
          importo += this.calcContrib(curr_fattura, importo);
        }
      } else {
        importo = this.getImporto(curr_fattura, false)
      }
      this.ricalcolaAnticipi(curr_fattura, importo)
    }
    this.ricalcolaIncasso(curr_fattura)
    this.setState({
        curr_fattura: curr_fattura
    })
  }

    rimuoviAnticipo(idx) {
      let { curr_fattura } = this.state
      const ant = curr_fattura.anticipi[idx]
      let imp = ant.importo
      ant.importo = 0
      ant.residuo += imp
      for (let k = 0; k < curr_fattura.anticipi.length && imp > 0; ++k) {
        if (k !== idx) {
          const diff = Math.min(curr_fattura.anticipi[k].residuo, imp)
          curr_fattura.anticipi[k].residuo -= diff
          curr_fattura.anticipi[k].importo += diff
          imp -= diff
        }
      }
      this.ricalcolaIncasso(curr_fattura)
      this.setState({
        curr_fattura: curr_fattura
      })
    }

    toggleAnticipo(fatt, idx) {
      let { curr_fattura } = this.state
      fatt.in_uso = !fatt.in_uso
      if(!fatt.in_uso) {
        fatt.residuo += fatt.importo
        fatt.importo = 0
      } else {
        const imp = this.getImporto(curr_fattura, true)
        const diff = Math.min(fatt.residuo, imp)
        fatt.residuo -= diff
        fatt.importo += diff
      }
      this.ricalcolaIncasso(curr_fattura)
      this.setState({
        curr_fattura: curr_fattura
      })
    }

    nuovoIncasso() {
      let { curr_fattura } = this.state
      curr_fattura.incassi.push(
        {

            "data": new Date().toISOString().substring(0, 10),
            "importo_currency": "EUR",
            "importo": 0.00,
            "note": "",
            "contestuale": true,
            "metodo_pagamento": 1

        }
      )
      this.setState({
        curr_fattura: curr_fattura
      })
    }


    nuovaScadenza() {
      let { curr_fattura } = this.state
      curr_fattura.scadenze.push(
        {
            "data_scadenza": new Date().toISOString().substring(0, 10),
            "importo_currency": "EUR",
            "importo": 0.00,
            "compagnia_assicurativa_id": 0,
            "note": ""
        }
      )
      this.setState({
        curr_fattura: curr_fattura
      })
    }

    modificaDataScadenza(data, idx) {
      let { curr_fattura } = this.state
      curr_fattura.scadenze[idx].data_scadenza = data.format("YYYY-MM-DD")
      this.setState({
        curr_fattura: curr_fattura
      })
    }

    modificaImportoScadenza(val, idx) {
      let {curr_fattura} = this.state
      curr_fattura.scadenze[idx].importo = val
      this.setState({curr_fattura: curr_fattura})
    }



    rimuoviIncasso(idx) {
      let { curr_fattura } = this.state
      curr_fattura.incassi.splice(idx)
      this.setState({
        curr_fattura: curr_fattura
      })
    }

    rimuoviScadenza(idx) {
      let { curr_fattura } = this.state
      curr_fattura.scadenze.splice(idx)
      this.setState({
        curr_fattura: curr_fattura
      })
    }

    handleMetodoPagamento(evt, idx) {
      const m = evt.target.value
      let { curr_fattura } = this.state
      curr_fattura.incassi[idx].metodo_pagamento = m
      this.setState({
        curr_fattura: curr_fattura
      })

    }

    handleCompagnia(evt, idx) {
        const c = evt.target.value;
        let { curr_fattura } = this.state;
        curr_fattura.scadenze[idx].compagnia_assicurativa_id = c;
        this.setState({
            curr_fattura: curr_fattura
        })
    }

    urlPdf() {
      if (this.state.curr_fattura !== undefined) {
        const url_pdf = `${process.env.REACT_APP_API_URL}/dc/stampa_fattura/${this.state.curr_fattura.id}.pdf`
        return url_pdf
      }
      return ""
    }

    getMetodoPagamento(id) {
      for (let k = 0; k < this.state.metodi_pagamento.length; ++k) {
          if(this.state.metodi_pagamento[k].id == id) {
            return this.state.metodi_pagamento[k]
          }
      }
      return undefined
    }

    chkTurnoOpen() {
      //canEdit: api.canEditCassa(),
      const profilo_operatore_reloaded = api.reloadOperatore((operatore) => {
        const canOpen = api.canOpenCassa();
        if (operatore.utente.sede_login.nome.toUpperCase() !== api.sedeLogin()) {
          this.props.enqueueSnackbar("Attenzione, il tuo account è utilizzato contemporaneamente per accedere a sedi diverse! Esegui il Logout e nuovamente il login per procedere.",
            {
              variant: 'error',
              autoHideDuration: 15000
            })
        } else {
          if (canOpen) {
            this.props.enqueueSnackbar("E' necessario aprire la cassa per inserire una fattura.",
              {
                variant: 'error',
                autoHideDuration: 5000
              })
          } else {
            const {nuova_fattura} = this.state
            this.setFattura({...nuova_fattura}, true)
          }
        }
      });
      if (!profilo_operatore_reloaded) {
        this.props.enqueueSnackbar("Errore caricando il profilo dell'operatore, impossibile procedere.",
          {
            variant:  'error',
            autoHideDuration: 5000
          })
      }
    }

    setEditFattura() {
      let tipo = this.state.curr_fattura.tipo_fattura
      if(tipo === "SALDO" && this.state.curr_fattura.saldo_finale) {
        tipo = "SALDO_FINALE"
      }
      if(!this.state.edit) {
        //devo editarla, mi salvo la fattura
        const backup_fattura = JSON.parse(JSON.stringify(this.state.curr_fattura))
        this.setState({
          edit: !this.state.edit,
          backup_fattura: backup_fattura,
          tipo_fattura: tipo
        })
      } else {
        const curr_fattura = JSON.parse(JSON.stringify(this.state.backup_fattura))
        this.setState({
          edit: !this.state.edit,
          curr_fattura: curr_fattura,
          tipo_fattura: tipo
        })
      }
    }

    toggleOpen() {
      this.setState({
        open: !this.state.open
      })
    }

    toggleOpenIntestatario() {
      this.setState({
        edit_intestatario: !this.state.edit_intestatario
      })
    }

    haIntestatariDiversi() {
        let res = false;
        for(let k=0; k < this.state.fatture_paziente.length; ++k) {
            const fatt = this.state.fatture_paziente[k];
            if(fatt.intestatario && fatt.intestatario_id !== fatt.paziente_id) {
                res = true;
                break;
            }
        }
        return res;
    }

    formatIntest(intest) {
        let res = "";
        let conn = "";
        if(intest.fatture.length > 1) {
            res = "Le fatture ";
            conn = " sono intestate a ";
        } else {
            res = "La fattura "
            conn = " è intestata a ";
        }
        for (let k=0; k < intest.fatture.length; ++k) {
            res += " " + intest.fatture[k];
        }
        res += conn;
        res += intest.cognome + " " + intest.nome + ".";
        return res;
    }

    listaIntestatariDiversi() {
        const intestatari = {};
        const res = [];
        for(let k=0; k < this.state.fatture_paziente.length; ++k) {
            const fatt = this.state.fatture_paziente[k];
            if(fatt.intestatario && fatt.intestatario_id !== fatt.paziente_id) {
                const key = fatt.intestatario_id;
                if (!(key in intestatari)) {
                    intestatari[key] = {
                        fatture: [],
                        cognome: fatt.intestatario.cognome,
                        nome: fatt.intestatario.nome
                    }
                }
                intestatari[key]['fatture'].push(fatt.numero_fattura);
            }
        }
        for (const k in intestatari) {
            res.push(this.formatIntest(intestatari[k]));
        }
        return res;
    }

    

    render() {
      const { classes } = this.props
        const moment = new MomentUtils()
        const { tipo_fattura }  = this.state
        const { curr_fattura }  = this.state
        const { isLoading } = this.state
        const { in_preview }  = this.state
        const { nuova_fattura } = this.state
        const { metodi_pagamento } = this.state
        const { preventivi_paziente } = this.state
        const { edit } = this.state
        let paziente  = this.props.paziente
        if(this.state.paziente !== undefined) {
          paziente  = this.state.paziente
        }
        if (curr_fattura && !curr_fattura.intestatario) {
              curr_fattura.intestatario  = paziente
              curr_fattura.intestatario_id  = paziente.id
        }

        let anticipi_non_usati = false;

        const url_pdf = this.urlPdf()
        const { canEdit } = this.state

        const nomeSede = api.sedeLogin()

        let piani_values = [];
        let piani_non_acconto = [];


        if (this.state.curr_fattura) {
          piani_values = this.state.curr_fattura.piani_di_cura.map(piano => { return piano.id })
        }

        if(piani_values.length === 0 && this.state.curr_fattura && this.state.curr_fattura.tipo_fattura === "ACCONTO" && !this.state.curr_fattura.id) {
          piani_values = preventivi_paziente.filter((piano) => {
            return (piano.eseguibile === true && piano.fatturabile_in_acconto)
          }).map(piano => { return piano.id })
          if(piani_values.length > 0)
            this.handleChangePiani(piani_values)
        }

        if(this.state.curr_fattura && this.state.curr_fattura.tipo_fattura === "ACCONTO" && !this.state.curr_fattura.id) {
          piani_non_acconto = preventivi_paziente.filter((piano) => {
              return (piano.eseguibile === true && piano.ha_trattamenti_da_fatturare && !piano.fatturabile_in_acconto)
          });
        }

        let fatture_values = []
        if (this.state.curr_fattura) {
          fatture_values = this.state.curr_fattura.fatture_di_riferimento.map(fatt => { return fatt.id })
        }


        const options_preventivi = preventivi_paziente.map((row) => {
          return ({
                value: row.id,
                label: row.nome + " #" + row.id
              })
        })
        const options_metodi = metodi_pagamento.filter((row) => {return (curr_fattura && curr_fattura.tipo_fattura !== "NOTA_DI_CREDITO" || (row.nome.toLowerCase() === "contanti" || row.nome.toLowerCase() === "bonifico"))}).map((row) => {
          return ({
                value: row.id,
                label: row.nome
              })
        })

        const intestatari_diversi = this.haIntestatariDiversi();
        const lista_intestatari_diversi = this.listaIntestatariDiversi();
    return(
      <Container component="main" maxWidth="md">
          <MuiPickersUtilsProvider utils={MomentUtils} locale={'it'}>
          {/*<TurnoCassa setEditCassa={this.setEditCassa.bind(this)} />*/}
            {curr_fattura === undefined && <TabContainer>
            {this.state.fatture_paziente.length === 0 && <h5>Nessuna fattura per {paziente.cognome} {paziente.nome}</h5>}
            {this.state.fatture_paziente.length > 0 && <Grid container>
                <Grid item xs={6}><h5>Fatture di {paziente.cognome} {paziente.nome}</h5></Grid>
                <Grid item xs={6}><h5 style={{color: "#A60000", "float": "right"}}>
                    {intestatari_diversi && <><PeopleIcon/> = intestatario diverso dal paziente</>}
                </h5>
                </Grid>
            </Grid>}
            <Table size='small' padding="checkbox">
              <TableHead>
                <TableRow>
                    <TableCell>Numero</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Documento</TableCell>
                    <TableCell>Piani</TableCell>
                    <TableCell align="right">Importo</TableCell>
                    <TableCell align="right">Disponibile</TableCell>
                    <TableCell align="right">Incassi</TableCell>
                    <TableCell align="right">

                      <Button onClick={() => { this.chkTurnoOpen() }} variant="contained" color="primary"><CreateIcon/></Button>

                    </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.fatture_paziente.map((item) => {
                  const titleResiduo = "somma disponibile " + item.importo_residuo;
                  let prodotto = ""
                  if(item.prodotto_assicurativo !== null && item.prodotto_assicurativo !== undefined) {
                    prodotto = " - " + item.prodotto_assicurativo.nome
                  }
                  let intest = "";
                  if(item.intestatario) {
                      intest = "Intestata a " + item.intestatario.cognome + " " + item.intestatario.nome;
                  }
                  return (<TableRow className={classes.root}>
                    <TableCell>
                    {item.intestatario &&
                        item.intestatario_id !== item.paziente_id &&
                        <Tooltip title={intest}><span style={{color: "#A60000"}}><PeopleIcon/> {item.numero_fattura}</span></Tooltip>}
                    {(!item.intestatario ||
                        item.intestatario_id === item.paziente_id) &&
                        <span>{item.numero_fattura}</span>}
                    </TableCell>
                    <TableCell>
                    {moment.moment(item.data_fattura).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                    {item.tipo_fattura.replace(/_/g, " ")} {item.saldo_finale && <span>FINALE</span>}
                    </TableCell>
                    <TableCell>
                    {item.piani_di_cura.map((piano, idx) => {
                        return("#" + piano.id + " " + piano.nome + " ")
                    })}
                    </TableCell>
                    <TableCell align="right">
                        {item.tipo_fattura !== "SALDO" && <NumberFormat value={Number(item.importo) + Number(item.importo_bollo)} displayType={'text'} suffix="" thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={2} decimalScale={2} align="right" />}
                        {item.tipo_fattura === "SALDO" && <NumberFormat value={this.getImporto(item, false)} displayType={'text'} suffix="" thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={2} decimalScale={2} align="right" />}
                    </TableCell>
                    <TableCell align="right"  style={{color: "#008500"}}>
                        {item.importo_residuo > 0 && <NumberFormat value={Number(item.importo_residuo)} displayType={'text'} suffix="" thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={2} decimalScale={2} align="right" />}
                    </TableCell>
                    <TableCell align="right">
                    <NumberFormat value={Number(item.incassato)} displayType={'text'} suffix="" thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={2} decimalScale={2} align="right" />
                    </TableCell>
                    <TableCell align="right">
                      {item.stato === "BOZZA" && <span>
                        <Button onClick={() => { this.setFattura(item, false) }} variant="contained" color="primary"><EditIcon/></Button>
                        <Button onClick={() => { this.setPreview(item) }} variant="contained" color="primary"><PdfIcon/></Button>
                        <Button onClick={() => { this.cancellaFattura(item.id) }} variant="contained" color="secondary"><DeleteIcon/></Button>
                        </span>
                        }
                        {item.stato !== "BOZZA" && <span>
                        <Button onClick={() => { this.setFattura(item, false) }} variant="contained" color="primary"><EditIcon/></Button>
                        </span>
                        }
                        {/*<Button onClick={() => { this.setFattura(item) }} variant="contained" color="primary">Visualizza</Button>*/}
                    </TableCell>
                  </TableRow>)
                  }
                )
                }
                </TableBody>
              </Table>
              <RiepilogoEseguiti paziente_id={this.props.paziente_id} />
              </TabContainer>}
            {curr_fattura !== undefined && <Grid container spacing={12
            }>
            <Grid item xs={10}>
            {curr_fattura.id === undefined && <h5>Nuova fattura <span style={{color: "#A60000"}}>SEDE: {nomeSede}</span></h5>}
            {curr_fattura.id === undefined && lista_intestatari_diversi.length === 0 && preventivi_paziente.filter((piano) => {
                          if(curr_fattura.tipo_fattura === "ACCONTO") {
                            return (piano.eseguibile)
                          } else {
                            return (piano.eseguibile || piano.stato === "Completato")
                          }
                      }).length ===0 && <h5><span style={{color: "#A60000"}}>ATTENZIONE: il paziente non ha piani di cura approvati a cui associare la fattura.</span></h5>}
            
            {curr_fattura.id === undefined && curr_fattura.tipo_fattura === "ACCONTO" && piani_non_acconto.length === 1 && <h5><span style={{color: "#A60000"}}>ATTENZIONE: il piano "#{piani_non_acconto[0].id} {piani_non_acconto[0].nome}" è fatturabile solo a saldo.<br/>PER QUESTO PIANO SI DEVONO ESEGUIRE I TRATTAMENTI ED EMETTERE LE FATTURE SENZA FARE ACCONTI.</span></h5>}
            {curr_fattura.id === undefined && curr_fattura.tipo_fattura === "ACCONTO" && piani_non_acconto.length > 1 && <h5><span style={{color: "#A60000"}}>ATTENZIONE: i piani {this.descriviPiani(piani_non_acconto)} sono fatturabili solo a saldo.<br/>PER QUESTI PIANI SI DEVONO ESEGUIRE I TRATTAMENTI ED EMETTERE LE FATTURE SENZA FARE ACCONTI.</span></h5>}
            


            {curr_fattura.id === undefined && lista_intestatari_diversi.length >0 && <ul style={{"list-style-type": "none", "margin": 0, "padding": 0}}>
                <li><h5 style={{color: "#A60000", margin: 0, padding: 0}}>ATTENZIONE:</h5></li>
                {lista_intestatari_diversi.map((item) => {
                    return (<li><h5 style={{color: "#A60000", margin: 0, padding: 0}}>{item}</h5></li>);
                })}
                </ul>}
            {curr_fattura.id !== undefined &&  <h5>Fattura #{curr_fattura.numero_fattura}</h5>}
            </Grid>
            <Grid item xs={2}>
              <Button onClick={() => { this.setFattura(undefined) }} variant="contained" color="default"><ListIcon/></Button>
              <Button onClick={() => { this.setPreview() }} variant="contained" color="default"><PdfIcon/></Button>
            </Grid>
            {(curr_fattura.id === undefined || !in_preview) && <>
            <Grid item xs={3}>
              <Paper style={{padding: "8px", "margin-bottom": "4px"}}>
                <Typography variant="subtitle1"><b>Paziente: </b></Typography>
                <Typography variant="body1">
                  {paziente.cognome} {paziente.nome}
                </Typography>
              </Paper>
              <Paper style={{padding: "8px"}}>
                <Typography variant="subtitle1"><b>Destinatario: </b> {edit &&
                    <Button
                  onClick={ () => {
                      this.setState({"edit_intestatario": true})
                  } }
                  variant="contained"
                  color="secondary">Cambia <PersonIcon/></Button>
                }</Typography>
                <Typography variant="body1">
                  {curr_fattura.intestatario.cognome} {curr_fattura.intestatario.nome}<br/>
                  { curr_fattura.intestatario.indirizzo_residenza || 'indirizzo residenza' }<br/>
                  {curr_fattura.intestatario.cap || 'CAP'} {curr_fattura.intestatario.citta_residenza_testo_libero || 'citta'}<br/>
                  Codice Fiscale: {curr_fattura.intestatario.nic || 'codice fiscale'}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={9}>
            <Paper style={{padding: "8px", "margin-left": "12px"}}>
              <Grid container>
                <Grid item xs={6}>
                  <FormLabel component="legend">Tipo Fattura</FormLabel>
                  {edit && <RadioGroup
                    aria-label=""
                    name="tipo_fattura"
                    className={classes.group}
                    defaultValue={curr_fattura.id && tipo_fattura}
                    onChange={(val)=> {this.handleTipo(val)}}
                    row
                  >
                  <FormControlLabel value="SALDO" control={<Radio />} label="Saldo Parziale" />
                  <FormControlLabel value="SALDO_FINALE" control={<Radio />} label="Saldo Finale" />
                  <FormControlLabel value="ACCONTO" control={<Radio />} label="Acconto" />
                  <FormControlLabel value="NOTA_DI_CREDITO" control={<Radio />} label="Nota di credito" />
                  </RadioGroup>}
                  {!edit && curr_fattura.tipo_fattura.replace(/_/g, " ")}
                </Grid>
                <Grid item xs={3} align="right">
                  {!edit && curr_fattura.id && <Tooltip title="modifica fattura"><Button
                  onClick={ () => {this.setEditFattura()} }
                  variant="contained"
                  color="secondary">Modifica <EditIcon/></Button></Tooltip>}
                  {edit && curr_fattura.id && <Tooltip title="annulla modifiche"><Button
                  onClick={ () => {this.setEditFattura()} }
                  variant="contained"
                  color="secondary">Annulla <EditIcon/></Button></Tooltip>}
                </Grid>
                <Grid item xs={3} align="right">
                  {!edit &&<Tooltip title="cancella fattura"><Button
                  onClick={this.props.confirm(() => { this.cancellaFattura(curr_fattura.id) }, {
                    title: 'Cancella fattura',
                    description: "Vuoi davvero cancellare questa fattura?",
                    confirmationText: 'Sì',
                    cancellationText: 'No'
                    })}
                  variant="contained"
                  color="default">Cancella <DeleteIcon/></Button></Tooltip>}
                </Grid>

                <Grid item xs={6}>
                  {edit && <KeyboardDatePicker
                      disableFuture={true}
                      name="data_fattura"
                      label="Data fattura"
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      value={curr_fattura.data_fattura || null}
                      onChange={(date) => {this.handleChangeDate(date)}}
                      style={{ width: "90%" }}
                    />}
                </Grid>
                <Grid item xs={6} align="right">
                {edit && <TextField
                    id="numero_fattura"
                    label="Num. fattura"
                    name="numero_fattura"
                    helperText="lasciare vuoto per numerazione automatica"
                    value={this.state.curr_fattura.numero_fattura}
                    onChange={(val)=> {this.handleChangeNumero(val)}}
                  />}
                  {!edit && <span>Fattura n. {this.state.curr_fattura.numero_fattura} del { moment.moment(this.state.curr_fattura.data_fattura).format("DD/MM/YYYY") }</span>}
                  </Grid>
                  <Grid item xs={12}>
                  {edit && this.state.curr_fattura && this.state.curr_fattura.tipo_fattura === "NOTA_DI_CREDITO"  && <MultipleSelect
                        label="Associa fatture"
                        values={fatture_values}
                        error={this.state.validate && fatture_values.length === 0}
                        options={this.state.fatture_paziente.filter((fatt) => {
                          return (fatt.tipo_fattura !== "NOTA_DI_CREDITO")
                      }).map(fatt => { return {value: fatt.id, label: fatt.numero_fattura + " del " + fatt.data_fattura}})}
                        helperText="Scegli fatture da associare"
                        onChange={this.handleChangeFattureAssociate.bind(this)}
                        SelectProps={{
                        isCreatable: false,
                        msgNoOptionsAvailable: "Non ci sono fatture da associare."
                        }}
                      />}
                        {!edit && this.state.curr_fattura && <div>
                        <b>Piani associati:</b> {this.state.curr_fattura.piani_di_cura.map((piano) => {return ("#" + piano.id + " " + piano.nome) }).join("; ")}
                        </div>}
                        {edit && this.state.curr_fattura && this.state.curr_fattura.tipo_fattura === "ACCONTO"  && <MultipleSelect
                        label="Associa piani di cure"
                        values={piani_values}
                        error={this.state.validate && piani_values.length === 0}
                        options={preventivi_paziente.filter((piano) => {
                          return (this.state.curr_fattura.id || piano.eseguibile)
                      }).map(piano => { return {value: piano.id, label: "#" + piano.id + " " + piano.nome}})}
                        helperText="Scegli piani di cure da associare"
                        onChange={this.handleChangePiani.bind(this)}
                        SelectProps={{
                        isCreatable: false,
                        msgNoOptionsAvailable: "Non ci sono piani da associare."
                        }}
                      />}
                        {!edit && this.state.curr_fattura && this.state.curr_fattura.tipo_fattura === "NOTA_DI_CREDITO" && <div>
                        <b>Fatture di riferimento:</b> {this.state.curr_fattura.fatture_di_riferimento.map((f) => {return ("N. " + f.numero_fattura + " del " + f.data_fattura) }).join("; ")}
                        </div>}
                </Grid>
              </Grid>
            </Paper>
            </Grid>
            {isLoading && <h4 style={{color: "#A60000", align: 'center'}} >Attendere il caricamento delle voci...</h4>}
            {!isLoading && curr_fattura.voci.length === 0 && <h4>Nessuna voce</h4>}
            {!isLoading && curr_fattura.tipo_fattura !== "NOTA_DI_CREDITO" && tipo_fattura !== "SALDO_FINALE" && curr_fattura.voci.length > 0 && <h4>Voci Fatturabili (rimuovere quelle che non interessano):</h4>}
            {!isLoading && tipo_fattura === "SALDO_FINALE" && curr_fattura.voci.length > 0 && <h4>Voci Fatturabili:</h4>}
            <Table dense={true} border={0}>
            {curr_fattura.voci.length > 0 && <>
            <TableRow border={0}>
                <TableCell border={0}><b>VOCI</b></TableCell>
                <TableCell border={0} align="right"><b>IMPORTI</b></TableCell>
                <TableCell>
                  {edit && <Tooltip title="ricarica trattamenti"><Button color="default" onClick={() => { this.getVociFattura() }}><RefreshIcon/></Button></Tooltip>}
                </TableCell>
            </TableRow>
                {(!edit && curr_fattura.tipo_fattura === 'ACCONTO') && curr_fattura.voci_acconto && curr_fattura.voci_acconto.map((voce, idx) => {
                  return (<TableRow>
                    <TableCell>
                        {voce}
                    </TableCell>
                    <TableCell align="right">
                    &nbsp;
                    </TableCell>
                  </TableRow>)
                })}
                {(!edit && curr_fattura.tipo_fattura === 'ACCONTO') && curr_fattura.voci && curr_fattura.voci.map((voce, idx) => {
                  if (voce.tipo_voce === "CONTRIBUTO") {
                      voce.importo = parseFloat(voce.importo)
                      return (<TableRow>
                          <TableCell>
                              {voce.descrizione}
                          </TableCell>
                          <TableCell align="right">
                              <NumberFormat
                                  value={voce.importo}
                                  displayType={'text'}
                                  fixedDecimalScale={2}
                                  decimalScale={2}
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  suffix={' EUR'}
                                  align="right"
                              />
                          </TableCell>
                      </TableRow>)
                  }
                })}
                {(edit || curr_fattura.tipo_fattura !== 'ACCONTO') && curr_fattura.voci && curr_fattura.voci.map((voce, idx) => {
                  voce.importo = parseFloat(voce.importo)
                  const descrizione_display = [voce.descrizione , <br/>, <small>{voce.descrizione_piano}</small>]
                  return (<TableRow>
                    <TableCell>
                    {edit && voce.tipo_voce !== "TRATTAMENTO" &&  voce.tipo_voce !== "CONTRIBUTO" &&  voce.tipo_voce !== "SCONTO" &&
                    <TextField
                    style={{width: "100%"}}
                    value={voce.descrizione}
                    onChange={(evt)=> {this.cambiaDescr(evt.target.value, idx)}}
                    />
                    }
                    {!edit && voce.tipo_voce !== "TRATTAMENTO" && voce.tipo_voce !== "CONTRIBUTO" && voce.tipo_voce !== "SCONTO" && voce.descrizione}
                    {(voce.tipo_voce === "TRATTAMENTO" || voce.tipo_voce === "CONTRIBUTO" || voce.tipo_voce === "SCONTO") && descrizione_display}
                    </TableCell>
                    <TableCell align="right">
                    {edit && voce.tipo_voce !== "TRATTAMENTO" && voce.tipo_voce !== "CONTRIBUTO" &&
                    <NumberFormat
                    defaultValue={0.00}
                    allowEmptyFormatting={true}
                    value={voce.importo}
                    customInput={TextField}
                    fixedDecimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    decimalScale={2}
                    suffix={' EUR'}
                    onValueChange={(values)=> {this.cambiaImporto(values.floatValue, idx, voce.tipo_voce)}}
                    align="right"
                    />}
                    {!edit && voce.tipo_voce !== "TRATTAMENTO" &&  voce.tipo_voce !== "CONTRIBUTO" &&
                    <NumberFormat
                    value={voce.importo}
                    displayType={'text'}
                    fixedDecimalScale={2}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    suffix={' EUR'}
                    align="right"
                    />}
                    {(voce.tipo_voce === "TRATTAMENTO" || voce.tipo_voce === "CONTRIBUTO") &&
                    <NumberFormat
                    value={voce.importo}
                    displayType={'text'}
                    fixedDecimalScale={2}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    suffix=' EUR'
                    />}
                    </TableCell>
                    {edit  && (tipo_fattura !== "SALDO_FINALE" || voce.tipo_voce === "SCONTO") && !(voce.tipo_voce === "CONTRIBUTO" && tipo_fattura === "ACCONTO") &&
                    <TableCell>
                    <Tooltip title="rimuovi voce"><Button color="default" onClick={() => { this.rimuoviVoce(idx) }}><DeleteIcon/></Button></Tooltip>
                    </TableCell>
                    }
                    {!edit &&
                    <TableCell>

                    </TableCell>
                    }
                  </TableRow>)
                  }
                )
            }
            {edit && curr_fattura.tipo_fattura === "ACCONTO" &&
            <TableRow>
              <TableCell colSpan={3}>
                <Button color="secondary" onClick={() => { this.nuovaVoce(false) }}>Aggiungi voce</Button>
              </TableCell>
            </TableRow>
            }
            {edit && curr_fattura.tipo_fattura !== "ACCONTO" &&
            <TableRow>
              <TableCell colSpan={3}>
                <Button color="secondary" onClick={() => { this.nuovaVoce(true) }}>Aggiungi sconto (omaggio al cliente)</Button>
              </TableCell>
            </TableRow>
            }
            <TableRow>
                <TableCell><b>TOTALE CONCORDATO</b></TableCell>
                <TableCell align="right">
                  <b><NumberFormat value={this.getImporto(curr_fattura, false)} displayType={'text'} fixedDecimalScale={2} decimalScale={2} suffix="" decimalSeparator="," thousandSeparator="." /></b>
                </TableCell>
                <TableCell></TableCell>
            </TableRow>
            </>}
            {curr_fattura.tipo_fattura.indexOf("SALDO") >= 0 && curr_fattura.voci.length > 0 && curr_fattura.anticipi && curr_fattura.anticipi.map((fatt, idx) => {
                let stile = {}
                if (!fatt.in_uso) anticipi_non_usati = true;

                if(fatt.residuo_iniziale > fatt.importo) anticipi_non_usati = true;

                if (edit && !fatt.in_uso) {
                  if(fatt.altro_intestatario) {
                    stile = {
                      color: "#A60000",
                      "text-decoration": "line-through"
                    }
                  } else {
                    stile = {
                      color: "#888888",
                      "text-decoration": "line-through"
                    }
                  }
                }

                return (<TableRow>
                  <TableCell>
                  <span style={stile}>{fatt.descrizione_anticipo}</span> {edit && fatt.residuo_iniziale && fatt.residuo_iniziale | 0 && <span style={stile}>(importo utilizzabile <NumberFormat value={Number(fatt.residuo_iniziale)} displayType={'text'} fixedDecimalScale={2} decimalScale={2} decimalSeparator="," thousandSeparator="." suffix="" />)</span>}
                  {edit && !fatt.in_uso && fatt.altro_intestatario && <><br/><span style={{color: "#A60000", "font-weight": "bold"}}><PersonIcon/> ATTENZIONE: intestare la fattura a {fatt.altro_intestatario} per poter utilizzare questo acconto.</span></>}                                                     
                  </TableCell>
                  <TableCell align="right">
                    { (!edit || fatt.in_uso) && <>- <NumberFormat value={Number(fatt.importo)} displayType={'text'} fixedDecimalScale={2} decimalScale={2} decimalSeparator="," thousandSeparator="." suffix="" /></>}
                  </TableCell>
            <TableCell>

                {edit && !fatt.altro_intestatario && tipo_fattura !== "SALDO_FINALE" &&<Tooltip title="seleziona anticipo"><Checkbox checked={fatt.in_uso} color="primary"
                                                     onClick={(value) => {
                                                       this.toggleAnticipo(fatt, idx)
                                                     }}/></Tooltip>}
                
            </TableCell>
                </TableRow>)
            })}
          
          {curr_fattura.altri_anticipi && curr_fattura.altri_anticipi.length > 0 && <TableRow>
              <TableCell colSpan={3} style={{color: "#A60000", "font-weight": "bold"}}>
              <PersonIcon/> Acconti con intestatari diversi
              </TableCell>
          </TableRow>}
          {curr_fattura.tipo_fattura.indexOf("SALDO") >= 0 && curr_fattura.voci.length > 0 && curr_fattura.altri_anticipi && curr_fattura.altri_anticipi.map((fatt, idx) => {
                let stile = {}
                if (!fatt.in_uso) anticipi_non_usati = true;

                if(fatt.residuo_iniziale > fatt.importo) anticipi_non_usati = true;

                if (edit && !fatt.in_uso) {
                    stile = {
                      color: "#A60000"
                    }
                }

                return (<TableRow>
                  <TableCell>
                  <span style={stile}>{fatt.descrizione_anticipo}</span> {edit && fatt.residuo_iniziale && fatt.residuo_iniziale | 0 && <span style={stile}>(importo utilizzabile <NumberFormat value={Number(fatt.residuo_iniziale)} displayType={'text'} fixedDecimalScale={2} decimalScale={2} decimalSeparator="," thousandSeparator="." suffix="" />)</span>}
                  {edit && !fatt.in_uso && fatt.altro_intestatario && <><br/><span style={{color: "#A60000", "font-weight": "bold"}}>ATTENZIONE: intestare la fattura a {fatt.altro_intestatario} per poter utilizzare questo acconto.</span></>}                                                     
                  </TableCell>
                  <TableCell align="right">
                    { (!edit || fatt.in_uso) && <>- <NumberFormat value={Number(fatt.importo)} displayType={'text'} fixedDecimalScale={2} decimalScale={2} decimalSeparator="," thousandSeparator="." suffix="" /></>}
                  </TableCell>
            <TableCell>
                
            </TableCell>
                </TableRow>)
            })}
            


            {curr_fattura.tipo_fattura.indexOf("SALDO") >= 0 && curr_fattura.voci.length > 0 && curr_fattura.anticipi.length > 0 && <TableRow>
                <TableCell><b>TOTALE</b></TableCell>
                <TableCell align="right">
                <b><NumberFormat value={this.getImporto(curr_fattura, true)} displayType={'text'} fixedDecimalScale={2} decimalSeparator="," thousandSeparator="." decimalScale={2} suffix="" /></b>
                </TableCell>
                <TableCell></TableCell>
            </TableRow>}
            {curr_fattura.voci.length > 0 && <>
            <TableRow>
                <TableCell><b>BOLLO</b></TableCell>
                <TableCell align="right">
                  {this.hasBollo(curr_fattura) &&<b><NumberFormat value={2.0} displayType={'text'} fixedDecimalScale={2} decimalScale={2} suffix="" decimalSeparator="," thousandSeparator="." /></b>}
                  {!this.hasBollo(curr_fattura) &&<b>NO</b>}
                </TableCell>
                <TableCell> </TableCell>
            </TableRow>
            <TableRow>
            <TableCell><b>DA INCASSARE</b></TableCell>
            <TableCell align="right">
              {this.hasBollo(curr_fattura) &&<b><NumberFormat value={this.getImporto(curr_fattura, true) + 2.0} displayType={'text'} fixedDecimalScale={2} decimalScale={2} suffix="" decimalSeparator="," thousandSeparator="." /></b>}
              {!this.hasBollo(curr_fattura) &&<b><NumberFormat value={this.getImporto(curr_fattura, true)} displayType={'text'} fixedDecimalScale={2} decimalScale={2} suffix="" decimalSeparator="," thousandSeparator="." /></b>}
            </TableCell>
            <TableCell> </TableCell>
            </TableRow></>}
            </Table>
            </>}
            {curr_fattura.id !== undefined && in_preview &&
            <Grid item xs={12}>
              <PreviewPdf url_pdf={url_pdf}/>
            </Grid>
            }

            {curr_fattura.voci.length > 0 &&<>
            <h3>Incassi</h3>
            <Table>
            {!edit && curr_fattura.incassi.map((incasso, idx) => {
                  const curr_metodo = this.getMetodoPagamento(incasso.metodo_pagamento)
                  incasso.importo = parseFloat(incasso.importo)
                  return (<div><TableRow>
                    <TableCell>
                     {curr_metodo.nome}
                    </TableCell>
                    <TableCell>
                      {moment.moment(incasso.data).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell align="right">
                    <NumberFormat
                    label={'importo'}
                    value={incasso.importo}
                    displayType={'text'}
                    fixedDecimalScale={2}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    suffix=""
                    onValueChange={(values)=> {this.cambiaImportoInc(values.floatValue, idx)}}
                    />
                    </TableCell>
                    <TableCell>

                    </TableCell>

                  </TableRow>
                  {curr_metodo.note_obbligatorie && <TableRow>
                    <TableCell colSpan={4}>
                    <TextField
                      style={{width: "100%"}}
                      label={"note (" + curr_metodo.suggerimento_note + ")"}
                      value={incasso.note || curr_metodo.suggerimento_note}
                      onChange={(evt)=> {this.handleIncassoNote(evt.target.value, idx)}}
                      />
                    </TableCell>

                  </TableRow>}</div>)
                  }
                )
            }
              {edit && curr_fattura.incassi.map((incasso, idx) => {
                  incasso.importo = parseFloat(incasso.importo)
                  return (<div><TableRow>
                    <TableCell>
                    <Select
                    required
                    variant="filled"
                    color="inherit"
                    name='metodo'
                    label='Metodo di pagamento'
                    style={{width: "400px"}}
                    fullWidth={true}
                    value={incasso.metodo_pagamento}
                    onChange={(val)=> {this.handleMetodoPagamento(val, idx)}}
                    >
                    {options_metodi.map((item, index) => {
                      return (<MenuItem value={item.value}>{item.label}</MenuItem>)
                    })}
                    </Select>
                    </TableCell>
                    <TableCell align="right">
                    <NumberFormat
                    defaultValue={0.00}
                    allowEmptyFormatting={true}
                    value={incasso.importo}
                    customInput={TextField}
                    fixedDecimalScale={2}
                    decimalScale={2}
                    suffix=""
                    decimalSeparator=","
                    thousandSeparator="."
                    onValueChange={(values)=> {this.cambiaImportoInc(values.floatValue, idx)}}
                    align="right"
                    />
                    </TableCell>
                    <TableCell>
                    <Tooltip title="rimuovi incasso"><Button color="default" onClick={() => { this.rimuoviIncasso(idx) }}><DeleteIcon/></Button></Tooltip>
                    </TableCell>

                  </TableRow>
                  {metodi_pagamento[incasso.metodo_pagamento -1].note_obbligatorie && <TableRow>
                    <TableCell colSpan={4}>
                    <TextField
                      style={{width: "100%"}}
                      label={"note (" + metodi_pagamento[incasso.metodo_pagamento -1].suggerimento_note + ")"}
                      value={incasso.note || metodi_pagamento[incasso.metodo_pagamento -1].suggerimento_note}
                      onChange={(evt)=> {this.handleIncassoNote(evt.target.value, idx)}}
                      />
                    </TableCell>

                  </TableRow>}</div>)
                  }
                )
            }

            {edit && <TableRow>
              <TableCell colSpan={2}>
                <Button color="secondary" onClick={() => { this.nuovoIncasso() }}>Aggiungi incasso</Button>
              </TableCell>
            </TableRow>}
            </Table></>}


            {curr_fattura.tipo_fattura !== "NOTA_DI_CREDITO" && <>
            <h3>Scadenze</h3>
            <Table>
            {!edit && curr_fattura.scadenze.map((scadenza, idx) => {
                  scadenza.importo = parseFloat(scadenza.importo)
                  return (<TableRow>
                    <TableCell>
                    {this.getCompagnia(scadenza.compagnia_assicurativa_id)}
                    </TableCell>
                    <TableCell>
                      {moment.moment(scadenza.data_scadenza).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell align="right">
                    <NumberFormat
                    label={'importo'}
                    value={scadenza.importo}
                    displayType={'text'}
                    fixedDecimalScale={2}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    suffix=""
                    />
                    </TableCell>
                    <TableCell>
                    {scadenza.note}
                    </TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>)
                  }
                )
            }

            {edit && curr_fattura.scadenze.map((scadenza, idx) => {
                  return (<TableRow>
                    <TableCell>
                    <Select
                    required
                    variant="filled"
                    color="inherit"
                    name='compagnia_assicurativa'
                    label='Assicurazione'
                    style={{width: "200px"}}
                    fullWidth={true}
                    value={scadenza.compagnia_assicurativa_id}
                    onChange={(val)=> {this.handleCompagnia(val, idx)}}
                    >
                    {this.state.assicurazioni.map((item, index) => {
                      return (<MenuItem value={item.id}>{item.nome}</MenuItem>)
                    })}
                    </Select>
                    </TableCell>
                    <TableCell>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      value={scadenza.data_scadenza}
                      onChange={(data) => {this.modificaDataScadenza(data, idx)}}
                    />
                    </TableCell>
                    <TableCell align="right">
                    <NumberFormat
                    defaultValue={0.00}
                    allowEmptyFormatting={true}
                    value={scadenza.importo}
                    customInput={TextField}
                    fixedDecimalScale={2}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    suffix=""
                    onValueChange={(values)=> {this.modificaImportoScadenza(values.floatValue, idx)}}
                    />
                    </TableCell>
                    <TableCell>
                    <TextField
                      required={true}
                      error={this.state.validate}
                      name={'note'}
                      label={'Note'}
                      style={{width: "200px"}}
                      value ={scadenza.note}
                      onChange={(evt)=> {this.handleScadenzaNote(evt.target.value, idx)}}
                      helperText='inserisci note'
                    />
                    </TableCell>
                    <TableCell>
                    <Tooltip title="rimuovi scadenza"><Button color="default" onClick={() => { this.rimuoviScadenza(idx) }}><DeleteIcon/></Button></Tooltip>
                    </TableCell>
                  </TableRow>)
                  }
                )
            }


            {edit && <TableRow>
              <TableCell colSpan={2}>
                <Button color="secondary" onClick={() => { this.nuovaScadenza() }}>Aggiungi scadenza</Button>
              </TableCell>
            </TableRow>}
            </Table>
          </>}

          {curr_fattura.tipo_fattura === "NOTA_DI_CREDITO" && <>
              <h3>Note</h3>
              <Grid container spacing={12}>
                {edit && <Grid item xs={12}>
                <TextareaAutosize
                    id='testo_aggiuntivo'
                    label='Note'
                    value={curr_fattura.testo_aggiuntivo}
                    rows={6}
                    style={{width: "98%"}}
                    placeholder="Note"
                    error={this.state.validate && curr_fattura.testo_aggiuntivo === ''}
                    onChange={(evt) => {
                      this.handleNote(evt.target.value)
                    }}
                    />
                </Grid>}
                {!edit && <Grid item xs={12}>
                {curr_fattura.testo_aggiuntivo}
                </Grid>}
              </Grid>
          </>}







            {edit &&
            <Button variant="contained" disabled={this.state.salva_in_corso} color="primary" onClick={() => { this.salvaFattura() }}>Salva fattura</Button>
            }
            &nbsp;
            {curr_fattura.stato === "BOZZA" && curr_fattura.id !== undefined &&
            <Button variant="contained" color="primary" onClick={() => { this.emettiFattura() }}>Emetti fattura</Button>}
            </Grid>
          }
          {this.state.paziente && <DialogPaziente paziente_id={this.state.paziente.id} paziente={this.state.paziente} open={this.state.open} toggleOpen={this.toggleOpen.bind(this)} setPaziente={this.setPaziente.bind(this)} />}

          {this.state.paziente && <DialogIntestatario open={this.state.edit_intestatario} toggleOpen={this.toggleOpenIntestatario.bind(this)} setIntestatario={this.setIntestatario.bind(this)} />}
              {tipo_fattura === "SALDO_FINALE" && anticipi_non_usati && this.props.confirm(() => { }, {
                    title: 'Saldo finale',
                    description: "Non puoi fare una fattura a saldo finale perché ci sono acconti inutilizzati.",
                    confirmationText: 'OK',
                    cancellationText: ''
                    })}
          </MuiPickersUtilsProvider>

        </Container>
    )
    }


    getCompagnia(compagnia_assicurativa_id) {
        let res = "A carico del paziente";
        this.state.assicurazioni.map((el) => {
            if(el.id == compagnia_assicurativa_id) {
                res = el.nome;
                return;
            }
        });
        return res;
    }
}
export default withStyles(styles)(withSnackbar(withConfirm(Fatture)))
