import React, { Component } from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ListIcon from '@material-ui/icons/List'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
//import DialogPaziente from './dialogs/DialogPaziente'
import Fab from '@material-ui/core/Fab' 
import Tooltip from '@material-ui/core/Tooltip'
import NumberFormat from 'react-number-format'
import { BrowserRouter as Router, Route, Link as RouterLink } from "react-router-dom"
import { Link } from '@material-ui/core'
import { Add, Edit, EuroSymbol } from '@material-ui/icons'
import Container from '@material-ui/core/Container'
import api from "../api_calls/api"
import PreviewPdf from "../components/PreviewPdf"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
//import TurnoCassa from './TurnoCassa'
import { withSnackbar } from 'notistack'
import withConfirm from 'material-ui-confirm'
import MomentUtils from '@date-io/moment'
import PdfIcon from '@material-ui/icons/PictureAsPdf'
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";



const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    }, 
      palette: {
        primary: {
          light: '#0000',
          main: '#00000',
          dark: '#0000',
          contrastText: '#fff',
        },
        secondary: {
          light: '#ff7961',
          main: '#f44336',
          dark: '#ba000d',
          contrastText: '#000',
        },
      },
}));






function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

class ListaIncassi extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      logged_in: localStorage.getItem('token') ? true : false,
      lista_incassi: [],
      curr_turno: undefined,
      curr_incasso: undefined,
      curr_foglio: undefined,
      date: "today",
      canEdit: false,
      q: ''
    } 
  }

  setEditCassa(val) {
    this.setState({
      canEdit: val
    })
  }

  urlPdf() {
    if (this.state.curr_incasso !== undefined) {
      const url_pdf = `${process.env.REACT_APP_API_URL}/dc/stampa_ricevuta_incassi/?incassi_id=${this.state.curr_incasso.id}`
      console.log(url_pdf)
      return url_pdf
    }
    return ""
  }


  urlTurno(turno) {
    if(this.state.sedeCorrente) {
      const op = api.getOperatore();
      const dt = new Date()
      const moment = new MomentUtils();
      const oggi = moment.moment(dt).format("YYYYMMDD");
      const id_sede = op.utente.sede_corrente.id;
      const id_utente = op.id;
      const url_pdf = `${process.env.REACT_APP_API_URL}/dc/stampa_foglio_incassi/${oggi}/${turno}/${id_sede}/${id_utente}/`;
      console.log(url_pdf);
      return url_pdf;
    }
  }




  
  componentDidMount() {
        api.reloadCurrTurno()
        this.cercaIncassi(this.state.q, this.state.date)
  }

  componentWillUpdate() {
    
  }

  cercaIncassiQ = (evt) => {
    this.cercaIncassi(this.state.q, this.state.date)
  }

  cercaIncassi = (q, date) => {
    const url_paz = `${process.env.REACT_APP_API_URL}/dc/ricerca_incassi/${date}/?q=` 
    api.call_get(url_paz + q, (result) => {
      this.setState({
        lista_incassi: result.results,
        q: q,
        date: date,
        sedeCorrente: localStorage.getItem('sede_corrente_nome')
      })
    })
  }

  cancellaIncasso(id) {
      /* TODO: capire perché il confirm di material UI non sta funzionando
      this.props.confirm(() => {
        const url_incasso = `${process.env.REACT_APP_API_URL}/dc/api_incasso/${id}/`
        const method = 'DELETE'
        api.call_post(
          url_incasso, 
          this.state.curr_incasso, 
          (res) => {
            this.props.enqueueSnackbar(`Incasso cancellato.`, {variant:  'success', autoHideDuration: 4000})
            window.setTimeout(() => { window.location.href = "/gui/lista_incassi/" }, 1750)
          },
          (err) => { 
              const msg = JSON.stringify(err)
            this.props.enqueueSnackbar(`Errore nel salvataggio dei dati: ${msg}`, {variant:  'error', autoHideDuration: 4000})
          },
          method)
      }, {
      title: 'Cancella incasso',
      description: "Vuoi davvero cancellare questo incasso?",
      confirmationText: 'Sì',
      cancellationText: 'No'
      })*/

      const cancella = window.confirm("Vuoi davvero cancellare questo incasso?")
      if(cancella) {
        const url_incasso = `${process.env.REACT_APP_API_URL}/dc/api_incasso/${id}/`
        const method = 'DELETE'
        api.call_post(
          url_incasso, 
          this.state.curr_incasso, 
          (res) => {
            this.props.enqueueSnackbar(`Incasso cancellato.`, {variant:  'success', autoHideDuration: 4000})
            window.setTimeout(() => { window.location.href = "/gui/lista_incassi/" }, 1750)
          },
          (err) => { 
              const msg = JSON.stringify(err)
            this.props.enqueueSnackbar(`Errore salvataggio dati: ${msg}`, {variant:  'error', autoHideDuration: 4000})
          },
          method)
      }
  }

  changeDate = (evt) => {
    this.cercaIncassi(this.state.q, evt.target.value)
  }


  handleChange = (event, value) => {
    if (value === 'piano') {
      this.setState({ curr_piano: null })
    }
    this.setState({ value });
  };

  setCurrIncasso = (incasso) => {
    this.setState({
      curr_incasso: incasso
    })
  }

  setCurrFoglio = (foglio) => {
    if (this.state.curr_foglio !== undefined) {
      this.setState({
        curr_foglio: undefined
      });
    } else {
      this.setState({
        curr_foglio: foglio
      });
    }
  }

  caricaPiano = (id) => {
    let piano = undefined
    const url_piani = `${process.env.REACT_APP_API_URL}/dc/piani_terapeutici/${this.props.pid}`
      fetch(url_piani, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(result => result.json())
        .then(result => {
          const piani  = result.results
          for (let k = 0; k < piani.length; ++k) {
            if (piani[k].id = id) {
              piano = piani[k]
              break
            }
          }
          this.setState({
            curr_piano: id,
            piano: piano,
            value: 'piano'
          })
      }) 
  }

  clickPianoUtente(id) {
    this.caricaPiano(id)
  }

  chkTurnoOpen() {
    //canEdit: api.canEditCassa(),
     const canOpen = api.canOpenCassa()
    if (canOpen) {
      this.props.enqueueSnackbar("E' necessario aprire la cassa per inserire un incasso.", 
          {
            variant:  'error', 
            autoHideDuration: 5000
          }) 
    } else {
      window.location.href = "/gui/nuovo_incasso/"
    }
  }

  
  render() {
    const classes = {}
    const { date }  = this.state
    const { q }  = this.state
    const { curr_incasso, curr_foglio } = this.state
    const { canEdit } = this.state
    const moment = new MomentUtils()
    const {sedeCorrente} = this.state
    
    return (
      <Container component="main" maxWidth="md">
          {curr_incasso === undefined && <Grid container xs={12}>
            <Grid item xs={12} >
                  <Grid container xs={12}>
                    <Grid item xs={12} md={3}>
                       <h2>Ricerca incassi<br/>{sedeCorrente}</h2>
                    </Grid>
                    <Grid item xs={12} md={5} >
                    <TextField
                    name='lista_incassi'
                    label='Cerca per paziente'
                    value={q}
                    style={{ width: "80%" }}
                    onChange={(evt) => this.setState({
                      q:  evt.target.value
                    })}
                    onKeyDown={(evt) => { if (evt.key === 'Enter') this.cercaIncassiQ(); }}
                    InputProps={{endAdornment:  <IconButton
                                aria-label="Cerca"
                                title="Cerca"
                                color="primary"
                                size="small"
                                onClick={this.cercaIncassiQ}
                                edge="end"><SearchIcon />
                              </IconButton>}}
                    />
                    </Grid>   
                    <Grid item xs={12} md={4} >
                    <RadioGroup
                      aria-label="Date"
                      name="date"
                      className={classes.group}
                      value={date}
                      onChange={this.changeDate}
                    >
                      <Grid container>
                        <Grid item xs={8}>
                          <FormControlLabel value="today" control={<Radio />} label="Incassi di oggi" />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel value="other" control={<Radio />} label="Precedenti" />
                        </Grid>
                      </Grid>
                      </RadioGroup>
                    </Grid>
                 </Grid>
                 <Grid container xs={12}>
                   <Grid item xs={6}>&nbsp;</Grid>
                   <Grid item xs={3}>
                          <Button onClick={() => { this.setCurrFoglio("08-14") }} variant="contained" color="default">Incassi 08-14 <PdfIcon/></Button>
                   </Grid>
                    <Grid item xs={3}>
                      <Button onClick={() => { this.setCurrFoglio("14-20") }} variant="contained" color="default">Incassi 14-20 <PdfIcon/></Button>
                    </Grid>
                 </Grid>
            </Grid>
            {curr_foglio !== undefined && <Grid container xs={12}>
              <Grid item xs={12}><br/>
                <PreviewPdf url_pdf={this.urlTurno(curr_foglio)}/>
              </Grid>
          </Grid>}

            {/*<TurnoCassa setEditCassa={this.setEditCassa.bind(this)} />*/}
            <Grid item xs={12} >
              <Paper elevation={5} style={{ margin: '0 15px 5px 0' }}>
                  <h3>Lista incassi {date === 'today' ? 'incassi di oggi' : 'precedenti'}</h3>
                  <Table dense={false}>
                    <TableHead>
                        <TableRow>
                          <TableCell>Data</TableCell>
                          <TableCell>Paziente</TableCell>
                          <TableCell align="right">Importo</TableCell>
                          <TableCell>
                          <Link color="inherit" onClick={() => {this.chkTurnoOpen()} }>
                          <Tooltip title="nuovo incasso">
                              <Fab size="small" color="secondary">
                                <Add/>
                              </Fab>
                            </Tooltip>  
                          </Link>
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.lista_incassi.map((item) => {
                      return (<TableRow>
                          <TableCell>{moment.moment(item.data).format("DD/MM/YYYY")}</TableCell>
                          <TableCell>{item.text}</TableCell>
                          <TableCell align="right">
                            <NumberFormat 
                                value={Number(item.importo)} 
                                displayType={'text'}
                                fixedDecimalScale={2} 
                                decimalScale={2} 
                                decimalSeparator=','
                                thousandSeparator='.'
                                suffix={' EUR'}
                                />
                          </TableCell>
                          <TableCell>
                  
                            <Tooltip title="visualizza">
                              <Fab size="small" color="secondary" onClick={() => { this.setCurrIncasso(item) }}>
                                <EditIcon/>
                              </Fab>
                            </Tooltip>  
                            
                          </TableCell>
                      </TableRow>)
                    })
                    }
                    </TableBody>
                  </Table>
              </Paper>
            </Grid>  
             
            <Grid item xs={2} >
                
            </Grid>  
          </Grid>}

          {curr_incasso !== undefined && <Paper elevation={5} style={{ margin: '0 15px 5px 0' }}><Grid container style={{padding: "5px 8px 5px 8px"}} spacing={1}>
            <Grid item xs={12}>
                <Grid container spacing={5} padding={5}>
                  <Grid item xs={2}>Incasso #{curr_incasso.id}</Grid>
                  <Grid item xs={2}>{curr_incasso.text}</Grid>
                  <Grid item xs={2} align="right">
                  <NumberFormat 
                                value={curr_incasso.importo} 
                                displayType={'text'}
                                fixedDecimalScale={2} 
                                decimalScale={2} 
                                suffix={' EUR'}
                                />
                  </Grid>
                  <Grid item xs={3} align="right">
                  <Button  variant="contained" 
                    onClick={() => { this.cancellaIncasso(curr_incasso.id) }}
                    color="default">
                        Cancella <DeleteIcon/>
                  </Button>
                  </Grid>
                  <Grid item xs={3} align="right">
                  <Button  variant="contained" onClick={() => {window.location.href="/gui/edit_incasso/" + curr_incasso.id}} color="secondary">
                        Modifica <EditIcon/>
                  </Button>
                  </Grid>
                </Grid>
            </Grid>  
            <Grid item xs={12}>
              <PreviewPdf url_pdf={this.urlPdf()}/> 
            </Grid>  
          </Grid></Paper>}          
        
      </Container>
    )
  }
}
export default withSnackbar(withConfirm(ListaIncassi))
