import React, { Fragment, useState } from 'react'
import { 
    Button, 
 } from '@material-ui/core/'
import Fab from '@material-ui/core/Fab' 
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import { SingleSelect } from "react-select-material-ui"
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import history from '../components/history'
import Container from '@material-ui/core/Container'

import { Add, Edit } from '@material-ui/icons'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers"
import "moment/locale/it"
import api from "../api_calls/api"
import { withSnackbar } from 'notistack'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'



class DialogDataFirma extends React.Component {
    state = {
        open: false,
        validate: false,
        pdc: undefined
    }

    

    constructor(props) {
        super(props)
        if(props.pdc !== null && props.pdc !== undefined) {
            this.state.pdc = JSON.parse(JSON.stringify(props.pdc))
            if (!this.state.pdc.data_firma_paziente) {
                const moment = new MomentUtils();
                this.state.pdc.data_firma_paziente = moment.moment(new Date()).format('YYYY-MM-DD')
            }
        } else {
            ;//alert("VUOTO!!!!")
        }
      }

      componentDidMount () {
        
      }

      componentDidUpdate (old) {
        
      }

      

  

    
  
  salvaPdc(pdc) {
      const url_pdc = `${process.env.REACT_APP_API_URL}/dc/piano_terapeutico/${pdc.id}/`
      let method = 'PUT'
      api.call_post(
        url_pdc, 
        pdc, (res) => {
            this.props.enqueueSnackbar(`Data di firma impostata.`, {variant:  'success', autoHideDuration: 4000})
            this.props.setDf(pdc)
        },
        (err) => {
          try {
            let msg = err.response.data.split("\n")[1];
            this.props.enqueueSnackbar(`Errore salvataggio dati: ${msg}`, {variant:  'error', autoHideDuration: 8000})
          } catch(e) {
            this.props.enqueueSnackbar(`Errore salvataggio dati: ${e}`, {variant:  'error', autoHideDuration: 8000})
          }
        },
        method)
    
    }


  handleChangeDate(date) {
    try {
          this.setState({
            pdc: {
                ...this.state.pdc,
                'data_firma_paziente': date.format('YYYY-MM-DD')
            }
        })
    } catch(e) {
      ;
    }
  }

  render() {
  console.log("PROPS: " + JSON.stringify(this.props))  
  const { 
    open, 
    validate, 
    pdc
  } = this.state

  return (
    <Dialog open={this.props.open}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'it'}>
              <DialogTitle id="form-dialog-title">Data di firma</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Inserisci la data di firma.
                </DialogContentText>
                <KeyboardDatePicker 
                      name="data_firma_paziente"
                      label="Data"
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      value={this}
                      onChange={(date) => {this.handleChangeDate(date)}}
                      style={{ width: "90%" }}
                    />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.props.toggleOpen} color="secondary">
                  Chiudi
                </Button>
                <Button onClick={() => { this.salvaPdc(this.state.pdc) }} color="primary">
                  Conferma
                </Button>
              </DialogActions>
            
      </MuiPickersUtilsProvider>  
    </Dialog>
  )}
}

export default withSnackbar(DialogDataFirma)
