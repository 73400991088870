import MomentUtils from '@date-io/moment';
import React from 'react'
import api from "../api_calls/api";
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import { DatePicker, KeyboardTimePicker } from "@material-ui/pickers"
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { SingleSelect } from "react-select-material-ui"
import Switch from '@material-ui/core/Switch';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Tooltip from "@material-ui/core/Tooltip";
import { withSnackbar } from 'notistack';
import withConfirm from "material-ui-confirm";
import { withStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import DialogConsenso from "../dialogs/DialogConsenso";

function numberWithThousandsSep(x) {
  if (x===undefined || Number.isNaN(x)) return "";
  return x.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  // https://stackoverflow.com/a/4187164/1029569  tonca  a 2 decimali
}

function event_descr(evt, testoOggettoTask, testoMotivoScelto, testoMotivoMancataEsecuzioneScelto, testoTipo) {
  let separatore = "";
  let label_text = "";

  if (evt.oggetto_task !== undefined && evt.oggetto_task !== 'XX') {
    label_text += " " + testoOggettoTask + "- "
  }
  if (evt.motivo_annullamento !== 'XX') {
    label_text += "ANNULLATO (" + testoMotivoScelto + ") - "
  }
  if (evt.motivo_mancata_esecuzione !== 'XX') {
    label_text += "NON ESEGUITO (" + testoMotivoMancataEsecuzioneScelto + ") - "
  }
  if (evt.nome_paziente !== "" && evt.nome_paziente !== undefined) {
    label_text += evt.nome_paziente;
    separatore = " - ";
  }
  if (evt.nome_medico !== "" && evt.nome_medico !== undefined) {
    label_text += separatore + evt.nome_medico;
    separatore = " - ";
  }
  if (evt.tipo !== 'XX') {
    label_text += " --- " + testoTipo
  } else {
    if (label_text !== "") {
      label_text += " --- ";
    }
  }
  return label_text;
}



class DatiAppuntamento extends React.Component {

  state = {
    evt: undefined,
    options_pazienti: undefined,
    da_eseguire: [],
    eseguiti: [],
    eseguiti_associati: [],
    da_eseguire_associati: [],
    medici: [],
    acconti: [],
    saldi_paziente: undefined,
    descrizione_acconti: undefined,
    acconti_altri_app: undefined,
    acconti_tutti_app: undefined,
    displayOggettoChange: true,
    currSet: undefined,
    openCDialog: false,
    tipiAppuntamento: [{"value": "XX", "label": "----"}]
  };

  oggettoTask = [
                      {"value": "XX", "label": "----"},
                      {"value": "FA", "label": "Fissare appuntamento"},
                      {"value": "CA", "label": "Chiedere acconto"},
                      {"value": "CO", "label": "Colloquio"},
                      {"value": "PA", "label": "Passa per acconto"},
                      {"value": "ND", "label": "Medico non disponibile"}
  ];
  motiviAnnullamento = [
                      {"value": "XX", "label": "----"},
                      {"value": "NO", "label": "Non si presenta"},
                      {"value": "NP", "label": "Non paga"},
                      {"value": "CO", "label": "Eseguito da concorrenza"},
                      {"value": "RI", "label": "Impegnato/a richiamerà"},
                      {"value": "MA", "label": "Mancanza componenti"},
                      {"value": "NE", "label": "Nega di aver appuntamento"},
                      {"value": "EC", "label": "Rinuncia per motivi economici"},
                      {"value": "SP", "label": "Spostato su altra sede"}
  ];
  motiviMancataEsecuzione = [
                      {"value": "XX", "label": "----"},
                      {"value": "NC", "label": "Ci siamo dimenticati di fare arrivare i componenti in tempo utile"},
                      {"value": "NA", "label": "Ci siamo dimenticati di fare arrivare l’apparecchiatura in tempo utile"},
                      {"value": "NM", "label": "Ci siamo dimenticati di fare arrivare il manufatto di laboratorio in tempo utile"},
                      {"value": "NA", "label": "Ci siamo dimenticati di prescrivere l’antibiotico al paziente"},
                      {"value": "PA", "label": "Il paziente non ha preso l’antibiotico prescritto"},
                      {"value": "PS", "label": "Il paziente non ha portato i soldi"},
                      {"value": "LR", "label": "Il laboratorio non ha portato il manufatto protesico ordinato in tempo utile"},
                      {"value": "LS", "label": "Il laboratorio ha sbagliato a fare il manufatto protesico"}
  ];
  tipiAppuntamentoOrig = [
                      {"value": "XX", "label": "----"},
                      {"value": "PV", "label": "Prima visita"},
                      {"value": "VS", "label": "Visita specialistica successiva alla prima"},
                      {"value": "NC", "label": "Visita su paziente già nostro per nuove cure"},
                      {"value": "ID", "label": "Igiene Dentale (quando non compresa in un piano di trattamento accettato)"},
//                      {"value": "TA", "label": "TAC/OPT di controllo"},
                      {"value": "PR", "label": "Rivalutazione del preventivo"},
                      {"value": "RG", "label": "Rivalutazione gnatologica"},
                      {"value": "RI", "label": "Seduta aggiuntiva per terapia protesica (oltre quelle già previste dal piano di trattamento)"},
                      {"value": "TC", "label": "Seduta aggiuntiva per terapia canalare o conservativa (oltre quelle già previste dal piano di trattamento)"},
                      {"value": "TI", "label": "Seduta aggiuntiva per terapia implantare (oltre quelle già previste dal piano di trattamento)"},
                      {"value": "GA", "label": "Esecuzione prestazioni in garanzia"},
                      {"value": "TO", "label": "Terapia ortodontica"}
];



  constructor(props) {
    super(props)
    let evt = props.evt
    let medici = props.medici
    this.initDateAndTimes(evt)
    // devo garantire che evt.database_id sia sempre valorizzato; 0 se nuovo evento
    if (evt.database_id === undefined) evt.database_id = 0;
    if (evt.tipo === undefined) evt.tipo = 'XX';
    if (evt.motivo_annullamento === undefined) evt.motivo_annullamento = 'XX';
    if (evt.motivo_mancata_esecuzione === undefined) evt.motivo_mancata_esecuzione = 'XX';
    if (evt.oggetto_task === undefined) evt.oggetto_task = 'XX';
    // di default è un appuntamento e non un task
    if (evt.oggetto === undefined) evt.oggetto = 'appuntamento'
    this.state.evt = evt
    this.state.medici = medici.map((row, index) => {
            return (
              {
                  value: row.key,
                  label: row.label
              }
            )
          })
  }

  inizializzaTipiAppuntamento() {
    const url_tipi = `${process.env.REACT_APP_API_URL}/dc/tipi_appuntamento`;
    api.call_get(url_tipi, (result) => {
      let tipi = result.results;
      console.log(JSON.stringify(tipi));
      this.setState({tipiAppuntamento: tipi});
    });
  }

  inizializzaEseguiti(cartella_clinica) {
    let evt = this.state.evt;
    const moment = new MomentUtils();

    if (cartella_clinica) {
      const url_eseguiti = `${process.env.REACT_APP_API_URL}/dc/cc_eseguiti?cc_id=` + cartella_clinica + `&database_id=` + evt.database_id
      api.call_get(url_eseguiti, (result) => {
              const eseguiti = result.results;
              const eseguiti_associati = eseguiti.filter(el => el.appuntamento_id === evt.database_id);
              this.setState({
                eseguiti: eseguiti,
                eseguiti_associati: eseguiti_associati
              }, () => this.aggiornaDescrizione() );
      });
      const url_da_eseguire = `${process.env.REACT_APP_API_URL}/dc/cc_da_eseguire?cc_id=` + cartella_clinica + `&database_id=` + evt.database_id
      api.call_get(url_da_eseguire, (result) => {
            const da_eseguire = result.results;
            const da_eseguire_associati = da_eseguire.filter(el => el.appuntamento_id === evt.database_id);
            this.setState({
              da_eseguire: da_eseguire,
              da_eseguire_associati: da_eseguire_associati
            }, () => this.aggiornaDescrizione() );
      });
      const url_acconti_paziente = `${process.env.REACT_APP_API_URL}/dc/acconti_paziente?cc_id=` + cartella_clinica + `&database_id=` + evt.database_id + `&start_date=` + moment.parse(evt.start_date).format('DD/MM/YYYY')
      api.call_get(url_acconti_paziente, (result) => {
            const acconti = result.results.fatture;
            const saldi_paziente = result.results.saldi_paziente;
            const descrizione_acconti = result.results.descrizione_acconti;
            const acconti_altri_app = result.results.acconti_altri_app;
            const acconti_tutti_app = result.results.acconti_tutti_app;
            this.setState({
              acconti: acconti,
              saldi_paziente: saldi_paziente,
              descrizione_acconti: descrizione_acconti,
              acconti_altri_app: acconti_altri_app,
              acconti_tutti_app: acconti_tutti_app
            }, () => this.aggiornaDescrizione() );
      });
    } else {
      this.aggiornaDescrizione();
    }
  }

  componentDidMount() {
    let evt = this.state.evt
    this.inizializzaTipiAppuntamento();
    this.inizializzaEseguiti(evt.cartella_clinica);
    if (evt.medico === undefined || evt.medico === "") {
      this.verificaDisponibilita()
    }
  }

  aggiornaDescrizione() {
    let evt = this.state.evt;
    let label_text = "";
    let separatore = "";
    const motivoScelto = this.motiviAnnullamento.filter(el => el.value === evt.motivo_annullamento);
    const motivoMancataEsecuzioneScelto = this.motiviMancataEsecuzione.filter(el => el.value === evt.motivo_mancata_esecuzione);
    const oggettoTask = this.oggettoTask.filter(el => el.value === evt.oggetto_task);
    // se ho almeno un evento associato non posso indicare il tipo di appuntamento
    if (this.state.da_eseguire_associati.length > 0 || this.state.eseguiti_associati.length > 0) evt.tipo = "XX";

    const tipoAppuntamento = this.state.tipiAppuntamento.filter(el => el.value === evt.tipo);
    const testoMotivoScelto = ((motivoScelto.length > 0 && motivoScelto[0].value !== "XX") ? motivoScelto[0].label : "");
    const testoMotivoMancataEsecuzioneScelto = ((motivoMancataEsecuzioneScelto.length > 0 && motivoMancataEsecuzioneScelto[0].value !== "XX") ? motivoMancataEsecuzioneScelto[0].label : "");
    const testoOggettoTask = ((oggettoTask.length > 0 && oggettoTask[0].value !== "XX") ? oggettoTask[0].label : "");
    const testoTipo = ((tipoAppuntamento.length > 0 && tipoAppuntamento[0].value !== "XX") ? tipoAppuntamento[0].label : "");

    if (evt.oggetto_task !== undefined && evt.oggetto_task !== 'XX') {
      label_text += " " + testoOggettoTask + "- "
    }
    if (evt.motivo_annullamento !== 'XX') {
      label_text += "ANNULLATO (" + testoMotivoScelto + ") - "
    }
    if ((evt.nome_medico === "" || evt.nome_medico === undefined) && evt.medico !== "") {
      this.setNomeMedico(evt.medico)
    }
    label_text = event_descr(evt, testoOggettoTask, testoMotivoScelto, testoMotivoMancataEsecuzioneScelto, testoTipo)

    let da_eseguire_associati = this.state.da_eseguire_associati.map((item, index) => {
      return (item.str_molto_breve)
    });
    let eseguiti_associati = this.state.eseguiti_associati.map((item, index) => {
      return (item.str_molto_breve)
    });
    let tutti_gli_associati = this.state.da_eseguire_associati.concat(this.state.eseguiti_associati);
    let associati = da_eseguire_associati.concat(eseguiti_associati);
    label_text += associati.join(', ');
    let id_associati = [];
    for (let k = 0; k < tutti_gli_associati.length; ++k) {
      id_associati.push(tutti_gli_associati[k].id);
    }
    evt.text = label_text;

    evt.fondoDisponibile = "";
    evt.anticipiRichiesti = [];
    evt.totAnticipiRichiesti = 0;
    evt.totAnticipiMancanti = 0;
    evt.saldiRichiesti = [];
    evt.totSaldiRichiesti = 0;
    evt.statoDisponibilita = "";

    if (this.state.saldi_paziente !== undefined) {
      let acconti = this.state.acconti;
      for (let j = 0; j < acconti.length; ++j) {
        acconti[j].importo_usato_in_questo_appuntamento = 0;
      }
      evt.fondoDisponibile = this.state.saldi_paziente["fondo_disponibile"] - this.state.acconti_altri_app;
      // TODO CHECK SU TUTTI GLI ASSOCIATI O SOLO SU QUELLI NON ESEGUITI?
      for (let k = 0; k < tutti_gli_associati.length; ++k) {
        if (!tutti_gli_associati[k].saldato) {
          if (tutti_gli_associati[k].prezzo_per_fattura > 0) {
            evt.saldiRichiesti.push(tutti_gli_associati[k].str_breve + ": €" + tutti_gli_associati[k].prezzo_per_fattura);
            evt.totSaldiRichiesti += parseFloat(tutti_gli_associati[k].prezzo_per_fattura);
          }
          if (tutti_gli_associati[k].anticipo_per_fattura > 0) {
            let contributi = [];
            tutti_gli_associati[k].importo_da_coprire = tutti_gli_associati[k].anticipo_per_fattura;
            console.log("anticipo_per_fattura/importo_da_coprire=€" + JSON.stringify(tutti_gli_associati[k].anticipo_per_fattura))
            for (let j = 0; j < acconti.length; ++j) {
//              if (acconti[j].pdc.includes(tutti_gli_associati[k].id_pdc)) { // PRIMA USAVO SOLO GLI ACCONTI CON IL PDC APPLICABILE; MAGDALO DICE TUTTI GLI ACCONTI
              let residuoUtilizzabile = parseFloat(acconti[j].importo_residuo) - acconti[j].importo_usato_in_questo_appuntamento;
              console.log("residuoUtilizzabile " + JSON.stringify(residuoUtilizzabile))
              if (residuoUtilizzabile > 0) {
                if (parseFloat(tutti_gli_associati[k].importo_da_coprire) > residuoUtilizzabile) { // MANCA LA COPERTURA
                  console.log("MANCA LA COPERTURA ")
                  tutti_gli_associati[k].importo_da_coprire = tutti_gli_associati[k].importo_da_coprire - residuoUtilizzabile;
                  acconti[j].importo_usato_in_questo_appuntamento += residuoUtilizzabile;
                  contributi.push(acconti[j].n + " €" + residuoUtilizzabile.toString());
                } else { // QUESTA FATTURA COPRE LE NECESSITA DI QUESTO SET
                  console.log("C'E' COPERTURA ")
                  acconti[j].importo_usato_in_questo_appuntamento += parseFloat(tutti_gli_associati[k].importo_da_coprire);
                  contributi.push(acconti[j].n + " €" + tutti_gli_associati[k].importo_da_coprire.toString());
                  tutti_gli_associati[k].importo_da_coprire = 0;
                  break; // QUESTA FATTURA COPRE LE NECESSITA DI QUESTO SET: ESCO DAL LOOP FATTURE DI ACCONTO
                }
              }
//              }
            }
//            console.log("anticipo_per_fattura=€" + JSON.stringify(tutti_gli_associati[k].anticipo_per_fattura));
            if (tutti_gli_associati[k].importo_da_coprire > 0) { // ACCONTI NON SUFFICIENTI A COPRIRE
              evt.statoDisponibilita += "(" + tutti_gli_associati[k].str_breve + ": €" + tutti_gli_associati[k].importo_da_coprire + ") ";
              evt.totAnticipiMancanti += parseFloat(tutti_gli_associati[k].importo_da_coprire);
//              console.log("importo_da_coprire=€" + JSON.stringify(tutti_gli_associati[k].anticipo_per_fattura));
//              console.log("evt.totAnticipiMancanti=€" + JSON.stringify(evt.totAnticipiMancanti));
            }
            console.log("evt.statoDisponibilita " + evt.statoDisponibilita)
            evt.anticipiRichiesti.push(tutti_gli_associati[k].str_breve + ": €" + tutti_gli_associati[k].anticipo_per_fattura + " (" + contributi.join(', ') + ")");
            evt.totAnticipiRichiesti += parseFloat(tutti_gli_associati[k].anticipo_per_fattura);
          }
        }
      }
      this.setState({
        acconti: acconti
      })
    }
    if (evt.anticipiRichiesti.length > 0) {
      evt.anticipiRichiesti = evt.anticipiRichiesti.join(" - ")
    } else {
      evt.anticipiRichiesti = "";
    }
    if (evt.saldiRichiesti.length > 0) {
      evt.saldiRichiesti = "ANTICIPI: " + evt.anticipiRichiesti + " SALDI: " + evt.saldiRichiesti.join(" - ")
    } else {
      evt.saldiRichiesti = "";
    }


    if (id_associati.length > 0) {
      let id_associati_par = id_associati.join('_');
      const url_da_eseguire = `${process.env.REACT_APP_API_URL}/dc/get_descr_app?id_associati=${id_associati_par}`

      api.call_get(url_da_eseguire, (result) => {
        if (result.status === "success") {
          this.props.enqueueSnackbar(result.results["disponibilita"], {
            variant: 'success',
            autoHideDuration: 10000
          });
          let evt = this.state.evt;
          if (evt) {
            let descrizione = result.results["descrizione"];
            let posizione = evt.text.search(" --- ");
            if (posizione > 0) {
              evt.text = evt.text.substring(0, posizione) + " --- " + result.results["descrizione"];
            } else {
              evt.text += result.results["descrizione"];
            }
            this.setState({evt: evt},);
          }
        }
      });
    }

    this.setState({
      evt: evt,
    })
  }

  toggleAssociati(item) {
    const associato = (item.appuntamento_id===this.state.evt.database_id);
    const url_da_eseguire = `${process.env.REACT_APP_API_URL}/dc/associa_set?set_id=`+item.id+`&appuntamento_id=`+this.state.evt.database_id+`&associato=`+associato;
    api.call_get(url_da_eseguire, (result) => {
      //controllo errori:
      if (result.esito==="ERROR") {
        this.props.enqueueSnackbar(result.message, {variant:  'error', autoHideDuration: 5000})
      } else {
        let evt = this.state.evt;
        evt.color = result.results.app_color;
        this.setState({
          evt: evt
        });
        this.inizializzaEseguiti(this.state.evt.cartella_clinica);
        if (associato) { // era associato, dopo l'invocazione dell'API non lo è più
          this.props.enqueueSnackbar("Trattamento non più associato", {variant:  'success', autoHideDuration: 5000})
        } else { // e viceversa
          this.props.enqueueSnackbar("Trattamento associato", {variant:  'success', autoHideDuration: 5000})
        }
        if (result.message !== "") {
          this.props.enqueueSnackbar(result.message, {variant:  'warning', autoHideDuration: 10000})
        }
      }
    });
  }

  toggleEseguitiAssociati(item) {
    if (this.state.evt.database_id > 0) {
      this.toggleAssociati(item)
    } else { // nuovo appuntamento, modifico solo la UI; poi ci penserà il salvataggio lato server
      let {eseguiti_associati} = this.state
      let indice = eseguiti_associati.indexOf(item)
      if (indice >= 0) {
        eseguiti_associati.splice(indice, 1)
      } else {
        eseguiti_associati.push(item)
      }
      this.setState({
        eseguiti_associati: eseguiti_associati,
        displayOggettoChange: false
      }, () => this.aggiornaDescrizione() );
    }
  }

  toggleDaEseguireAssociati(item) {
    if (this.state.evt.database_id > 0) {
      this.toggleAssociati(item)
    } else { // nuovo appuntamento, modifico solo la UI; poi ci penserà il salvataggio lato server
      let {da_eseguire_associati} = this.state
      let indice = da_eseguire_associati.indexOf(item)
      if (indice >= 0) {
        da_eseguire_associati.splice(indice, 1)
      } else {
        da_eseguire_associati.push(item)
      }
      this.setState({
        da_eseguire_associati: da_eseguire_associati,
        displayOggettoChange: false
      }, () => this.aggiornaDescrizione() )
    }

  }

  toggleStatoEsecuzione(item) {
    let stato = ((item.stato === "Eseguito") ? 0 : 1);
    if(stato === 1 && !item.has_consenso) {
        this.setState({
          openCDialog: true,
          currSet: item
        });
        return;
    }

    const url_da_eseguire = `${process.env.REACT_APP_API_URL}/dc/set_set?set_id=` + item.id + `&stato=` + stato
    api.call_get(url_da_eseguire, (result) => {
      if (result.status==="success") {
        let evt = this.state.evt
        if (item.stato === "Eseguito") {
          this.props.enqueueSnackbar("Trattamento non eseguito", {
            variant: 'success',
            autoHideDuration: 5000
          })
        } else {
          this.props.enqueueSnackbar("Trattamento eseguito", {
            variant: 'success',
            autoHideDuration: 5000
          })
        }
        this.inizializzaEseguiti(evt.cartella_clinica);
      } else {
          this.props.enqueueSnackbar(result.detail, {
            variant: 'error',
            autoHideDuration: 10000
          })
      }
    });
  }

  verificaDisponibilita() {
    let evt = this.state.evt;

    if (evt.oggetto === 'appuntamento') {
      console.log(typeof (evt.date));
      console.log(toString(evt.date));
      let par_date = (typeof (evt.date) === 'string' ? evt.date : evt.date)
      const url_da_eseguire = `${process.env.REACT_APP_API_URL}/dc/get_disponibilita?medico_id=${evt.medico}&paziente_id=${evt.paziente_id}&tipo=${evt.tipo}&data=${par_date}`

      api.call_get(url_da_eseguire, (result) => {
        if (result.status === "success") {
          this.props.enqueueSnackbar(result.results["disponibilita"], {
            variant: 'success',
            autoHideDuration: 10000
          });
          let evt = this.state.evt;
          if (evt) {
            evt.abilitato_1a_visita_30_80 = result.results["abilitato_1a_visita_30_80"];
            evt.competenze_medico = result.results["competenze_medico"];
            this.setState({evt: evt}, this.checkCompetenze);
          }
        } else {
          this.props.enqueueSnackbar(result.detail, {
            variant: 'error',
            autoHideDuration: 10000
          })
        }
      });
    }
  }

  initDateAndTimes(evt) {
    const moment = new MomentUtils()
    let baseDate = moment.parse(evt.start_date)
    const endDate = moment.parse(evt.end_date)
    const st = baseDate
    const et = endDate
    evt.date = moment.moment(baseDate).format("YYYY-MM-DD")
    evt.start_time = st
    evt.end_time = et
  };

  componentDidUpdate (old) {
    if (old.evt !== this.props.evt) {
      let evt = this.props.evt
      // devo garantire che evt.database_idpk sia sempre valorizzato; 0 se nuovo evento
      if (evt.database_id === undefined) evt.database_id = 0;
      this.initDateAndTimes(evt)
      this.setState({evt: evt})
    }
  }

  handleChange = (name) => ({target: { value } }) => {
    this.setState({
      evt: {
        ...this.state.evt,
        [name]: value
      }
    })
  };

  handleChangeDate(date, name) {
    // console.log(date); // Moment {_isAMomentObject: true, _i: "2019-09-17", _f: "YYYY-MM-DD", _isUTC: false, _pf: {…}, …}
    this.setState({
      evt: {
        ...this.state.evt,
        [name]: date.format('YYYY-MM-DD') //.format('YYYY-MM-DDTHH:mm:00.000Z')
      }
    }, () => this.verificaDisponibilita() )
  }

  handleChangeTime(date, name) {
    this.setState({
      evt: {
        ...this.state.evt,
        [name]: date
      }
    })
  }

  salvaEvt(evt, nuovoStato) {
    const sd = new Date(evt.date)
    const ed = new Date(evt.date)
    sd.setHours(new Date(evt.start_time).getHours())
    sd.setMinutes(new Date(evt.start_time).getMinutes())
    ed.setHours(new Date(evt.end_time).getHours())
    ed.setMinutes(new Date(evt.end_time).getMinutes())
    evt.start_date = sd;
    evt.end_date = ed;
    evt.json_attivita_appuntamento = this.state.eseguiti_associati.concat(this.state.da_eseguire_associati).map((row, index) => {
        return ( row.id )
      });
    evt.json_attivita_appuntamento = JSON.stringify(evt.json_attivita_appuntamento);
    delete evt.date;
    delete evt.start_time;
    delete evt.end_time;
    // E' NUOVO ??  (EITHER TASK OR APPUNTAMENTO)
    if (evt.database_id === 0)
    {
      evt.dhtmlx_id = evt.id;
      delete evt.id;
      // elimino id altrimenti dhtmlx lo spedisce al backend ma questo è l'id interno
    } else {
      evt["!nativeeditor_status"] = ""
      // altrimenti scheduler lo lascia ad inserted e invoca REST senza id e quindi crea un nuovo appuntamento anche se ha il database_id
      // senza questa riga se crei un appuntamento e subito lo modifichi lui ne ricrea uno nuovo
    }
    if (nuovoStato !== '')
      evt.stato = nuovoStato;

    const testo_libero =   document.getElementById("testo_libero").value;
    evt.testo_libero = testo_libero;

    // uso la sede corrente sia per il task che per l'appuntamento perché non vogliono inserire lo studio
    evt.sede = this.props.sede_corrente;
    if (evt.color === null || evt.color === undefined) evt.color = "";
    this.props.salvaEvt(evt);
  }

  handlePaziente = (paziente) => {
    let evt = this.state.evt;
    evt.nome_paziente = paziente.text;
    evt.cartella_clinica = paziente.cartella_clinica;
    evt.paziente_id = paziente.id;
    evt.paziente_email = paziente.email;
    /* D.P. tolgo questo controllo perché non tiene conto dei pazienti collegati
    if (paziente.email === '') {
      if (paziente.genere === 'f') {
        this.props.enqueueSnackbar("La paziente scelta non ha l'email e non si potrà salvare l'appuntamento.", {
          variant: 'warning',
          autoHideDuration: 5000
        });
      } else {
        this.props.enqueueSnackbar("Il paziente scelto non ha l'email e non si potrà salvare l'appuntamento.", {
          variant: 'warning',
          autoHideDuration: 5000
        });
      }
    }*/
    this.setState({
        evt: evt,
        displayOggettoChange: false
      }, () => this.aggiornaDescrizione());
    this.inizializzaEseguiti(paziente.cartella_clinica);
  }

  setNomeMedico = (medico) => {
    let evt = this.state.evt;
    const {medici} = this.state;
    let questoMedico = undefined;
    questoMedico = medici.filter(el => el.value === medico);
    evt.nome_medico = (questoMedico.length > 0 ? questoMedico[0].label : "");
    this.setState({
      evt: evt
    });
  };

  setMedico = (medico) => {
    this.setNomeMedico(medico);
    let evt = this.state.evt;
    if (evt.oggetto === 'appuntamento') {
      evt.medico = medico;
    } else {
      evt.medico = medico;
      evt.medico_coinvolto = medico;
    }
    this.setState({
      evt: evt,
      displayOggettoChange: false
    }, () => {this.aggiornaDescrizione(); this.verificaDisponibilita();})
  };

  setMotivoAnnullamento = (motivo) => {
    let evt = this.state.evt;
    evt.motivo_annullamento = motivo;
    if (evt.motivo_annullamento !== 'XX') {
      evt.motivo_mancata_esecuzione = 'XX';
      evt.stato = 'Annullato';
    } else {
      evt.stato = this.props.evt_prima_delle_modifiche.stato;
    }
    this.setState({
      evt: evt,
      displayOggettoChange: false
    }, () => this.aggiornaDescrizione());
  };

  setMotivoMancataEsecuzione = (motivo) => {
    let evt = this.state.evt;
    evt.motivo_mancata_esecuzione = motivo;
    if (evt.motivo_mancata_esecuzione !== 'XX') {
      evt.motivo_annullamento = 'XX';
      evt.stato = 'Mancata esecuzione';
    } else {
      evt.stato = this.props.evt_prima_delle_modifiche.stato;
    }
    this.setState({
      evt: evt,
      displayOggettoChange: false
    }, () => this.aggiornaDescrizione());
  };

  setOggettoTask = (oggetto) => {
    let evt = this.state.evt;
    evt.oggetto_task = oggetto;
    this.setState({
      evt: evt,
      displayOggettoChange: false
    }, () => this.aggiornaDescrizione());
  };

  setTestoLiberoTask = (testo_libero) => {
    let evt = this.state.evt;
    evt.testo_libero = testo_libero;
    this.setState({
      evt: evt,
      displayOggettoChange: false
    }, () => this.aggiornaDescrizione());
  };

  checkCompetenze = () => {
    let evt = this.state.evt;
    // igiene_sbiancamento=ID e ortodonzia=TO
    if (evt && evt.medico && (evt.tipo==='ID' || evt.tipo==='TO')) {
      if (evt.tipo === 'ID') {
        if (!evt.competenze_medico.includes("igiene_sbiancamento")) {
          this.props.enqueueSnackbar("ATTENZIONE! " + evt.nome_medico + " non è abilitato per Igiene dentale e sbiancamento.", {
            variant: 'error',
            autoHideDuration: 10000
          })
        }
      } else {
        if (!evt.competenze_medico.includes("ortodonzia")) {
          this.props.enqueueSnackbar("ATTENZIONE! " + evt.nome_medico + " non è abilitato per Ortodonzia.", {
            variant: 'error',
            autoHideDuration: 10000
          })
        }
      }
    }
  };

  setTipoAppuntamento = (tipo) => {
    let evt = this.state.evt;
    const isAgendaChiusa = tipo === 'AC';
    //D.P. importante: cartella_clinica = 0 è necessario per associare la cartella clinica
    //dedicata all'agenda chiusa 
    const emptyPatient = {id: 0, text: "AGENDA CHIUSA", cartella_clinica: 0, email: ""};
    evt.tipo = tipo;
    if (isAgendaChiusa) {
      evt.testo_libero = "";
    }
    
    console.log('setTipoAppuntamento.evt: ' + toString(evt));
    console.log('setTipoAppuntamento.tipo: ' + toString(tipo));
    if (evt && evt.medico && evt.tipo==='PV' && !evt.abilitato_1a_visita_30_80) {
      this.props.enqueueSnackbar("ATTENZIONE! Il medico non è abilitato ad effettuare una prima visita a pazienti " +
        "tra 30 e 80 anni.", {
        variant: 'error',
        autoHideDuration: 10000
      })
    }
    
    this.setState({
      evt: evt,
      displayOggettoChange: true
    }, () => {
      this.aggiornaDescrizione()
      this.checkCompetenze()
      if(isAgendaChiusa) this.handlePaziente(emptyPatient);
    });
  };

  handleTipoOggettoChange = (event) => {
    let evt = this.state.evt;
    evt.oggetto = event.target.value;
    this.setState({
      evt: evt,
      displayOggettoChange: false
    }, () => this.aggiornaDescrizione());
  };

  toggleConsOpen = () => {
    this.setState({
      openCDialog: !this.state.openCDialog
    })
  };



  render() {
    const medici = this.state.medici.filter(el => el.value !== "0");
    const moment = new MomentUtils();
    const HtmlTooltip = withStyles(theme => ({
      tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
      },
    }))(Tooltip);
    let evt = this.state.evt;
    const momentStartDate = moment.parse(this.state.evt.start_date);
    const momentTra2Ore = moment.moment(new Date()).add(2,'hours');
    const options_pazienti = this.props.options_pazienti;
    return (
      <Container component="main" maxWidth="lg" minWidth="xs">
        <DialogConsenso set={this.state.currSet}  allsets={this.state.da_eseguire_associati} toggleOpen={() => {this.setState({altri: [], selectedFile: undefined}); this.toggleConsOpen()}} afterSave={() => {this.inizializzaEseguiti(this.state.evt.cartella_clinica)}} open={this.state.openCDialog} />
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'it'}>
          <Grid container spacing={3}>
            {(evt.database_id === 0) && this.state.displayOggettoChange &&
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup aria-label="position" name="position" value={evt.oggetto}
                            onChange={this.handleTipoOggettoChange} row>
                  <FormControlLabel
                    value="appuntamento"
                    control={<Radio color="primary"/>}
                    label="Appuntamento"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="task"
                    control={<Radio color="primary"/>}
                    label="Task"
                    labelPlacement="top"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            }
          </Grid>
          {(evt.oggetto === 'appuntamento') &&
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {evt.text}
              </Grid>
              {((evt.anticipiRichiesti !== "") || (evt.saldiRichiesti !== "")) &&
              <Grid item xs={12}>
                <Tooltip title="Corrispettivo previsto a copertura (somma di anticipi e saldi delle sedute associate)" arrow>
                  <Button>Pianificato per l'appuntamento: €{numberWithThousandsSep(evt.totAnticipiRichiesti + evt.totSaldiRichiesti)}</Button>
                </Tooltip>
              </Grid>
              }
              {false && (evt.anticipiRichiesti !== "") &&
              <Grid item xs={12}>
                <Tooltip title={evt.anticipiRichiesti} arrow>
                  <Button>Anticipi richiesti per l'appuntamento: €{numberWithThousandsSep(evt.totAnticipiRichiesti)}</Button>
                </Tooltip>
              </Grid>
              }
              {(evt.totAnticipiMancanti>0) &&
              <Grid item xs={12}>
                <Tooltip title={evt.statoDisponibilita} arrow>
                  <Button>Anticipi mancanti per l'appuntamento: €{numberWithThousandsSep(evt.totAnticipiMancanti)}</Button>
                </Tooltip>
              </Grid>
              }
              {(evt.fondoDisponibile > 0) &&
              <Grid item xs={12}>
                  <Tooltip title={this.state.descrizione_acconti} arrow>
                  <Button>Fondo disponibile: €{numberWithThousandsSep(evt.fondoDisponibile)}</Button>
                </Tooltip>
              </Grid>
              }
              {((evt.anticipiRichiesti !== "") || (evt.saldiRichiesti !== "")) && (evt.totAnticipiRichiesti + evt.totSaldiRichiesti - evt.fondoDisponibile > 0) &&
              <Grid item xs={12}>
                <Tooltip title={evt.saldiRichiesti} arrow>
                  <Button>Saldo minimo da corrispondere all'appuntamento: €{numberWithThousandsSep(evt.totAnticipiRichiesti + evt.totSaldiRichiesti - evt.fondoDisponibile)}</Button>
                </Tooltip>
              </Grid>
              }
              {(evt.paziente_id !== undefined) && (evt.tipo !== 'AC') && (evt.paziente_id !== 0) &&
              <Grid item xs={12}>
                <a href={`/gui/pazienti/?pid=${evt.paziente_id}`}>Modifica i dati di {evt.nome_paziente}</a>
              </Grid>
              }
              {(evt.database_id === 0) && (evt.tipo !== 'AC') &&
              <Grid item xs={12}>
                <FormControl style={{width: "100%"}}>
                  <SingleSelect
                    variant="filled"
                    color="inherit"
                    name={'paziente'} label={'Paziente'}
                    style={{width: "100%"}}
                    options={options_pazienti}
                    onInput={
                      this.props.handleInputPazienti
                    } onChange={(val) => {
                    this.handlePaziente(val)
                  }}
                    SelectProps={{
                      msgNoOptionsAvailable: "Digita parte del cognome del paziente",
                      msgNoOptionsMatchFilter: "Non ci sono pazienti che corrispondono alla ricerca"
                    }}
                  />
                </FormControl>
              </Grid>
              }
              <Grid item xs={12}>
                <FormControl style={{width: "100%"}}>
                  <SingleSelect
                    variant="filled"
                    color="inherit"
                    name={'medico'} label={'Medico'}
                    style={{width: "100%"}}
                    options={medici}
                    value={evt.medico + ""}
                    onChange={(val) => {
                      this.setMedico(val)
                    }}
                    SelectProps={{
                      msgNoOptionsAvailable: "Digita parte del cognome del paziente",
                      msgNoOptionsMatchFilter: "Non ci sono pazienti che corrispondono alla ricerca"
                    }}
                  />
                </FormControl>
              </Grid>
              {(this.state.da_eseguire_associati.length === 0 && this.state.eseguiti_associati.length === 0) &&
              <Grid item xs={12}>
                <FormControl style={{width: "100%"}}>
                  <SingleSelect
                    variant="filled"
                    color="inherit"
                    name={'tipo-appuntamento'} label={'Tipo appuntamento'}
                    style={{width: "100%"}}
                    options={this.state.tipiAppuntamento}
                    value={evt.tipo + ""}
                    onChange={(val) => {
                      this.setTipoAppuntamento(val)
                    }}
                  />
                </FormControl>
              </Grid>
              }
              <Grid item xs={4}>
                <FormControl>
                  <DatePicker
                    required
                    name="date"
                    autoOk={true}
                    openTo="date"
                    format="DD/MM/YYYY"
                    label="Giorno"
                    views={["date"]}
                    value={evt.date}
                    onChange={(date) => {
                      this.handleChangeDate(date, 'date')
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl>
                  <KeyboardTimePicker
                    name="start_time"
                    clearable
                    autoOk={true}
                    ampm={false}
                    label="ora inizio"
                    minutesStep={15}
                    format="HH:mm"
                    value={evt.start_time}
                    onChange={(date) => {
                      this.handleChangeTime(date, 'start_time')
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl>
                  <KeyboardTimePicker
                    name="end_time"
                    clearable
                    autoOk={true}
                    ampm={false}
                    format="HH:mm"
                    label="ora fine"
                    minutesStep={15}
                    value={evt.end_time}
                    min_value={evt.start_time}
                    onChange={(date) => {
                      this.handleChangeTime(date, 'end_time')
                    }}
                  />
                </FormControl>
              </Grid>
              {this.state.da_eseguire.length > 0 &&
              <Grid item xs={12}>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    Trattamenti da eseguire ({this.state.da_eseguire.length})
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={1}>
                      {this.state.da_eseguire.map((item, index) => {
                        return (
                          <>
                            <Grid item xs={9}>
                              <FormControl>
                                <HtmlTooltip title={
                                  <React.Fragment>{item.descrizione_ripetibilita}<br/>Altri
                                    appuntamenti: {item.altri_appuntamenti}<br/>Anticipo:
                                    €{numberWithThousandsSep(item.anticipo_per_fattura)}<br/>Saldo:
                                    €{numberWithThousandsSep(item.prezzo_per_fattura)}<br/>{item.importo_da_coprire > 0 && ("Importo non coperto da acconti: €" + numberWithThousandsSep(item.importo_da_coprire))}
                                  </React.Fragment>} TransitionComponent={Zoom}>
                                  <FormControlLabel
                                    control={<Checkbox value={item.id}
                                                       color="primary"
                                                       onClick={(value) => {
                                                         this.toggleDaEseguireAssociati(item)
                                                       }}/>}
                                    label={item.str_breve}
                                    checked={this.state.da_eseguire_associati.filter(el => el === item).length}
                                  />
                                </HtmlTooltip>
                              </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                              {item.stato_consenso == 'non attivo' && <span>&nbsp;</span>}
                              {item.stato_consenso  != 'non attivo' && !item.has_consenso && <div onClick={() => {this.toggleStatoEsecuzione(item);}} style={{'cursor': 'pointer','background-color': "#A60000", color: 'white', width: '80px', 'font-size': '10pt', 'text-align': 'center', padding: '2px'}}><small><b>CONSENSO<br/>{item.stato_consenso}</b></small></div>}
                              {item.stato_consenso  != 'non attivo' && item.has_consenso && item.stato_consenso === 'caricato' && <a download target='_blank' href={ process.env.REACT_APP_API_URL + '/dc/stampa_consenso_set/' + item.id + '.pdf'}><div style={{'cursor': 'pointer','background-color': "#008500", color: 'white', width: '80px', 'font-size': '10pt', 'text-align': 'center', padding: '2px'}}><small><b>CONSENSO<br/>{item.stato_consenso}</b></small></div></a>}
                              {item.stato_consenso  != 'non attivo' && item.has_consenso && item.stato_consenso !== 'caricato' && <a download target='_blank'><div style={{'cursor': 'pointer','background-color': "#FFA500", color: 'white', width: '80px', 'font-size': '10pt', 'text-align': 'center', padding: '2px'}}><small><b>CONSENSO<br/>{item.stato_consenso}</b></small></div></a>}
                            </Grid>
                            <Grid item xs={1}>
                              {this.state.evt.database_id > 0 && momentStartDate.isBefore(momentTra2Ore) && this.state.da_eseguire_associati.filter(el => el === item).length > 0 &&
                              <Switch
                                checked={item.stato === "Eseguito"}
                                onClick={(value) => {
                                  this.toggleStatoEsecuzione(item)
                                }}
                                value={item.id}
                              />
                              }
                            </Grid>
                          </>
                        )
                      })
                      }
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
              }
              {this.state.eseguiti.length > 0 &&
              <Grid item xs={12}>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    Trattamenti eseguiti ({this.state.eseguiti.length})
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container>
                      {this.state.eseguiti.map((item, index) => {
                        return (
                          <>
                            <Grid item xs={10}>
                              <FormControl>
                                <HtmlTooltip title={<React.Fragment> {item.altri_appuntamenti} </React.Fragment>} TransitionComponent={Zoom}>
                                <FormControlLabel
                                  control={<Checkbox value={item.id} color="primary"
                                                     onClick={(value) => {
                                                       this.toggleEseguitiAssociati(item)
                                                     }}/>}
                                  label={item.str_breve}
                                  checked={this.state.eseguiti_associati.filter(el => el === item).length}
                                />
                                </HtmlTooltip>
                              </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                              {this.state.evt.database_id > 0 && momentStartDate.isBefore(momentTra2Ore) && this.state.eseguiti_associati.filter(el => el === item).length > 0 &&
                              <Switch
                                checked={item.stato === "Eseguito"}
                                onClick={(value) => {
                                  this.toggleStatoEsecuzione(item)
                                }}
                                value={item.id}
                              />
                              }
                            </Grid>
                          </>
                        )
                      })
                      }
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
              }
              {(evt.database_id > 0) &&
              <Grid item xs={12}>
                <FormControl style={{width: "100%"}}>
                  <SingleSelect
                    variant="filled"
                    color="inherit"
                    name={'annulla-appuntamento'} label={'Seleziona motivo annullamento'}
                    style={{width: "100%"}}
                    options={this.motiviAnnullamento}
                    value={evt.motivo_annullamento + ""}
                    onChange={(val) => {
                      this.setMotivoAnnullamento(val)
                    }}
                  />
                </FormControl>
              </Grid>
              }
              {(evt.database_id > 0) &&
              <Grid item xs={12}>
                <FormControl style={{width: "100%"}}>
                  <SingleSelect
                    variant="filled"
                    color="inherit"
                    name={'mancata-esecuzione'} label={'Seleziona motivo mancata esecuzione'}
                    style={{width: "100%"}}
                    options={this.motiviMancataEsecuzione}
                    value={evt.motivo_mancata_esecuzione + ""}
                    onChange={(val) => {
                      this.setMotivoMancataEsecuzione(val)
                    }}
                  />
                </FormControl>
              </Grid>
              }
              {evt.tipo !== 'AC' &&
              <Grid item xs={12}>
                    <TextareaAutosize
                    id='testo_libero'
                    label='Dettagli'
                    value={evt.testo_libero}
                    rows={6}
                    style={{width: "98%"}}
                    placeholder="Note"
                    onChange={(evt) => {
                      this.setTestoLiberoTask(evt.target.value)
                    }}
                    />
              </Grid>}
              {evt.tipo === 'AC' && <input type="hidden" id="testo_libero" value="" />}
            </Grid>
            <Grid container spacing={3}>
              {(evt.motivo_annullamento !== 'XX' || evt.motivo_mancata_esecuzione !== 'XX' || evt.tipo !== 'XX' ||
                 this.state.eseguiti_associati.length>0 || this.state.da_eseguire_associati.length>0 ) && evt.medico &&
              evt.totAnticipiMancanti===0 &&
              <Grid item xs={2}>
                {/* se si vuole salvare l'evento qui */}
                <Button variant="contained" onClick={() => {
                  this.salvaEvt(evt, '')
                }} color="primary">
                  Salva
                </Button>
              </Grid>
              }
              {(evt.stato === 'Iniziale') &&
               ((evt.motivo_annullamento !== 'XX' && evt.motivo_mancata_esecuzione !== 'XX') || evt.tipo !== 'XX' ||
                 this.state.eseguiti_associati.length>0 || this.state.da_eseguire_associati.length>0 ) &&
              <Grid item xs={2}>
                <Button variant="contained" onClick={() => {
                  this.salvaEvt(evt, 'Confermato')
                }} color="primary">
                  Confermato
                </Button>
              </Grid>
              }
              {(evt.stato === 'Iniziale' || evt.stato === 'Confermato') &&
               ((evt.motivo_annullamento !== 'XX' && evt.motivo_mancata_esecuzione !== 'XX') || evt.tipo !== 'XX' ||
                 this.state.eseguiti_associati.length>0 || this.state.da_eseguire_associati.length>0 ) &&
              <Grid item xs={2}>
                <Button variant="contained" onClick={() => {
                  this.salvaEvt(evt, 'Effettuato')
                }} color="primary">
                  Eseguito
                </Button>
              </Grid>
              }
              {(this.state.eseguiti_associati.length===0 || this.state.da_eseguire_associati.length===0 ) && false &&
              <Grid item xs={2}>
                <Button variant="contained" onClick={() => {
                  this.eliminaEvt(evt)
                }} color="primary">
                  Elimina
                </Button>
              </Grid>
              }
              <Grid item xs={2}>
                <Button variant="contained" onClick={this.props.confirm(() => { this.props.activateScheduler(evt) }, {
                    title: 'Appuntamento',
                    description: "Chiudi senza salvare?",
                    confirmationText: 'Sì',
                    cancellationText: 'No'
                    })}
                   color="secondary">
                  Chiudi
                </Button>
              </Grid>
            </Grid>
          </>
          }
          {(evt.oggetto === 'task') &&
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {evt.text}
              </Grid>
              <Grid item xs={12}>
                <FormControl style={{width: "100%"}}>
                  <SingleSelect
                    variant="filled"
                    color="inherit"
                    name={'oggetto-task'} label={"Seleziona l'oggetto del task"}
                    style={{width: "100%"}}
                    options={this.oggettoTask}
                    value={evt.oggetto_task + ""}
                    onChange={(val) => {
                      this.setOggettoTask(val)
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl style={{width: "100%"}}>
                  <SingleSelect
                    variant="filled"
                    color="inherit"
                    name={'paziente'} label={'Paziente'}
                    style={{width: "100%"}}
                    options={options_pazienti}
                    value={evt.cartella_clinica + ""}
                    onInput={
                      this.props.handleInputPazienti
                    } onChange={(val) => {
                    this.handlePaziente(val)
                  }}
                    SelectProps={{
                      msgNoOptionsAvailable: "Digita parte del cognome del paziente",
                      msgNoOptionsMatchFilter: "Non ci sono pazienti che corrispondono alla ricerca"
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl style={{width: "100%"}}>
                  <SingleSelect
                    variant="filled"
                    color="inherit"
                    name={'medico'} label={'Medico'}
                    style={{width: "100%"}}
                    options={medici}
                    value={evt.medico_coinvolto + ""}
                    onChange={(val) => {
                      this.setMedico(val)
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                    <TextareaAutosize
                    id='testo_libero'
                    label='Dettagli'
                    value={evt.testo_libero}
                    rows={6}
                    style={{width: "98%"}}
                    placeholder="Seleziona i valori e solo se necessario aggiungi un testo libero"
                    onChange={(evt) => {
                      this.setTestoLiberoTask(evt.target.value)
                    }}
                    />
              </Grid>
              <Grid item xs={4}>
                <FormControl>
                  <DatePicker
                    required
                    name="date"
                    disablePast
                    autoOk={true}
                    openTo="date"
                    format="DD/MM/YYYY"
                    label="Giorno"
                    views={["date"]}
                    value={evt.date}
                    onChange={(date) => {
                      this.handleChangeDate(date, 'date')
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl>
                  <KeyboardTimePicker
                    name="start_time"
                    clearable
                    autoOk={true}
                    ampm={false}
                    label="ora inizio"
                    minutesStep={15}
                    format="HH:mm"
                    value={evt.start_time}
                    onChange={(date) => {
                      this.handleChangeTime(date, 'start_time')
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl>
                  <KeyboardTimePicker
                    name="end_time"
                    clearable
                    autoOk={true}
                    ampm={false}
                    format="HH:mm"
                    label="ora fine"
                    minutesStep={15}
                    value={evt.end_time}
                    min_value={evt.start_time}
                    onChange={(date) => {
                      this.handleChangeTime(date, 'end_time')
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Button variant="contained" onClick={() => {
                  this.salvaEvt(evt, '')
                }} color="primary">
                  Salva
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button variant="contained" onClick={this.props.confirm(() => { this.props.activateScheduler(evt) }, {
                    title: 'Task',
                    description: "Chiudi senza salvare?",
                    confirmationText: 'Sì',
                    cancellationText: 'No'
                    })}
                    color="secondary">
                  Chiudi
                </Button>
              </Grid>
            </Grid>
          </>
          }
        </MuiPickersUtilsProvider>
      </Container>
    )
  }
}
export default withSnackbar(withConfirm(DatiAppuntamento));
