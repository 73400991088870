const array_ma_non_posso = {
    join: (array, separator) => {
       let res = ""
       array.map((el, index) => {
            if(index >  0) {
                res += separator
            }
            res += el
       })
       return res 
    }
} 
export default array_ma_non_posso