import React, { Fragment, useState } from 'react'
import { 
    Button, 
 } from '@material-ui/core/'
import Fab from '@material-ui/core/Fab' 
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import { SingleSelect } from "react-select-material-ui"

import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import history from './history'
import Container from '@material-ui/core/Container'

import { Add, Edit } from '@material-ui/icons'
import "moment/locale/it"
import api from "../api_calls/api"
import { withSnackbar } from 'notistack'
import NumberFormat from 'react-number-format'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';


class EditIncasso extends React.Component {
    state = {
        edit: false,
        validate: false,
        curr_incasso: {
            id: 0,
            fattura_id: 0,
            data: '',
            importo: 0.0, 
            metodo_pagamento: 0,
            note: '', 
            contestuale: false 
        },
        metodi_pagamento: [],
        lista_pazienti: [],
        lista_fatture: [],
        curr_fattura: undefined,
        curr_fattura_label: '',
        curr_metodo: undefined,
        curr_paziente_id: 0,
    }

    componentDidMount () {
        try {
            if(this.props.match.params.incasso_id) {
                let url_incasso = `${process.env.REACT_APP_API_URL}/dc/api_incasso/` + this.props.match.params.incasso_id
                api.call_get(url_incasso,(res) => {
                    this.setState({ curr_incasso: res, edit: true})
                })
            }
        } catch (e) {
          console.log("Errore init: " + JSON.stringify(e))
        }
        this.loadMetodi()
      }

      componentDidUpdate (old) {
        try {
          if(this.props.match.params.paziente_id !== undefined && (old.match.params.paziente_id != this.props.match.params.paziente_id)) {
            if(this.props.match.params.paziente_id) {
                let url_paziente = `${process.env.REACT_APP_API_URL}/dc/api_paziente/` + this.props.match.params.paziente_id
                fetch(url_paziente, { 
                    headers: { 
                        Authorization: `JWT ${localStorage.getItem('token')}`
                    }
                }).then(res => res.json())
                .then(res => {
                    const paz  = res.results[0]
                    this.setState({ curr_paziente: paz})
                })
            }
          }
      } catch (e) {
        console.log("Errore init: " + JSON.stringify(e))
      }
      }

      handleInputPazienti = (evt) => {
        const url_paz = `${process.env.REACT_APP_API_URL}/dc/autocomplete_pazienti?q=` 
        api.call_get(url_paz + evt.target.value, (result) => {
              const pazienti = result.results.map((row, index) => {
                return (
                  {
                      value: row,
                      label: row.text
                  }
                )
              })
              this.setState({
                lista_pazienti: pazienti
              })
          })
      }

      handleChangePaziente = (paz) => {
        const url_paz = `${process.env.REACT_APP_API_URL}/dc/fatture_paziente/${paz.id}/` 
        api.call_get(url_paz, (result) => {
        const lista_fatture = result.results.filter(row => Number((row.importo - row.incassato).toFixed(2)) > 0).map((row, index) => {
            return (
                {
                    value: row,
                    label: "N. " + row.numero_fattura + " del " + row.data_fattura
                }
              )
            })  
            
            this.setState({
              lista_fatture: lista_fatture,
              curr_fattura: undefined,
              curr_fattura_label: '',
              curr_paziente_id: paz.id,
              curr_incasso: {
                ...this.state.curr_incasso,
                fattura_id: 0,
                importo: 0
              }
            })
        })
      }

      getMax(fatt) {
        let importo = fatt.importo
        if(importo >= 77.47) {
          importo += 2.0
        }
        return Number(importo.toFixed(2)) 
      }


      handleChangeFattura = (evt) => {
            const fatt = evt.target.value
            let importo = this.getMax(fatt)
            
            this.setState({
              curr_fattura: fatt,
              curr_fattura_label: '',
              curr_incasso: {
                ...this.state.curr_incasso,
                fattura_id: fatt.id,
                importo: Number((importo - fatt.incassato).toFixed(2))
              }
            })
      }

      handleChangeMetodo = (value) => {
        this.setState({
          curr_incasso: {
              ...this.state.curr_incasso,
              metodo_pagamento: value.id
          },
          curr_metodo: value
      })
    }

    handleChangeNote = (ev) => {
      const value = ev.target.value
      this.setState({
        curr_incasso: {
            ...this.state.curr_incasso,
            note: value
        }
    })
  }

    handleChangeImporto = (value) => {
      this.setState({
        curr_incasso: {
            ...this.state.curr_incasso,
            importo: value
        }
    })
    }
    
    handleSave = () => {
        this.salvaIncasso()
    }

    handleChange = (name) => ({target: { value } }) => {
        this.setState({
            curr_incasso: {
                ...this.state.curr_incasso,
                [name]: value
            }
        })
    }

    
    
    loadMetodi = () => {
      const url_metodi = `${process.env.REACT_APP_API_URL}/dc/metodi_pagamento/`
        api.call_get(url_metodi, (result) => {
              this.setState({
                metodi_pagamento: result.results
              })
          })
    }
    

    handleClickOpen = () => {
        this.setState({open: true})
    }

    handleClose = () => {
        this.setState({open: false})
    }
  

  salvaIncasso() {
      let { curr_incasso, validate, curr_metodo } = this.state  
      if (!curr_metodo) {
        validate = true  
        this.setState({
          validate: validate
        })
        return false
      }

      if (curr_metodo.note_obbligatorie && (curr_incasso.note === undefined || curr_incasso.note ===  '')) {
        validate = true  
        this.setState({
          validate: validate
        })
        return false
      }

      if (!curr_incasso.fattura_id || curr_incasso.fattura_id === 0) {
        validate = true  
        this.setState({
          validate: validate
        })
        return false
      }

      let url_incasso = `${process.env.REACT_APP_API_URL}/dc/api_incasso/${curr_incasso.id}/`
      let method = 'PUT'
      let msg = `Incasso modificato correttamente.`
      if (!curr_incasso.id || curr_incasso.id === 0) {
        //nuovo incasso
        url_incasso = `${process.env.REACT_APP_API_URL}/dc/api_nuovo_incasso/`
        curr_incasso.id = null
        if(!curr_incasso.data) {
          curr_incasso.data = new Date().toISOString().substring(0, 10)
        }
        delete curr_incasso.id
        msg = `Incasso inserito correttamente.`
        method = 'POST'
      }
      api.call_post(
        url_incasso, 
        curr_incasso, (res) => {
          this.props.enqueueSnackbar(msg, {variant:  'success', autoHideDuration: 2000})
          //TODO: capire come effettuare la navigazione senza usare window.location.href
          window.setTimeout(() => { window.location.href = "/gui/lista_incassi"}, 1750)
        },
        (err) => {
          let msg = ''
          for (const c in err.response.data) {
            msg += err.response.data[c] + " "
          }
          this.props.enqueueSnackbar(`Errore salvataggio dati: ${msg}`, {variant:  'error', autoHideDuration: 5000})
        },
        method)
    }

  editCancel() {
    window.location.href = '/gui/lista_incassi/'
  }

  handleChangeDate(date) {
    try {
          this.setState({
            curr_incasso: {
                ...this.state.curr_incasso,
                'data': date.format('YYYY-MM-DD')
            }
        })
    } catch(e) {
      ;
    }
  }

  

  render() {
  let { 
    edit,
    validate,
    curr_incasso: {
      fattura_id,
      importo, 
      metodo_pagamento,
      note,
      data
     },
     lista_fatture,
     lista_pazienti,
     metodi_pagamento,
     curr_fattura,
     curr_fattura_label ,
     curr_metodo
  } = this.state
  const edit_incasso = this.state.curr_incasso
  const curr_metodo_id = edit_incasso.metodo_pagamento
  const moment = new MomentUtils();
  if(!data) {
    data = moment.moment(new Date()).format('YYYY-MM-DD')
  }

  let tt = "Nuovo incasso"
  if(edit) {
    tt = "Modifica incasso"
  } 
  const options_metodi_pagamento = metodi_pagamento.map((row, index) => {
    if (curr_metodo_id && row.id == curr_metodo_id) {
      curr_metodo = row
      this.state.curr_metodo = curr_metodo
    }
    return (
      {
          value: row,
          label: row.nome
      }
    )
  })
  
  return (
    <Container component="main" maxWidth="xs">
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'it'}>
        <h3>{tt}</h3>
        
        <form id="form_paziente"> 
          <Grid container spacing={2}>
          {!edit && <Grid item xs={12}>
          <FormControl >    
          
          <SingleSelect
                    variant="filled"
                    color="inherit"
                    name='paziente' 
                    label='Paziente'
                    fullWidth={true}
                    style={{width: "400px"}}
                    options={lista_pazienti}
                    onInput={
                      this.handleInputPazienti
                    } 
                    onChange={(val) => {
                      this.handleChangePaziente(val)
                    }}
                    SelectProps={{
                      msgNoOptionsAvailable: "Digita parte del nome del paziente",
                      msgNoOptionsMatchFilter: "Non ci sono pazienti che corrispondono alla ricerca"
                    }}
           />
          </FormControl>
          </Grid>}
          {!edit && <Grid item xs={12}>
          <FormControl >    
          <Select
                    error={
                      (this.state.curr_paziente_id > 0 && (!lista_fatture || lista_fatture.length === 0)) ||
                      (validate && !this.state.curr_fattura)
                    }
                    required
                    variant="filled"
                    color="inherit"
                    name='curr_fattura' 
                    label='Fattura'
                    style={{width: "400px"}}
                    fullWidth={true}
                    value={curr_fattura}
                    onChange={(val) => {
                      this.handleChangeFattura(val)
                    }}
          >
          {lista_fatture.map((item, index) => {
            return (
              <MenuItem value={item.value}>{item.label}</MenuItem>
            )
          })}  
          </Select>
          <FormHelperText error={(
            this.state.curr_paziente_id > 0 && (!lista_fatture || lista_fatture.length === 0))
            ||
            (validate && !this.state.curr_fattura)
            } >{(this.state.curr_paziente_id > 0 && (!lista_fatture || lista_fatture.length === 0)) ? "Il paziente selezionato non ha fatture da pagare" : 'Scegli fattura'}</FormHelperText>  
          </FormControl>
          </Grid>}
          {edit && <Grid item xs={12}>
            {curr_fattura_label}
          </Grid>}
          <Grid item xs={12}>
          <FormControl >    
          <KeyboardDatePicker 
                      disableFuture={true}
                      name="data"
                      label="Data incasso"
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      value={data || null}
                      onChange={(date) => {this.handleChangeDate(date)}}
                      style={{ width: "90%" }}
                    />
          </FormControl>
          </Grid>
          <Grid item xs={12}>
          <FormControl >    
          <NumberFormat 
                    label={'importo'}
                    value={Number(importo)} 
                    defaultValue={0.00}
                    allowEmptyFormatting={true}    
                    margin="dense"
                    customInput={TextField} 
                    fixedDecimalScale={2} 
                    decimalScale={2} 
                    decimalSeparator=','
                    thousandSeparator='.'
                    suffix={' EUR'}
                    style={{width: "400px"}}
                    isAllowed={(values) => {
                      const {floatValue} = values;
                      return floatValue > 0 &&  (edit || floatValue <= this.getMax(curr_fattura) - curr_fattura.incassato);
                    }}  
                    onValueChange={(values)=> {this.handleChangeImporto(values.floatValue)}}
                    helperText={curr_fattura ? "Importo da pagare fattura n. " + curr_fattura.numero_fattura + ": " + importo + " EUR": ' '}
                    />
          </FormControl>          
          </Grid>
          <Grid item xs={12}>
          <FormControl >    
          <SingleSelect
                      error={validate && !curr_metodo}
                      name={'metodo_pagamento'}
                      label={'Metodo di pagamento'}
                      style={{width: "400px"}}
                      options ={options_metodi_pagamento}
                      value ={curr_metodo}
                      onChange={(val)=> {this.handleChangeMetodo(val)}}
                      helperText={"Seleziona un metodo di pagamento"}
                    />
          </FormControl>          
          </Grid>
          <Grid item xs={12}>
          <FormControl >    
          <TextField
                      required={curr_metodo ? curr_metodo.note_obbligatorie : false}
                      error={validate && curr_metodo && curr_metodo.note_obbligatorie}
                      name={'note'}
                      label={'Note'}
                      style={{width: "400px"}}
                      value ={note}
                      onChange={(val)=> {this.handleChangeNote(val)}}
                      helperText={curr_metodo ? curr_metodo.suggerimento_note : ' '}
                    />
          </FormControl>          
          </Grid>
          
          
          </Grid> 
          </form>  
          
        <Grid container spacing={1}>
        <Grid item xs={6} align="left">
          <Button  variant="contained" onClick={this.editCancel} color="secondary">
            Annulla
          </Button>
        </Grid>   
          <Grid item xs={6} align="right"> 
          <Button  variant="contained" onClick={this.handleSave} color="primary">
            Salva
          </Button>
          </Grid>   
        </Grid> 
        </MuiPickersUtilsProvider>   
    </Container>
  )}
}

export default withSnackbar(EditIncasso)