import React, { Component } from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import ListIcon from '@material-ui/icons/List'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
//import DialogPaziente from './dialogs/DialogPaziente'
import Fab from '@material-ui/core/Fab' 
import Tooltip from '@material-ui/core/Tooltip'
import NumberFormat from 'react-number-format'
import { BrowserRouter as Router, Route, Link as RouterLink } from "react-router-dom"
import { Link } from '@material-ui/core'
import { Add, Edit } from '@material-ui/icons'
import Container from '@material-ui/core/Container'
import api from "../api_calls/api"
import Button from '@material-ui/core/Button'
//import TurnoCassa from './TurnoCassa'
import { withSnackbar } from 'notistack'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";




const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    }, 
      palette: {
        primary: {
          light: '#0000',
          main: '#00000',
          dark: '#0000',
          contrastText: '#fff',
        },
        secondary: {
          light: '#ff7961',
          main: '#f44336',
          dark: '#ba000d',
          contrastText: '#000',
        },
      },
}));






function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

class ListaFatture extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      logged_in: localStorage.getItem('token') ? true : false,
      lista_fatture: [],
      canEdit: true,
      cerca_f: ''
    } 
  }

  setEditCassa(val) {
    this.setState({
      canEdit: val
    })
  }

  apriCassa() {
    api.apriCassa((data) => {
      window.location.href = '/gui/lista_incassi'
    })
  }
  
  chiudiCassa() {
    api.chiudiCassa((data) => {
      window.location.href = '/gui/stampa_incassi'
    })
  }
  

  
  componentDidMount() {
        this.cercaFatture({target: {value: ''}})
  }

  cercaFatture = () => {
    const url_paz = `${process.env.REACT_APP_API_URL}/dc/ricerca_fatture?q=`
    if(!this.state.cerca_f || this.state.cerca_f.length < 1) {
      return;
    }
    api.call_get(url_paz + this.state.cerca_f, (result) => {
      this.setState({
        lista_fatture: result.results,
        sedeCorrente: localStorage.getItem('sede_corrente_nome')
      })
    })
  }


  handleChange = (event, value) => {
    if (value === 'piano') {
      this.setState({ curr_piano: null })
    }
    this.setState({ value });
  };

  setCurrPiano = (id, piano) => {
    this.setState({
      curr_piano: id,
      piano: piano
    })
  }

  caricaPiano = (id) => {
    let piano = undefined
    const url_piani = `${process.env.REACT_APP_API_URL}/dc/piani_terapeutici/${this.props.pid}`
      fetch(url_piani, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(result => result.json())
        .then(result => {
          const piani  = result.results
          for (let k = 0; k < piani.length; ++k) {
            if (piani[k].id = id) {
              piano = piani[k]
              break
            }
          }
          this.setState({
            curr_piano: id,
            piano: piano,
            value: 'piano'
          })
      }) 
  }

  clickPianoUtente(id) {
    this.caricaPiano(id)
  }

  chkTurnoOpen() {
    //canEdit: api.canEditCassa(),
     const canOpen = api.canOpenCassa()
    if (canOpen) {
      this.props.enqueueSnackbar("E' necessario aprire la cassa per inserire una fattura.", 
          {
            variant:  'error', 
            autoHideDuration: 5000
          })
    } else {
      window.location.href = "/gui/nuova_fattura/"
    }
  }



  render() {
    const { pid } = this.props
    const { value }  = this.state
    const { paziente }  = this.props
    const { curr_piano }  = this.state
    const { piano }  = this.state
    const { canEdit } = this.state
    const moment = new MomentUtils()
    const {sedeCorrente} = this.state
    

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'it'}>
      <Container component="main" maxWidth="md">
          <Grid container xs={12}>
            <Grid item xs={12} >
                  <Grid container xs={12}>
                    <Grid item xs={3} >
                       <h2>Ricerca fatture<br/> {sedeCorrente}</h2>
                    </Grid>
                    <Grid item xs={9} >
                    <TextField
                    name='lista_fatture'
                    label='Cerca fatture'
                    style={{ width: "80%" }}
                    onChange={(evt) => this.setState({
                      cerca_f:  evt.target.value
                    })}
                    onKeyDown={(evt) => { if (evt.key === 'Enter') this.cercaFatture(); }}
                    InputProps={{endAdornment:  <IconButton
                                aria-label="Cerca"
                                title="Cerca"
                                color="primary"
                                size="small"
                                onClick={this.cercaFatture}
                                edge="end"><SearchIcon />
                              </IconButton>}}
                    />
                    </Grid>   
                    
                 </Grid>
            </Grid>
            {/*<TurnoCassa setEditCassa={this.setEditCassa.bind(this)} />*/}
            <Grid item xs={12} >
              <Paper elevation={5} style={{ margin: '0 15px 5px 0' }}>
                  <h3>Fatture</h3>
                  <Table dense={false}>
                    <TableHead>
                        <TableRow>
                          <TableCell>Numero</TableCell>
                          <TableCell>Data</TableCell>
                          <TableCell>Destinatario</TableCell>
                          <TableCell align="right">Importo</TableCell>
                          <TableCell>  
                          <Link color="inherit" onClick={() => {this.chkTurnoOpen()} }>
                          <Tooltip title="nuova fattura">
                              <Fab size="small" color="secondary">
                                <Add/>
                              </Fab>
                            </Tooltip>  
                          </Link>
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.lista_fatture.map((item) => {
                      return (<TableRow>
                          <TableCell>{item.numero_fattura}</TableCell>
                          <TableCell>{moment.moment(item.data_fattura).format("DD/MM/YYYY")}</TableCell>
                          <TableCell>{item.text}</TableCell>
                          <TableCell align="right">
                            <NumberFormat 
                                value={Number(item.totale_fattura)} 
                                displayType={'text'}
                                fixedDecimalScale={2} 
                                decimalScale={2} 
                                decimalSeparator=','
                                thousandSeparator='.'
                                suffix={' EUR'}
                                />
                          </TableCell>
                          <TableCell>
                  
                            <Tooltip title="seleziona fattura">
                            <Link color="inherit" component={RouterLink} to={'/fattura/' + item.id}>
                              <Fab size="small" color="secondary">
                                <CheckIcon/>
                              </Fab>
                              </Link>
                            </Tooltip>  
                            
                          </TableCell>
                      </TableRow>)
                    })
                    }
                    </TableBody>
                  </Table>
              </Paper>
            </Grid>  
             
            <Grid item xs={2} >
                
            </Grid>  
          </Grid>
        
      
      </Container>
      </MuiPickersUtilsProvider>
    )
  }
}
export default withSnackbar(ListaFatture)
