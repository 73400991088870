import React, { Fragment, useState } from 'react'
import {
    Button,
 } from '@material-ui/core/'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import "moment/locale/it"
import api from "../api_calls/api"
import { withSnackbar } from 'notistack'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

class DialogPIN extends React.Component {
    state = {
        open: false,
        validate: false,
        vecchio_pin: '',
        nuovo_pin: '',
        ripeti_pin: '',
    }

    handleSave = () => {

    }

    handleChange = (name) => ({target: { value } }) => {
        this.setState({
            [name]: value
        })
    }


    handleClose = () => {
        this.setState({open: false})
    }

    validaDati = () => {
        if (this.state.nuovo_pin !== '' && this.state.vecchio_pin  !== '' && this.state.ripeti_pin  !== '') {
            return true;
        } else {
            return false;
        }
    }

    validaRipeti = () => {
        return (this.state.nuovo_pin === this.state.ripeti_pin)
    }


    sendPIN = () => {
        const valido = this.validaDati() && this.validaRipeti();
        if(valido) {
            const url = `${process.env.REACT_APP_API_URL}/dc/change_pin`;
            const method = 'PUT';
            const data = {
                old: this.state.vecchio_pin,
                new: this.state.nuovo_pin
            }
            api.call_post(
                url,
                data, (res) => {
                    if(res.esito === 'OK') {
                        this.props.enqueueSnackbar(res.message, {variant:  'success', autoHideDuration: 4000});
                        this.props.toggleOpen();
                    } else {
                        this.props.enqueueSnackbar(res.message, {variant:  'error', autoHideDuration: 4000});
                    }
                },
            (err) => {
                this.props.enqueueSnackbar("Errore del server. Segnalare all'assistenza.", {variant:  'error', autoHideDuration: 4000});
            }, method);
        } else {
            this.setState({
                validate: !valido
            });
        }
    }


    helperVecchio = () => {
        let res = ' ';
        if (this.state.validate && this.state.vecchio_pin === '') {
            res = 'Obbligatorio. Se non hai un PIN inserisci la tua password.'
        }
        return res;
    }

    errorVecchio = () => {
        let res = false;
        if (this.state.validate && this.state.vecchio_pin === '') {
            res = true;
        }
        return res;
    }

    helperNuovo = () => {
        let res = ' ';
        if (this.state.validate && this.state.nuovo_pin === '') {
            res = 'Obbligatorio.'
        }
        if (this.state.validate && this.state.nuovo_pin.length < 4) {
            res = 'Il PIN è troppo corto.'
        }
        if (this.state.validate && this.state.nuovo_pin.length > 16) {
            res = 'Il PIN è troppo lungo.'
        }
        return res;
    }

    errorNuovo = () => {
        let res = false;
        if (this.state.validate && this.state.nuovo_pin === '') {
            res = true;
        }
        if(res && (this.state.nuovo_pin.length > 16 || this.state.nuovo_pin.length < 4)) {
            res = false;
        }
        return res;
    }

    helperRipeti = () => {
        let res = ' ';
        if (this.state.validate && this.state.ripeti_pin === '') {
            res = 'Obbligatorio.'
        } else if (this.state.validate && this.state.ripeti_pin !== this.state.nuovo_pin) {
            res = 'I PIN non coincidono.'
        }
        return res;
    }

    errorRipeti = () => {
        let res = false;
        if (this.state.validate && (this.state.ripeti_pin === '' || this.state.ripeti_pin !== this.state.nuovo_pin)) {
            res = true;
        }
        return res;
    }



  render() {
  const {
    validate
  } = this.state

  return (
    <Dialog open={this.props.open} fullWidth={false}>
      <DialogTitle id="form-dialog-title">Cambia il tuo PIN</DialogTitle>
      <DialogContent>
          <form id="form_paziente">
           <Grid container spacing={0}>
              <Grid item xs={12}>
              <FormControl >
              <TextField
                autoFocus
                required
                error={this.errorVecchio()}
                helperText={this.helperVecchio()}
                margin="dense"
                id="vecchio_pin"
                name="vecchio_pin"
                value={this.state.vecchio_pin}
                label="Vecchio PIN"
                type="password"
                onChange={this.handleChange('vecchio_pin')}
              />
              </FormControl>
              </Grid>
               <Grid item xs={12}>
              <FormControl >
              <TextField
                autoFocus
                required
                error={this.errorNuovo()}
                helperText={this.helperNuovo()}
                margin="dense"
                id="nuovo_pin"
                name="nuovo_pin"
                value={this.state.nuovo_pin}
                label="Nuovo PIN"
                type="text"
                onChange={this.handleChange('nuovo_pin')}
              />
              </FormControl>
              </Grid>
               <Grid item xs={12}>
              <FormControl >
              <TextField
                autoFocus
                required
                error={this.errorRipeti()}
                helperText={this.helperRipeti()}
                margin="dense"
                id="ripeti_pin"
                name="pipeti_pin"
                value={this.state.ripeti_pin}
                label="Ripeti PIN"
                type="text"
                onChange={this.handleChange('ripeti_pin')}
              />
              </FormControl>
              </Grid>
           </Grid>
          </form>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={0}>
        <Grid item xs={6} align="left">
          <Button  variant="contained" onClick={this.props.toggleOpen} color="secondary">
            Annulla
          </Button>
        </Grid>
          <Grid item xs={6} align="right">
              <Button  variant="contained" onClick={this.sendPIN} color="primary">
                Invia
              </Button>
          </Grid>
        </Grid>
        </DialogActions>
    </Dialog>
  )}
}

export default withSnackbar(DialogPIN)
