import React, { Component } from 'react'
import DiagrammaDentale from "../components/DiagrammaDentale"
class PianoTerapeutico extends Component {
    
    state = {
        options: []
    } 

    render() {
    return(
        <div>
            <DiagrammaDentale paziente={this.props.paziente} piano={this.props.piano} piano_obj={this.props.piano_obj} />
        </div>
    )
    }
    handleChange = (value) => {
        console.log(value);
      }
      
    handleInputChange = (value) => {
        fetch(`${process.env.REACT_APP_API_URL}/dc/question_set`, {
            headers: {
              Authorization: `JWT ${localStorage.getItem('token')}`
            }
          })
        .then(response => {
            return response.json()
        })
        .then(data => {
            const options = data.results.map((row, index) => {
                return (
                  {
                      value: row.id,
                      label: row.text
                  }
                )
              })
            
            this.setState({options: options})
            
           
        })
        .catch(err => {
                // Do something for an error here
        })
    }  

}
export default PianoTerapeutico;