import React, { Component } from 'react'
import { createMuiTheme } from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'
import cyan from '@material-ui/core/colors/cyan'
import { BrowserRouter as Router, Route, Link as RouterLink } from "react-router-dom"
import { withStyles } from '@material-ui/styles'
import DCNavBar from './components/DCNavBar'
import SignIn from './auth/SignIn'
import ListaFatture from './components/ListaFatture'
import ListaIncassi from './components/ListaIncassi'
import StampaIncassi from './components/StampaIncassi'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Incassi from "./components/Incassi"
import Calendario from "./components/Calendario"
import Pazienti from "./components/Pazienti"
import EditPaziente from "./components/EditPaziente"
import EditIncasso from "./components/EditIncasso"
import Fattura from "./components/Fattura"
import FatturaSingola from "./components/FatturaSingola"
import DatiSede from "./components/DatiSede"
import history from './components/history'
import SedeBloccata from './components/SedeBloccata'
import api from './api_calls/api'
import DialogPIN from './dialogs/DialogPIN.js'
import { withSnackbar } from 'notistack'
import DialogContatti from "./dialogs/DialogContatti";


const styles = {
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
};


function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0 * 3 }}>
      {props.children}
    </Typography>
  );
}

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: cyan,
    accent1color: blue
  },
  status: {
    danger: 'orange',
  },
})

class App extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      displayed_form: '',
      logged_in: localStorage.getItem('token') ? true : false,
      user: {},
      value: 'piano',
      paziente: undefined,
      curr_piano: null,
      pid: 0,
      options_pazienti: [],
      sbopen: false,
      sbmessage: '',
      sede_corrente: -1,
      sede_login: 1,
      sede_bloccata: false,
      blocco_appuntamenti: false,
      change_pin: false,
      open_contatti: false,
      lista_contatti: []
    }
    api.listaSedi()
  }

  componentWillUnmount() {
    try {
      clearInterval(this.interval1);
      clearInterval(this.interval2);
      clearInterval(this.interval3);
    } catch (e) {;}
  }

  componentWillMount() {
    let search = window.location.search
    let params = new URLSearchParams(search)
    let pid = params.get('pid')
    
    this.setState({
      pid: pid
    })
  }
  componentDidUpdate(old) {
    if(!localStorage.getItem('token') && this.state.logged_in) {
      this.setState({ logged_in: false, username: '' })
      window.location.href = "/gui/"  
    } else {
      setTimeout(() => this.checkSede(this), 5 * 1000);
    }
  }

  checkContatti(app, force) {
    if(app.state.sede_bloccata || ((force === undefined || !force) && app.state.open_contatti)) {
      return
    }
    console.log('checkContatti ...')
    const tk = localStorage.getItem('token');
    if (tk) api.contattiSocialDaChiamare(
        (json) => {
          const data = json.results;
          console.log('checkContatti OK: ' + JSON.stringify(data));
          if (data && data.length > 0 && !app.state.sede_bloccata) {
            console.log("APRO CONTATTI");
            app.setState({
                open_contatti: true,
                lista_contatti: data
            });
          }
        },
        () => {
          console.log('checkContatti KO');
        }
    );
  };

  checkSede(app) {
    console.log('check sede...')
    const tk = localStorage.getItem('token');
    //console.log("token: " + tk);
    if (tk) api.datiSede(
        (json) => {
          const data = json.results[0];
          try {
            if(app.state && data.bloccata !== app.state.sede_bloccata) {
              app.setState({
                sede_bloccata: data.bloccata
              });
            }
            console.log('fine check sede OK ' + data.bloccata);
          } catch (e) {
            console.log('fine check sede OK ' + e);
          }
        },
        () => {
          console.log('fine check sede KO');
        }
    );
  };

  checkSedeCorrente(app) {
    const tk = localStorage.getItem('token');
    //console.log("token: " + tk);
    if (tk) api.datiSedeCorrente(
        (json) => {
          const data = json.results[0];
          try {
            if(app.state && data.blocco_appuntamenti !== app.state.blocco_appuntamenti) {
              app.setState({
                blocco_appuntamenti: data.blocco_appuntamenti
              });
            }
            if(data.blocco_appuntamenti>0) {
              this.props.enqueueSnackbar(<div><strong>ATTENZIONE BLOCCO SEDE:</strong><br/>CI SONO {JSON.stringify(data.blocco_appuntamenti)} APPUNTAMENTI DI DOMANI IN SOSPESO<br/>ENTRO LE ORE {data.when_blocco_appuntamenti} PREMI IL TASTO “CONFERMATO” OPPURE SELEZIONA UNO DEI MOTIVI DI ANNULLAMENTO IN CASO DI MANCATA CONFERMA DA PARTE DEL PAZIENTE.</div>, {variant: 'error', autoHideDuration: 10000});
            }
            if(data.appuntamenti_da_aggiornare !== '') {
              this.props.enqueueSnackbar(<div><strong>ATTENZIONE!</strong><br/>{data.appuntamenti_da_aggiornare}</div>, {variant: 'error', autoHideDuration: 10000});
            }
            if(data.stato_ripetibilita !== '') {
              this.props.enqueueSnackbar(<div><strong>ATTENZIONE!</strong><br/>{data.stato_ripetibilita}</div>, {variant: 'error', autoHideDuration: 10000});
            }
            if(data.load_average_message && data.load_average_message!=='') {
              this.props.enqueueSnackbar(<div><strong>ATTENZIONE!</strong>{JSON.stringify(data.load_average_message)}</div>, {variant: 'error', autoHideDuration: 5000});
            }
          } catch (e) {
            console.log('Errore datiSedeCorrente ' + e);
          }
        },
        () => {
          console.log('Errore lato server datiSedeCorrente');
        }
    );
  };

  toggleOpenPIN() {
      this.setState({
        change_pin: !this.state.change_pin
      })
    }

    toggleOpenContatti() {
      this.setState({
        open_contatti: !this.state.open_contatti
      })
    }

    refreshContatti() {
      this.checkContatti(this, true);
    }

  componentDidMount() {
    console.log("APP DID MOUNT " + Date().toString());
    if (this.state.logged_in) {
      this.interval1 = setInterval(() => this.checkSede(this), 30 * 1000); //secondi
      this.interval2 = setInterval(() => this.checkSedeCorrente(this), 30 * 1000); //secondi
      this.interval3 = setInterval(() => this.checkContatti(this), 30 * 60 * 1000); //secondi

      //impedire che ricarichino la pagina aggirando il blocco per la durata dell'intervallo
      try {
        this.checkSede(this);
      } catch (e) {;}

      if(this.state.pid === undefined || this.state.pid === null) {
        return
      }
      const url_paziente = `${process.env.REACT_APP_API_URL}/dc/api_paziente/${this.state.pid}`
      fetch(url_paziente, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(result => result.json())
        .then(result => {
          console.log("DATI PAZIENTE: " + JSON.stringify(result))
          try {
            const paziente = result.results[0]
            this.setState({
              paziente: paziente
            })
          } catch(e) {
            this.setState({
              logged_in: false
            })
          }
      })
      }
  }

  setPaziente(paziente) {
    if(paziente === undefined) {
      this.setState({
        paziente: paziente,
        pid: paziente
      })
    } else {
      this.setState({
        paziente: paziente,
        pid: paziente.id
      })
    }
  }

  handle_login = (e) => {
    e.preventDefault()
    const data = { 
      username: e.target.username.value,
      password: e.target.password.value,
      sede_login: this.state.sede_login
    }

    console.log("RICHIESTA LOGIN" + JSON.stringify(data))
    api.login(
      data, 
      (json) => {
        if (json.token !== undefined) {
          this.setState({
            logged_in: true,
            login_error: false,
            user: json.user,
            sede_bloccata: json.sede_corrente_bloccata
          })
          this.props.enqueueSnackbar("Login effettuato.", {variant: 'success', persist: false, autoHideDuration: 2000})
      } else {
        this.setState({
          logged_in: false,
          login_error: true,
          user: {},
          sbopen: true,
          sbmessage: "Credenziali non valide."
        })
        alert("Credenziali non valide.")
        this.props.enqueueSnackbar("Credenziali non valide.", {})
      }
    },
    (err) => {
      if(err.message === 'Network Error') {
        this.props.enqueueSnackbar("Impossibile raggiungere il server, riprovare più tardi.", {variant: 'error', persist: false, autoHideDuration: 2000})
        
      } else if(err.message.endsWith("400")) {
        this.props.enqueueSnackbar("Credenziali non valide.", {variant: 'error', persist: false, autoHideDuration: 2000})
        this.setState({
          sbopen: true,
          sbmessage: "Credenziali non valide"
        })
      } else {
        this.props.enqueueSnackbar(err.message, {})
        this.setState({
          sbopen: true,
          sbmessage: JSON.stringify(err.message)
        })
      }
    })
    /*
    fetch(`${process.env.REACT_APP_API_URL}/token-auth/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(json => {
        console.log("RISPOSTA LOGIN: " + JSON.stringify(json))
        if (json.token !== undefined) {
          localStorage.setItem('token', json.token)
          this.setState({
            logged_in: true,
            login_error: false,
            user: json.user
          })
        } else {
          this.setState({
            logged_in: false,
            login_error: true,
            user: {}
          })
        }
      })*/
  }

  handle_logout = () => {
    localStorage.clear()
    this.setState({ logged_in: false, username: '' })
    window.location.href = '/gui/'
  }


  handleChange = (event, value) => {
    if (value === 'piano') {
      this.setState({ curr_piano: null })
    }
    this.setState({ value });
  };

  setCurrPiano = (id, piano) => {
    this.setState({
      curr_piano: id,
      piano: piano
    })
  }

  caricaPiano = (id) => {
    let piano = undefined
    const url_piani = `${process.env.REACT_APP_API_URL}/dc/piani_terapeutici/${this.state.pid}`
      fetch(url_piani, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(result => result.json())
        .then(result => {
          const piani  = result.results
          for (let k = 0; k < piani.length; ++k) {
            if (piani[k].id = id) {
              piano = piani[k]
              break
            }
          }
          this.setState({
            curr_piano: id,
            piano: piano,
            value: 'piano'
          })
      }) 
  }

  clickPianoUtente(id) {
    this.caricaPiano(id)
  }

  handleInputPazienti = (evt) => {
    const url_paz = `${process.env.REACT_APP_API_URL}/dc/autocomplete_pazienti?q=` 
    fetch(url_paz + evt.target.value, {
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`
      }
    })
        .then(result => result.json())
        .then(result => {
          const pazienti = result.results.map((row, index) => {
            return (
              {
                  value: row,
                  label: row.text
              }
            )
          })
          this.setState({
            options_pazienti: pazienti
          })
      })
  }

  handlePaziente = (paziente) => {
    console.log("selezionato paziente: " + JSON.stringify(paziente))
    if(paziente === undefined || paziente === null) {
      return
    }
    const url_paziente = `${process.env.REACT_APP_API_URL}/dc/api_paziente/${paziente.id}`
      fetch(url_paziente, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(result => result.json())
        .then(result => {
          try {
            const paz = result.results[0]
            this.setState({
              paziente: paz,
              pid: paz.id
            })
            history.push('/pazienti/')
          } catch(e) {
            console.log("ERRORE DATI PAZIENTE: " + JSON.stringify(e))
            this.setState({
              logged_in: false
            })
          }
      })
  }

  handleSBClose = () => {
    this.setState({
      sbopen: false,
      sbmessage: ''
    })
  }

  
  cambiaSede = (event) => {
      this.setState({
        sede_login: event.target.value
      })
  }


  render() {
    const { pid } = this.state
    const { value }  = this.state
    const { paziente }  = this.state
    const { curr_piano }  = this.state
    const { piano }  = this.state
    const {sbopen, sbmessage} = this.state
    const {sede_login} = this.state

    return (
        <><DialogPIN open={this.state.change_pin} toggleOpen={this.toggleOpenPIN.bind(this)} />
          <DialogContatti open={this.state.open_contatti} listaContatti={this.state.lista_contatti} toggleOpen={this.toggleOpenContatti.bind(this)} refreshContatti={this.refreshContatti.bind(this)} />
        <Router basename={'/gui'} history={history}>
          <DCNavBar logged_in={this.state.logged_in} user={this.state.user} handleLogout={this.handle_logout} handleInputPazienti={this.handleInputPazienti} handlePaziente={this.handlePaziente} options_pazienti={this.state.options_pazienti} togglePin={this.toggleOpenPIN.bind(this)}  history={history}/>
          {!this.state.logged_in &&
            <SignIn handleLogin={this.handle_login} logged_in={this.state.logged_in} user={this.state.user} login_error={this.state.login_error} sede_login={sede_login} cambiaSede={this.cambiaSede} />
          }
          {this.state.logged_in && !this.state.sede_bloccata && <>
            <Route exact path="/" component={Calendario} />
            <Route path="/stampa_incassi/" component={StampaIncassi} />
            <Route path="/lista_incassi/" render={(props) => <ListaIncassi {...props}/>} />
            <Route path="/lista_fatture/" component={ListaFatture} />
            <Route path="/dati_sede/" component={DatiSede} />
            <Route path="/calendario/" component={Calendario} />
            <Route path="/incassi/" component={Incassi} />
            <Route path="/pazienti/" render={(props) => <Pazienti pid={pid} paziente={paziente} setPaziente={this.setPaziente.bind(this)} tab={'piano'} editPaziente={undefined} sede_bloccata={this.state.sede_bloccata} />} />
            <Route path="/nuovo_paziente/" render={(props) => <EditPaziente paziente_id={undefined} paziente={undefined} setPaziente={this.setPaziente.bind(this)} history={history} />} />
            <Route path="/edit_paziente/:paziente_id" render={(props) => <EditPaziente {...props} paziente={undefined} setPaziente={this.setPaziente.bind(this)} />} />
            <Route path="/nuovo_incasso/" render={(props) => <EditIncasso incasso={undefined} history={history} />} />
            <Route path="/edit_incasso/:incasso_id" render={(props) => <EditIncasso {...props} incasso={undefined} history={history} />} />
            <Route path="/nuova_fattura/" render={(props) => <Fattura history={history} paziente={this.state.paziente} setPaziente={this.setPaziente.bind(this)} options_pazienti={this.state.options_pazienti} />} />
            <Route path="/fattura/:fattura_id" render={(props) => <FatturaSingola {...props} />} />  
            </>
          }
          {this.state.logged_in && this.state.sede_bloccata && <>
            <Route exact path="/" render={(props) => <SedeBloccata />} />
            <Route path="/stampa_incassi/" render={(props) => <SedeBloccata />} />
            <Route path="/lista_incassi/"  render={(props) => <SedeBloccata />} />
            <Route path="/lista_fatture/" render={(props) => <SedeBloccata />} />
            <Route path="/dati_sede/"   render={(props) => <SedeBloccata />} />
            <Route path="/calendario/" component={SedeBloccata} />
            <Route path="/incassi/"  render={(props) => <SedeBloccata />} />
            <Route path="/pazienti/" render={(props) => <Pazienti pid={pid} paziente={paziente} setPaziente={this.setPaziente.bind(this)} tab={'piano'} editPaziente={undefined} sede_bloccata={this.state.sede_bloccata} />} />
            <Route path="/nuovo_paziente/"  render={(props) => <SedeBloccata />} />
            <Route path="/edit_paziente/:paziente_id" render={(props) => <SedeBloccata />} />
            <Route path="/nuovo_incasso/"  render={(props) => <SedeBloccata />} />
            <Route path="/edit_incasso/:incasso_id"  render={(props) => <SedeBloccata />} />
            <Route path="/nuova_fattura/"  render={(props) => <SedeBloccata />} />
            <Route path="/fattura/:fattura_id" render={(props) => <SedeBloccata />} />
            </>
          }
        </Router></>
    )
  }
}
export default withSnackbar(withStyles(styles)(App))
