import React, { Fragment, useState } from 'react'
import { 
    Button, 
 } from '@material-ui/core/'
import Fab from '@material-ui/core/Fab' 
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import { SingleSelect } from "react-select-material-ui"
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import history from '../components/history'
import Container from '@material-ui/core/Container'

import { Add, Edit } from '@material-ui/icons'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers"
import "moment/locale/it"
import api from "../api_calls/api"
import { withSnackbar } from 'notistack'
import DialogPazienteCollegato from '../dialogs/DialogPazienteCollegato.js'
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import DeleteIcon from "@material-ui/icons/Delete";
import TableHead from "@material-ui/core/TableHead";

class EditPaziente extends React.Component {
    state = {
        open: false,
        validate: false,
        modifica: false,
        empty_email: false,
        email_start_value: '',
        curr_paziente: {
            id: null,
            cognome: '',
            nome: '',
            email: '',
            data_nascita: '',
            genere: 'm',
            nic: '',
            cellulare: '',
            citta_residenza_testo_libero: '',
            indirizzo_residenza: '',
            pin_operatore: '',
            cap: ''
        },
        pazienti_collegati: [],
        add_collegato: false
    }

    constructor(props) {
        super(props)

        if(props.paziente !== null && props.paziente !== undefined) {
            this.state.curr_paziente.id = props.paziente.id
            this.state.curr_paziente.cognome = props.paziente.cognome
            this.state.curr_paziente.nome = props.paziente.nome
            this.state.curr_paziente.nic = props.paziente.nic
            this.state.curr_paziente.email = props.paziente.email
            this.state.email_start_value = props.paziente.email
            this.state.curr_paziente.cellulare = props.paziente.cellulare || props.paziente.telefono
            this.state.curr_paziente.data_nascita = props.paziente.data_nascita
            this.state.modifica = true;
            this.state.empty_email = (!props.paziente.email || props.paziente.email === '');
        } 
        
      }

      componentDidMount () {
        try {
            if(this.props.match.params.paziente_id) {
                let url_paziente = `${process.env.REACT_APP_API_URL}/dc/api_paziente/` + this.props.match.params.paziente_id
                fetch(url_paziente, { 
                    headers: { 
                        Authorization: `JWT ${localStorage.getItem('token')}`
                    }
                }).then(res => res.json())
                .then(res => {
                    const paz  = res.results[0]
                    if(paz.cellulare === '') {
                      if (Array.isArray(paz.telefono)) {
                        paz.cellulare = paz.telefono[0] 
                      } else {
                        paz.cellulare = JSON.stringify(paz.telefono)
                      }
                    }
                    this.setState({
                        curr_paziente: paz,
                        modifica: true,
                        empty_email: (paz.email === ''),
                        email_start_value: paz.email
                    }, this.caricaCollegati)
                })
            }
        } catch (e) {
          console.log("Errore init: " + JSON.stringify(e))
        }
      }

      componentDidUpdate (old) {
        try {
          if(this.props.match.params.paziente_id !== undefined && (old.match.params.paziente_id != this.props.match.params.paziente_id)) {
            if(this.props.match.params.paziente_id) {
                let url_paziente = `${process.env.REACT_APP_API_URL}/dc/api_paziente/` + this.props.match.params.paziente_id
                fetch(url_paziente, { 
                    headers: { 
                        Authorization: `JWT ${localStorage.getItem('token')}`
                    }
                }).then(res => res.json())
                .then(res => {
                    const paz  = res.results[0]
                    this.setState({ curr_paziente: paz})
                })
            }
          }
      } catch (e) {
        console.log("Errore init: " + JSON.stringify(e))
      }
      }

    getCheckCode = (codiceFiscale) => {
      const CHECK_CODE_ODD = {
        0: 1,
        1: 0,
        2: 5,
        3: 7,
        4: 9,
        5: 13,
        6: 15,
        7: 17,
        8: 19,
        9: 21,
        A: 1,
        B: 0,
        C: 5,
        D: 7,
        E: 9,
        F: 13,
        G: 15,
        H: 17,
        I: 19,
        J: 21,
        K: 2,
        L: 4,
        M: 18,
        N: 20,
        O: 11,
        P: 3,
        Q: 6,
        R: 8,
        S: 12,
        T: 14,
        U: 16,
        V: 10,
        W: 22,
        X: 25,
        Y: 24,
        Z: 23
      }
      
      const CHECK_CODE_EVEN = {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        A: 0,
        B: 1,
        C: 2,
        D: 3,
        E: 4,
        F: 5,
        G: 6,
        H: 7,
        I: 8,
        J: 9,
        K: 10,
        L: 11,
        M: 12,
        N: 13,
        O: 14,
        P: 15,
        Q: 16,
        R: 17,
        S: 18,
        T: 19,
        U: 20,
        V: 21,
        W: 22,
        X: 23,
        Y: 24,
        Z: 25
      }
      
      const CHECK_CODE_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

      codiceFiscale = codiceFiscale.toUpperCase();
        let val = 0
        for (let i = 0; i < 15; i = i + 1) {
          const c = codiceFiscale[i]
          val += i % 2 !== 0 ? CHECK_CODE_EVEN[c] : CHECK_CODE_ODD[c]
        }
        val = val % 26
        return CHECK_CODE_CHARS.charAt(val)
    }


  toggleOpen() {
      console.log('add_collegato:', !this.state.add_collegato);
      this.setState({
        add_collegato: !this.state.add_collegato
      });
    }

  validaCodiceFiscale = (cf) => {
    let res = false
    if(cf.length === 0) {
      res = true
    } else {
      res = /[A-Z]{6}\d{2}[ABCDEHLMPRST]\d{2}[A-Z]\d{3}[A-Z]/i.test(cf)
      //res = res && (cf[15] === this.getCheckCode(cf))
      //console.log(cf[15] + " === " + this.getCheckCode(cf))
    }
    console.log("validaCodiceFiscale: " + res)
    return res
  }

  validaMail(m, pin) {
    let res = false
    if (m.indexOf("@") > 0 && m.indexOf(".") > 0) {
      res = true
    }
    if(!res && m === '' && pin !== '') {
        res = true;
    }
    return res
  }

    caricaCollegati = () => {
        if(!this.state.curr_paziente.id) {
            return;
        }
        const url = `${process.env.REACT_APP_API_URL}/dc/pazienti_collegati/` + this.state.curr_paziente.id + '/';
        console.log("caricaCollegati..");
        api.call_get(
        url,
        (res) => {
             this.setState({
                 pazienti_collegati: res.results
             });
        },
            (res) => {
                console.log("errore collegati:", JSON.stringify(res));
            });

    }



    addCollegato = (p) => {
        const url = `${process.env.REACT_APP_API_URL}/dc/aggiungi_paziente_collegato/` + this.state.curr_paziente.id + '/';
        api.call_post(
        url,
            {collegato_id: p.id}, (res) => {
             if(res.esito === 'OK') {
                this.props.enqueueSnackbar(res.msg, {variant:  'success', autoHideDuration: 4000})
             } else {
                this.props.enqueueSnackbar(res.msg, {variant:  'error', autoHideDuration: 4000})
             }
             this.setState({
                 add_collegato: false
             }, this.caricaCollegati);
        });

    }

    removeCollegato = (p) => {
        const url = `${process.env.REACT_APP_API_URL}/dc/rimuovi_paziente_collegato/` + this.state.curr_paziente.id + '/';
        api.call_post(
        url,
            {collegato_id: p.id}, (res) => {
             if(res.esito === 'OK') {
                this.props.enqueueSnackbar(res.msg, {variant:  'success', autoHideDuration: 4000})
             } else {
                this.props.enqueueSnackbar(res.msg, {variant:  'error', autoHideDuration: 4000})
             }
             this.caricaCollegati();
        });
    }

    handleSave = () => {
        const paz = this.state.curr_paziente
        const invalidCapLength = paz.cap.length > 0 && paz.cap.length !== 5
        const capIsNum = paz.cap.length === 0 || /^\d+$/.test(paz.cap)
        const invalidCF = !this.validaCodiceFiscale(paz.nic)
        const invalidMail = !this.validaMail(paz.email, paz.pin_operatore)


        if (paz.cognome === "" || paz.nome === "" || paz.cellulare === "" || paz.data_nascita === "" || invalidMail || invalidCapLength || !capIsNum || invalidCF || this.errorPin()) {
            this.setState({ validate: true })
            return false
        } else {
            this.salvaPaziente()
        }
    }

    handleChange = (name) => ({target: { value } }) => {
        if(name === 'nic') {
          value = value.toUpperCase()
        }
        this.setState({
            curr_paziente: {
                ...this.state.curr_paziente,
                [name]: value
            }
        })
    }
    
    

    handleClickOpen = () => {
        this.setState({open: true})
    }

    handleClose = () => {
        this.setState({open: false})
    }
  
    getLastPaz() {
        //TODO: smettere di usarlo una volta capito come leggere la risposta dopo il create
        let url_paziente = `${process.env.REACT_APP_API_URL}/dc/get_last_paziente_id/`
        fetch(url_paziente, { 
            headers: { 
                Authorization: `JWT ${localStorage.getItem('token')}`
            }
        }).then(res => res.json())
        .then(res => {
            console.log(JSON.stringify(res))
            const pid = res.results[0].id
        }) 
    }

  salvaPaziente() {
      let { curr_paziente } = this.state  
      let url_paziente = `${process.env.REACT_APP_API_URL}/dc/api_modifica_paziente/${curr_paziente.id}/`
      let method = 'PUT'
      if (curr_paziente.data_nascita === "") {
        curr_paziente.data_nascita = null
      }
      if (!curr_paziente.id || curr_paziente.id === 0) {
        //nuovo paziente
        url_paziente = `${process.env.REACT_APP_API_URL}/dc/api_nuovo_paziente/`
        curr_paziente.id = null
        method = 'POST'
      }
      api.call_post(
        url_paziente, 
        curr_paziente, (res) => {
          this.props.enqueueSnackbar(`Dati paziente ${res.cognome} ${res.nome} inseriti`, {variant:  'success', autoHideDuration: 4000})
          //TODO: capire come effettuare la navigazione senza usare window.location.href
          window.setTimeout(() => { window.location.href = "/gui/pazienti/?pid=" + res.id }, 1750)
        },
        (err) => {
          let msg = ''
          for (const c in err.response.data) {
            msg += err.response.data[c] + " "
          }
          this.props.enqueueSnackbar(`Errore salvataggio dati: ${msg}`, {variant:  'error', autoHideDuration: 4000})
        },
        method)
    }

  editCancel() {
    window.location.href = '/gui/pazienti/'
  }

  handleChangeDate(date) {
    try {
          this.setState({
            curr_paziente: {
                ...this.state.curr_paziente,
                'data_nascita': date.format('YYYY-MM-DD')
            }
        })
    } catch(e) {
      ;
    }
  }

  errorPin() {
        return (this.state.curr_paziente.email !== this.state.email_start_value && this.state.curr_paziente.pin_operatore === '');
  }

  helperPin() {
        if (!this.errorPin()) {
            return ' ';
        } else {
            if (this.state.email_start_value === '') {
                return 'inserire PIN per impostare la mail del paziente';
            } else {
                return 'inserire PIN per modificare la mail del paziente';
            }
        }
  }


  render() {
  console.log("PROPS: " + JSON.stringify(this.props))  
  const { 
    open, 
    validate, 
    curr_paziente: {
      cognome, 
      nome, 
      email, 
      cellulare, 
      data_nascita, 
      nic, 
      citta_residenza_testo_libero,
      indirizzo_residenza,
      pin_operatore,
      cap
     } 
  } = this.state
  let tt = "nuovo paziente"
  if(this.state.curr_paziente.id) {
    tt = "modifica paziente"
  }  
  
  const invalidCF = !this.validaCodiceFiscale(nic)
  const invalidCapLength = cap.length > 0 && cap.length < 5
  const capIsNum = cap.length === 0 || /^\d+$/.test(cap)
  let capErrorMsg = invalidCapLength ? 'Lunghezza non valida!' : ' '
  if (!invalidCapLength) {
    capErrorMsg = !capIsNum ? 'Valore non valido!' : ' '
  }

  return (
    <Container component="main" maxWidth="xs">
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'it'}>
        {!this.state.curr_paziente.id && <h3>Nuovo Paziente</h3>}
        {this.state.curr_paziente.id && <h3>Modifica Paziente</h3>}   
        <form id="form_paziente"> 
           <Grid container spacing={0}>
          <Grid item xs={6}>
          <FormControl >    
          <TextField
            autoFocus
            required
            error={validate && cognome === ""}
            helperText={validate && cognome === "" ? 'Obbligatorio!' : ' '}
            margin="dense"
            id="cognome"
            name="cognome"
            value={cognome}
            label="Cognome"
            type="text"
            onChange={this.handleChange('cognome')}
          />
          </FormControl>
          </Grid>
          <Grid item xs={6}>
          <FormControl >    
          <TextField
            required
            margin="dense"
            id="nome"
            name="nome"
            value={nome}
            error={validate && nome === ""}
            helperText={validate && nome === "" ? 'Obbligatorio!' : ' '}
            label="Nome"
            type="text"
            onChange={this.handleChange('nome')}
          />
          </FormControl>
          </Grid>
          <Grid item xs={6}>
          <FormControl >    
          <KeyboardDatePicker 
                      disableFuture={true}
                      required
                      name="data_nascita"
                      label="Data nascita"
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      value={data_nascita || null}
                      onChange={(date) => {this.handleChangeDate(date)}}
                      error={validate && data_nascita === ""}
                      helperText={validate && data_nascita === "" ? 'Obbligatorio!' : ' '}
                      style={{ width: "90%" }}
                    />
          </FormControl>
          </Grid> 
          <Grid item xs={6}>
          <FormControl >    
          <TextField
            margin="dense"
            id="nic"
            name="nic"
            label="Codice fiscale"
            type="text"
            value={nic}
            inputProps={{
              maxLength: 16
            }}
            error={validate && invalidCF}
            helperText={validate && invalidCF ? 'Valore non valido!' : ' '}
            onChange={this.handleChange('nic')}
          />
          </FormControl>
          </Grid>  
          <Grid item xs={6}>
          <FormControl >    
          <TextField
            required
            margin="dense"
            id="cellulare"
            label="Cellulare"
            name="cellulare"
            value={cellulare}
            error={validate && cellulare === ""}
            helperText={validate && cellulare === "" ? 'Obbligatorio!' : ' '}
            type="text"
            onChange={this.handleChange('cellulare')}
          />
          </FormControl>
          </Grid>  
          <Grid item xs={6}>
          <FormControl >    
          <TextField
            required
            margin="dense"
            id="email"
            name="email"
            label="Email"
            type="email"
            error={validate && !this.validaMail(email, pin_operatore)}
            helperText={validate && !this.validaMail(email, pin_operatore) ? 'Inserire un indirizzo valido o lasciarlo vuoto e inserire il proprio PIN!' : ' '}
            value={email}
            onChange={this.handleChange('email')}
          />
          </FormControl>
          </Grid>
          
            <Grid item xs={6}>
              <FormControl >    
              <TextField
                margin="dense"
                id="citta_residenza_testo_libero"
                name="citta_residenza_testo_libero"
                label="Città"
                type="text"
                value={citta_residenza_testo_libero}
                onChange={this.handleChange('citta_residenza_testo_libero')}
              />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl >    
              <TextField
                margin="dense"
                id="cap"
                name="cap"
                label="CAP"
                type="text"
                value={cap}
                error={validate && (invalidCapLength || !capIsNum)}
                helperText={validate ? capErrorMsg : ' '}
                inputProps={{
                  maxLength: 5
                }}
                onChange={this.handleChange('cap')}
              />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl >    
              <TextField
                margin="dense"
                id="indirizzo_residenza"
                name="indirizzo_residenza"
                label="Indirizzo"
                type="text"
                value={indirizzo_residenza}
                onChange={this.handleChange('indirizzo_residenza')}
              />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl >
              <TextField
                margin="dense"
                name="pin_operatore"
                label="PIN operatore"
                error={this.errorPin()}
                helperText={this.helperPin()}
                type="text"
                value={pin_operatore}
                onChange={this.handleChange('pin_operatore')}
                autoComplete="off"
              />
              </FormControl>
            </Grid>
          
          </Grid> 
          </form>  
          
        <Grid container spacing={1}>
        <Grid item xs={6} align="left">
          <Button  variant="contained" onClick={this.editCancel} color="secondary">
            Annulla
          </Button>
        </Grid>   
          <Grid item xs={6} align="right"> 
          <Button  variant="contained" onClick={this.handleSave} color="primary">
            Salva
          </Button>
          </Grid>   
        </Grid>
          <Grid container spacing={1}><Grid item xs={12}>&nbsp;</Grid></Grid>
          {this.state.curr_paziente.id && <Grid container spacing={1}>
              <Grid item xs={6}><h3>Pazienti collegati</h3></Grid>
              <Grid item xs={6} align="right">
                <Fab size="small" variant="contained" onClick={this.toggleOpen.bind(this)} color="secondary">
                    <Add/>
                </Fab>
              </Grid></Grid>}
        {this.state.curr_paziente.id && this.state.pazienti_collegati.length == 0 && <div>nessun paziente collegato</div>}
        {this.state.curr_paziente.id && this.state.pazienti_collegati.length > 0 && <Table dense={false}>
          <TableHead>
                        <TableRow>
                          <TableCell>Paziente</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>
                            &nbsp;
                          </TableCell>
                        </TableRow>
                    </TableHead>
            <TableBody>
          {this.state.pazienti_collegati.map((item) => {
                      return (<TableRow>
                          <TableCell>{item.cognome} {item.nome}</TableCell>
                          <TableCell>{item.email || 'N.D'}</TableCell>
                          <TableCell align={'right'}>

                            <Tooltip title="rimuovi">
                              <Fab size="small" color="secondary" onClick={() => { this.removeCollegato(item) }}>
                                <DeleteIcon/>
                              </Fab>
                            </Tooltip>

                          </TableCell>
                      </TableRow>)
                    })
        }
            </TableBody>
        </Table>}
        {this.state.curr_paziente.id && <DialogPazienteCollegato curr_paziente_id={this.state.curr_paziente.id} open={this.state.add_collegato} toggleOpen={this.toggleOpen.bind(this)} setCollegato={this.addCollegato.bind(this)} />}
      </MuiPickersUtilsProvider>
    </Container>
  )}
}

export default withSnackbar(EditPaziente)
