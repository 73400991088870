import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import NumberFormat from 'react-number-format'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import api from '../api_calls/api'

class RiepilogoEseguiti extends Component {
    state = {
        saldi: undefined,
        load: false
    }

    loadSaldi() {
        if(this.state.load) {
            api.loadSaldi(this.props.paziente_id,
                (result) => {
                    this.setState({
                        saldi: result
                    })
                },
                (err) => {
                    console.log(JSON.stringify(err))
                })
        } else {
            this.setState({
                        saldi: undefined
            })
        }
    }

    componentDidMount() {
        this.loadSaldi()
    }

    componentDidUpdate(old) {
      if(old.paziente_id !== this.props.paziente_id) {
        this.loadSaldi()
      }
    }

    render() {
        
        const { load, saldi }  = this.state
        //alert(JSON.stringify(saldi))
    return(
        <div className="riepilogo"><hr/>
            {!load && <Button variant="outlined" color="primary" onClick={() => {this.setState({load: true}, () => {this.loadSaldi();});}}>Apri schema contabile</Button>}
            {load && <Button variant="outlined" color="primary" onClick={() => {this.setState({load: false}, () => {this.loadSaldi();});}}>Chiudi schema contabile</Button>}
            {load && !saldi && <h5>attendere...</h5>}
            {load && saldi && <Table dense={true}>
                  <TableRow>
                    <TableCell>
                        Pianificato
                    </TableCell>  
                    <TableCell align="right">
                       <NumberFormat value={saldi.pianificato} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                    <TableCell> </TableCell>
                    </TableRow> 
                    <TableRow>
                    <TableCell>
                        Eseguito
                    </TableCell> 
                    <TableCell align="right">
                       <NumberFormat value={saldi.eseguito} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                    <TableCell> </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>
                        Eseguito Fatturato
                    </TableCell> 
                    <TableCell align="right">
                       <NumberFormat value={saldi.eseguito_fatturato} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Eseguito da fatturare
                    </TableCell> 
                    {(saldi.eseguito_da_fatturare > 0) && <TableCell align="right" style={{color: "#A60000"}}>
                       <b><NumberFormat value={saldi.eseguito_da_fatturare} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} /></b>
                    </TableCell>}
                    {(saldi.eseguito_da_fatturare <= 0) && <TableCell align="right">
                        <NumberFormat value={saldi.eseguito_da_fatturare} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>}
                    <TableCell> 
                    
                    </TableCell>
                  </TableRow>
                  {(saldi.eseguito_non_fatturabile > 0) && <TableRow>
                    <TableCell style={{color: "#FFA500"}}>
                      Eseguito parziale (non fatturabile)
                    </TableCell>
                    <TableCell align="right" style={{color: "#FFA500"}}>
                       <b><NumberFormat value={saldi.eseguito_non_fatturabile} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} /></b>
                    </TableCell>
                    <TableCell>

                    </TableCell>
                  </TableRow>}
                    {false && saldi.fatturato_note_di_credito !== 0 && //
                    <TableRow>
                        <TableCell style={{color: "#A60000"}}>
                            Fatturato note di credito
                        </TableCell>
                        <TableCell style={{color: "#A60000"}} align="right">
                            <NumberFormat value={saldi.fatturato_note_di_credito} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                        </TableCell>
                        <TableCell>

                        </TableCell>
                    </TableRow>
                    }
                  <TableRow>
                  <TableCell>
                        Incassato in acconto (caparra)
                    </TableCell> 
                    <TableCell align="right">
                       <NumberFormat value={saldi.incassato_in_acconto} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                    <TableCell> 
                    
                    </TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell>
                        Incassato come eseguito
                    </TableCell> 
                    <TableCell align="right">
                       <NumberFormat value={saldi.incassato_come_eseguito} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                    <TableCell> 
                    
                    </TableCell>
                  </TableRow>
                    {saldi.incasso_note_di_credito !== 0 &&
                    <TableRow>
                        <TableCell style={{color: "#A60000"}}>
                            Incasso note di credito
                        </TableCell>
                        <TableCell style={{color: "#A60000"}} align="right">
                            <NumberFormat value={saldi.incasso_note_di_credito} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                        </TableCell>
                        <TableCell>

                        </TableCell>
                    </TableRow>
                    }
                  <TableRow>
                  <TableCell>
                        Totale incassato
                    </TableCell> 
                    <TableCell align="right">
                       <NumberFormat value={saldi.totale_incassato} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                    <TableCell> 
                    
                    </TableCell>
                  </TableRow>
                  {(saldi.abbuoni !== undefined && saldi.abbuoni > 0) && <TableRow>
                    <TableCell>
                        Abbuoni
                    </TableCell>
                    <TableCell align="right">
                       <NumberFormat value={saldi.abbuoni} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                    <TableCell> </TableCell>
                    </TableRow>}
                  <TableRow>
                  <TableCell  className="green">
                      Fondo disponibile (per i successivi trattamenti)
                    </TableCell> 
                    {(saldi.fondo_disponibile > 0) && <TableCell align="right" style={{color: "#008500"}}>
                       <b><NumberFormat value={saldi.fondo_disponibile} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} /></b>
                    </TableCell>}
                    {(saldi.fondo_disponibile === 0) && <TableCell align="right">
                       <NumberFormat value={0} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>}
                    {(saldi.fondo_disponibile < 0) && <TableCell align="right" style={{color: "#A60000"}}>
                       <b><NumberFormat value={0} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} /></b>
                    </TableCell>}
                    <TableCell> 
                    
                    </TableCell>
                  </TableRow>
                {saldi.residuo_altre_fatture !== undefined && saldi.residuo_altre_fatture > 0 &&
                    <TableRow>
                    <TableCell  className="green" style={{color: "#FFA500"}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;<small>Di cui, associati a piani di cura non attivi</small>
                    </TableCell>
                    <TableCell align="right" style={{color: "#FFA500"}}>
                       <small><b><NumberFormat value={saldi.residuo_altre_fatture} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={''} /></b></small>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                  </TableRow>
                }


                {saldi.incassi_a_scadenza_paziente > 0 &&
                  <TableRow>
                  <TableCell>
                        Incassi a scadenza paziente
                    </TableCell>
                    <TableCell align="right">
                       <NumberFormat value={saldi.incassi_a_scadenza_paziente} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                    <TableCell>

                    </TableCell>
                  </TableRow>
                }
                {saldi.incassi_scaduti_paziente > 0 &&
                <TableRow>
                  <TableCell style={{color: "#A60000"}}>
                    Incassi scaduti paziente
                  </TableCell>
                    <TableCell align="right" style={{color: "#A60000"}}>
                       <NumberFormat value={saldi.incassi_scaduti_paziente} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                    <TableCell></TableCell>
                </TableRow>
                }

                {saldi.incassi_a_scadenza_assicurazione > 0 &&
                  <TableRow>
                  <TableCell>
                        Incassi a scadenza assicurazione
                    </TableCell>
                    <TableCell align="right" style={{color: "#008500"}}>
                       <NumberFormat value={saldi.incassi_a_scadenza_assicurazione} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                    <TableCell>

                    </TableCell>
                  </TableRow>
                }
                {saldi.incassi_scaduti_assicurazione > 0 &&
                <TableRow>
                  <TableCell style={{color: "#A60000"}}>
                    Incassi scaduti assicurazione
                  </TableCell>
                    <TableCell align="right" style={{color: "#A60000"}}>
                       <NumberFormat value={saldi.incassi_scaduti_assicurazione} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                    <TableCell>

                    </TableCell>
                </TableRow>
                }




                  <TableRow>
                  <TableCell>
                        Contributi
                    </TableCell> 
                    <TableCell align="right">
                       <NumberFormat value={saldi.contributi} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                    <TableCell> 
                    
                    </TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell>
                        Bolli
                    </TableCell> 
                    <TableCell align="right">
                       <NumberFormat value={saldi.bolli} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                    <TableCell> 
                    
                    </TableCell>
                  </TableRow>

                  
                  <TableRow>
                  <TableCell  className="red">
                        Da incassare
                    </TableCell> 
                    {(saldi.da_incassare > 0) && <TableCell align="right" style={{color: "#A60000"}}>
                       <b><NumberFormat value={saldi.da_incassare} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} /></b>
                    </TableCell>}
                    {(saldi.da_incassare <= 0) && <TableCell align="right">
                       <NumberFormat value={0} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>}
                    <TableCell> 
                    
                    </TableCell>
                  </TableRow>
                  {saldi.fatture_non_associate > 0 && <TableRow>
                  <TableCell style={{color: "#A60000"}}>
                  <b>Fatture non associate a piani di cure</b>
                  </TableCell> 
                  <TableCell align="right" style={{color: "#A60000"}}>
                      <b><NumberFormat value={saldi.fatture_non_associate} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} /></b>
                  </TableCell>
                  <TableCell> 
              
                  </TableCell>
                </TableRow>}
                {saldi.sopravvenienze_attive !== undefined && saldi.sopravvenienze_attive > 0 &&
                    <TableRow>
                    <TableCell  className="green" style={{color: "#FFA500"}}>
                        <b>Sopravvenienze attive</b>
                    </TableCell>
                    <TableCell align="right" style={{color: "#FFA500"}}>
                       <b><NumberFormat value={saldi.sopravvenienze_attive} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} /></b>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                  </TableRow>
                }
                  
              </Table>}
        </div>)
    }
      

}
export default RiepilogoEseguiti
