const calc = {
    sommaArray: (valori) => {
        const reducer = (accumulator, currentValue) => accumulator + currentValue;
        return valori.reduce(reducer)
    },
    ripartisciSconto: (prezzo, scontoTotale, prezzoTotale) => {
          prezzo = Number(prezzo)
          scontoTotale = Number(scontoTotale)
          prezzoTotale = Number(prezzoTotale)
          const res = prezzo * scontoTotale / prezzoTotale
          return Number(res.toFixed(2))
    },
    getSconti: (trattamenti, scontoTot) => {
        let scontoFatturati = 0.0
        trattamenti.map((item, idx) => {
          if (item.include_fatturati) {
            scontoFatturati += Number(item.prezzo_base-item.prezzo_scontato)
          }
        })
        scontoTot = Number(scontoTot) - scontoFatturati
        let prezzoTot = 0.0
        trattamenti.map((item, idx) => {
          if (!item.include_fatturati) {
            prezzoTot += Number(item.prezzo_base)
          }
        })
        let sconti = trattamenti.map((item, idx) => {
          if (item.include_fatturati) {
            return Number(item.prezzo_base-item.prezzo_scontato);
          } else {
            return calc.ripartisciSconto(item.prezzo_base, scontoTot, prezzoTot)
          }
        })
        let sommaSconti = calc.sommaArray(sconti)
        if(sommaSconti !== scontoTot + scontoFatturati) {
            let altri = sconti.slice()
            altri.splice(-1,1)
            console.log("ALTRI: " + JSON.stringify(altri))
            sconti[sconti.length - 1] = scontoTot - calc.sommaArray(altri)
        }
        return sconti
    },
    setPrezziScontati: (trattamenti, scontoTot) => {
        scontoTot = Number(scontoTot)
        const sconti = calc.getSconti(trattamenti, scontoTot)
        trattamenti.map((item, idx) => {
            const ps = Number(item.prezzo_base) - sconti[idx]
            item.prezzo_scontato = Number(ps.toFixed(2))
        })
    } 
}
export default calc
