import React, { Component } from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
//import DialogPaziente from './dialogs/DialogPaziente'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import NumberFormat from 'react-number-format'
import { BrowserRouter as Router, Route, Link as RouterLink } from "react-router-dom"
import { Link } from '@material-ui/core'
import {Add, Edit, EuroSymbol, OndemandVideo} from '@material-ui/icons'
import Container from '@material-ui/core/Container'
import api from "../api_calls/api"
import PreviewPdf from "../components/PreviewPdf"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withSnackbar } from 'notistack'
import withConfirm from 'material-ui-confirm'
import MomentUtils from '@date-io/moment'
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import {SingleSelect} from "react-select-material-ui";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from "moment";



const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    }, 
      palette: {
        primary: {
          light: '#0000',
          main: '#00000',
          dark: '#0000',
          contrastText: '#fff',
        },
        secondary: {
          light: '#ff7961',
          main: '#f44336',
          dark: '#ba000d',
          contrastText: '#000',
        },
      },
}));






function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

class DatiSede extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      logged_in: localStorage.getItem('token') ? true : false,
      operatore: api.getOperatore(),
      sede_corrente: {
        id: localStorage.getItem('sede_corrente_id'),
        nome_breve: localStorage.getItem('sede_corrente_nome_breve'),
        nome: localStorage.getItem('sede_corrente_nome')
      },
      fatturato: [],
      fatturato_da_incassare: [],
      lista_criticita: [],
      criticita: undefined,
      lista_ripetibilita: [],
      ripetibilita: undefined,
      messaggioRipetibilita: '',
      messaggi: [],
      messaggio: {
        tipo: '1',
        testo: ''
      },
      la_sede: parseInt(localStorage.getItem('sede_corrente_id')),
      la_sede_sc: parseInt(localStorage.getItem('sede_corrente_id')),
      la_sede_pdc: parseInt(localStorage.getItem('sede_corrente_id')),
      il_mese: undefined,
      il_mese_sc1: undefined,
      il_mese_sc2: undefined,
      il_mese_pdc: undefined,
    } 
  }
  
  componentDidMount() {
      this.readFatturatoSede();
      this.readFattureDaIncassareSede();
      this.readCriticita();
      this.readRipetibilita();
  }

  readCriticita() {
    const url = `${process.env.REACT_APP_API_URL}/dc/criticita`
    api.call_get(url, (result) => {
      this.setState({
        lista_criticita: result.results,
      });
      this.readMessaggi();
    })
  }

  readRipetibilita() {
    const url = `${process.env.REACT_APP_API_URL}/dc/stato_ripetibilita`
    this.setState({
        lista_ripetibilita: [],
      });
    api.call_get(url, (result) => {
      this.setState({
        lista_ripetibilita: result.results,
      });
    })
  }

  readMessaggi() {
    if(this.state.criticita !== undefined) {
      const url = `${process.env.REACT_APP_API_URL}/dc/messaggi_list/${this.state.criticita.id}`
      api.call_get(url, (result) => {
        this.setState({
          messaggi: result.results,
        });
      })
    } else {
      this.setState({
          messaggi: [],
        });
    }
  }

  setCriticita(criticita) {
    this.setState({
        criticita: criticita,
      }, this.readMessaggi);
  }

  setRipetibilita(ripetibilita) {
    this.setState({
        ripetibilita: ripetibilita,
      }, );
  }

  setTestoMessaggio(value) {
    this.setState({
      messaggio: {tipo: this.state.messaggio.tipo, testo: value}
    });
  }

  setTestoMessaggioRipetibilita(value) {
    this.setState({
      messaggioRipetibilita: value
    });
  }

  setTipoMessaggio(value) {
    this.setState({
      messaggio: {tipo: value, testo: this.state.messaggio.testo}
    });
  }

  get_mesi() {
      const oggi = new Date();
      let mesi = [];
      for (let k=0; k < 36; ++k) {
          const currd = new Date(oggi.getFullYear(), oggi.getMonth()-k, 1);
          mesi.push({
            value: moment(currd).format('YYYYMMDD'),
            label: moment(currd).format('MM YYYY')
          });
      }
      return mesi;
  }

  get_mesi_scad() {
      const oggi = new Date();
      let mesi = [];
      for (let k=0; k < 36; ++k) {
          const currd = new Date(oggi.getFullYear(), oggi.getMonth()-k, 1);
          mesi.push({
            value: currd,
            label: moment(currd).format('MM YYYY')
          });
      }
      return mesi;
  }


    cancellaMessaggio(id) {
    const url = `${process.env.REACT_APP_API_URL}/dc/messaggi_delete/` + id
    api.call_post(url, undefined, (res) => {
            this.props.enqueueSnackbar(`Messaggio cancellato`, {variant: 'success', autoHideDuration: 4000});
            this.setState({
              messaggio: {tipo: '1', testo: ''}
            }, this.readMessaggi);
          }, (err) => {
            let msg = ""
            try {
              msg = JSON.stringify(err.response.data)
            } catch (e) {
              msg = JSON.stringify(e)
            }
            this.props.enqueueSnackbar(`Errore invio messaggio: ${msg}`, {
              variant: 'error',
              autoHideDuration: 4000
            })
          }, 'DELETE');
  }

  componentDidUpdate(old) {
    //this.readFatturatoSede()
  }

  readFatturatoSede() { 
    const operatore = api.getOperatore()
    const sede_corrente = {
      id: localStorage.getItem('sede_corrente_id'),
      nome_breve: localStorage.getItem('sede_corrente_nome_breve'),
      nome: localStorage.getItem('sede_corrente_nome')
    } 
    const url_fatturato = `${process.env.REACT_APP_API_URL}/dc/fatturato_sede/${sede_corrente.id}`
    api.call_get(url_fatturato, (result) => {
        this.setState({
        fatturato: result,
        operatore: operatore,
        sede_corrente: sede_corrente
      })
    })  
  }

  readFattureDaIncassareSede() {
    const operatore = api.getOperatore()
    const sede_corrente = {
      id: localStorage.getItem('sede_corrente_id'),
      nome_breve: localStorage.getItem('sede_corrente_nome_breve'),
      nome: localStorage.getItem('sede_corrente_nome')
    }
    const url_fatturato = `${process.env.REACT_APP_API_URL}/dc/fatture_da_incassare_sede/${sede_corrente.id}`
    api.call_get(url_fatturato, (result) => {
        this.setState({
        fatturato_da_incassare: result
      })
    })
  }

  strToDate(str) {
      try {
          const dt = str.split('T')[0];
          const parts = dt.split('-');
          return parts[2] + '/' + parts[1] + '/' + parts[0];
      } catch (e) {
          return '--';
      }
  }

   inviaMessaggio() {
    const url = `${process.env.REACT_APP_API_URL}/dc/messaggi/`
    let messaggio = {...this.state.messaggio}
    messaggio.criticita = this.state.criticita.id
    api.call_post(url, messaggio, (res) => {
            this.props.enqueueSnackbar(`Messaggio inviato`, {variant: 'success', autoHideDuration: 4000});
            this.setState({
              messaggio: {tipo: '1', testo: ''}
            }, this.readMessaggi);
          }, (err) => {
            let msg = ""
            try {
              msg = JSON.stringify(err.response.data)
            } catch (e) {
              msg = JSON.stringify(e)
            }
            this.props.enqueueSnackbar(`Errore invio messaggio: ${msg}`, {
              variant: 'error',
              autoHideDuration: 4000
            })
          });
  }


  modificaRipetibilita(richiesta) {
    const { ripetibilita } = this.state;
    const { messaggioRipetibilita } = this.state;
    const url = `${process.env.REACT_APP_API_URL}/dc/ripetibilita/`
    ripetibilita.messaggioRipetibilita = messaggioRipetibilita
    ripetibilita.richiesta = richiesta
    api.call_post(url, ripetibilita, (res) => {
      this.props.enqueueSnackbar(`Stato ripetibilità aggiornato.`, {variant: 'success', autoHideDuration: 4000});
      // aggiornare il la voce di ripetibilità nella lista e visualizzare la lista
      this.ripetibilitaElenco()
    }, (err) => {
      let msg = ""
      try {
        msg = JSON.stringify(err.response.data)
      } catch (e) {
        msg = JSON.stringify(e)
      }
      this.props.enqueueSnackbar(`Errore aggiornando lo stato della ripetibilità: ${msg}`, {
        variant: 'error',
        autoHideDuration: 6000
      })
    });
  }

  richiediAutorizzazione() {
    this.modificaRipetibilita('autorizzazione');
  }

  pazienteHaSospeso() {
    this.modificaRipetibilita('sospeso');
  }

  dichiaraCompleta() {
    this.modificaRipetibilita('completa');
  }

  ripetibilitaElenco() {
    this.setState({
        ripetibilita: undefined,
      }, this.readRipetibilita);
  }

  setIlMese(val) {
      this.setState({
         il_mese: val
      });
  }

  setIlMesePdc(val) {
      this.setState({
         il_mese_pdc: val
      });
  }

  setLaSede(val) {
      this.setState({
         la_sede: val
      });
  }

  setLaSedePdc(val) {
      this.setState({
         la_sede_pdc: val
      });
  }

  setLaSedeSc(val) {
      this.setState({
         la_sede_sc: val
      });
  }

  setIlMeseSc1(val) {
      let sc2 = val;
      if (this.state.il_mese_sc2 && this.state.il_mese_sc2 > val) {
          sc2 = this.state.il_mese_sc2;
      }
      this.setState({
         il_mese_sc1: val,
         il_mese_sc2: sc2
      });
  }

  setIlMeseSc2(val) {
      if (this.state.il_mese_sc1 && val < this.state.il_mese_sc1) {
          this.setState({
              il_mese_sc2: this.state.il_mese_sc1
          });
      } else {
          this.setState({
              il_mese_sc2: val
          });
      }
  }
  
  render() {
    const { operatore } = this.state
    const { sede_corrente } = this.state
    const { fatturato } = this.state
    const { fatturato_da_incassare } = this.state
    const backend_version = localStorage.getItem('backend_version');
    const { criticita, messaggio, messaggi } = this.state;
    const { ripetibilita, messaggioRipetibilita } = this.state;
    const moment = new MomentUtils();

    const lista_criticita_sbloccotmp = this.state.lista_criticita.filter((el) => { return el.stato == 3 });
    const lista_criticita_attive = this.state.lista_criticita.filter((el) => { return el.stato == 1 });
    const lista_criticita_scadenza = this.state.lista_criticita.filter((el) => { return el.stato == 0 });
    const lista_criticita_riesame = this.state.lista_criticita.filter((el) => { return el.stato == 5 });
    const lista_criticita_sistemate = this.state.lista_criticita.filter((el) => { return el.stato == 7 });

    const le_liste= [
        lista_criticita_attive,
        lista_criticita_riesame,
        lista_criticita_sistemate,
        lista_criticita_scadenza,
        lista_criticita_sbloccotmp
    ];


    const la_sede = this.state.la_sede;
    const la_sede_pdc = this.state.la_sede_pdc;
    const la_sede_sc = this.state.la_sede_sc;
    let il_mese = this.state.il_mese;
    if(il_mese === undefined) {
        il_mese = this.get_mesi()[0].value
    }

    let il_mese_pdc = this.state.il_mese_pdc;
    if(il_mese_pdc === undefined) {
        il_mese_pdc = this.get_mesi()[0].value
    }

    let il_mese_sc1 = this.state.il_mese_sc1;
    if(il_mese_sc1 === undefined) {
        il_mese_sc1 = this.get_mesi_scad()[0].value
    }

    let il_mese_sc2 = this.state.il_mese_sc2;
    if(il_mese_sc2 === undefined) {
        il_mese_sc2 = this.get_mesi_scad()[0].value
    }

    const mese_da = (il_mese_sc1.getMonth() + 1) + "-" + il_mese_sc1.getFullYear();
    const mese_a = (il_mese_sc2.getMonth() + 1) + "-" + il_mese_sc2.getFullYear();

    return (
      <Container component="main" maxWidth="md">
          <Grid container >
              <Grid item xs={6}>
                <h3>Sede {sede_corrente.nome}</h3>
              </Grid>
              <Grid item xs={6}>
                &nbsp;
              </Grid>


              <Grid item xs={6}>
                <h3>Rapporto disponibilità</h3>
                      {this.state.operatore.sedi.map((item, index) => {
                        const url_rapporto_disponibilita = ` ${process.env.REACT_APP_API_URL}/dc/disponibilita_sede/${item.id}`;
                        return (
                          <h5><a target="_blank" href={url_rapporto_disponibilita}>{item.nome}</a></h5>
                        )
                      })
                      }
              </Grid>
              <Grid item xs={6}>
                <h5><OndemandVideo/> Video di presentazione e tutorial</h5>
                  <ul>
                      <li><a href='https://www.youtube.com/watch?v=MIfL4oIlJko' target='_blank'>impostazione PIN operatore</a></li>
                      <li><a href='https://youtu.be/Zne3F4rBafk' target='_blank'>modulo incassi giornalieri (per assistenti)</a></li>
                      <li><a href='https://youtu.be/kNWOqSVdXlE' target='_blank'>modulo incassi giornalieri (per amministratori)</a></li>
                      <li><a href='https://youtu.be/GAOnElSvZiI' target='_blank'>consensi e documenti primo accesso</a></li>
                      <li><a href='https://youtu.be/xhn_SeWI5mg' target='_blank'>gestione blocchi</a></li>
                      <li><a href='https://youtu.be/lJ-mi_o6Gws' target='_blank'>aggiungere un operatore</a></li>
                  </ul>
              </Grid>
              {(fatturato.length == 0 || fatturato_da_incassare.length == 0) && <Grid item xs={12}>
                      <h3><CircularProgress /> carico dati fatturazione...</h3>
              </Grid>}
              {fatturato.length > 0 && fatturato_da_incassare.length > 0 && <Grid item xs={5}>
                  <h3>Fatturato mensile</h3>
                  <br/><i><small>(i dati si aggiornano ogni 4 ore)</small></i>
                  <Table dense={false}>
                      <TableHead>
                        <TableCell>Mese</TableCell>
                        <TableCell align="right">Fatturato nel mese</TableCell>
                        <TableCell align="right">Incassato nel mese</TableCell>
                      </TableHead>
                      <TableBody>
                      {fatturato.map((item, idx) => {
                          let cstyle={}
                          if(idx === 0) {
                            cstyle={color: "#008500"}
                          }
                        return (
                        <TableRow>
                          <TableCell style={cstyle}>
                              {item.mese}
                          </TableCell>
                          <TableCell style={cstyle} align="right">
                            <NumberFormat
                                value={Number(item.fatturato)}
                                displayType={'text'}
                                fixedDecimalScale={2}
                                decimalScale={2}
                                decimalSeparator=','
                                thousandSeparator='.'
                                suffix={' EUR'}
                                />
                          </TableCell>
                          <TableCell style={cstyle} align="right">
                            <NumberFormat
                                value={Number(item.incassato)}
                                displayType={'text'}
                                fixedDecimalScale={2}
                                decimalScale={2}
                                decimalSeparator=','
                                thousandSeparator='.'
                                suffix={' EUR'}
                                />
                          </TableCell>
                      </TableRow>)})
                      }
                      </TableBody>
                  </Table>
              </Grid>}


              {fatturato.length > 0 && fatturato_da_incassare.length > 0 && <Grid item xs={7} style={{"padding-left": "30px"}}>
                <Grid container>
                  <Grid item xs={6}><h3>Fatture da incassare</h3><br/><i><small>(i dati si aggiornano ogni 4 ore)</small></i></Grid>
                  <Grid item xs={5} style={{"padding-left": "20px", "padding-top": "15px"}}><form method="GET" action={`${process.env.REACT_APP_API_URL}/dc/report_da_incassare_sede/${sede_corrente.id}`}>
                            <Button variant="contained" type="submit" size="small" color="primary">
                                Scarica Dettaglio
                            </Button>
                  </form></Grid>
                </Grid>
                
                  
                  <Table dense={false}>
                      <TableHead>
                        <TableCell>Mese</TableCell>
                        <TableCell align="right">Fatturato aperte</TableCell>
                        <TableCell align="right">Incassato aperte</TableCell>
                        <TableCell align="right">Da incassare</TableCell>
                      </TableHead>
                      <TableBody>
                      {fatturato_da_incassare.map((item, idx) => {
                          let cstyle={}
                          if(idx === 0) {
                            cstyle={color: "#008500"}
                          }
                        return (
                        <TableRow>
                          <TableCell style={cstyle}>
                              {item.mese}
                          </TableCell>
                          <TableCell style={cstyle} align="right">
                            <NumberFormat
                                value={Number(item.fatturato)}
                                displayType={'text'}
                                fixedDecimalScale={2}
                                decimalScale={2}
                                decimalSeparator=','
                                thousandSeparator='.'
                                suffix={' EUR'}
                                />
                          </TableCell>
                          <TableCell style={cstyle} align="right">
                            <NumberFormat
                                value={Number(item.incassato)}
                                displayType={'text'}
                                fixedDecimalScale={2}
                                decimalScale={2}
                                decimalSeparator=','
                                thousandSeparator='.'
                                suffix={' EUR'}
                                />
                          </TableCell>
                            <TableCell style={cstyle} align="right">
                            <NumberFormat
                                value={Number(item.da_incassare)}
                                displayType={'text'}
                                fixedDecimalScale={2}
                                decimalScale={2}
                                decimalSeparator=','
                                thousandSeparator='.'
                                suffix={' EUR'}
                                />
                          </TableCell>
                      </TableRow>)})
                      }
                      </TableBody>
                  </Table>
              </Grid>}

              <Grid item xs={6} style={{padding: "10px"}}>
                  <h3>Report incassi a scadenza</h3>
                  <form method="POST" target="_rep" action={`${process.env.REACT_APP_API_URL}/dc/esporta_scadenze_fe/`}>
                  <FormControl style={{"padding-left": "30px", "padding-top": "8px"}}>
                        <SingleSelect
                                  label={'Sede'}
                                  style={{width: 300}}
                                  value={la_sede_sc}
                                  defaltValue={la_sede_sc}
                                  options={this.state.operatore.sedi.map((sede, indexidx) => {
                                      return {value: sede.id, label: sede.nome};
                                  })}
                                  onChange={(val)=> {this.setLaSedeSc(val)}}
                                  />
                  </FormControl><br/>
                  <FormControl style={{"padding-left": "30px", "padding-top": "8px"}}>
                        <SingleSelect
                                  label={'Mese inizio'}
                                  style={{width: 300}}
                                  value={il_mese_sc1}
                                  defaltValue={il_mese_sc1}
                                  options={this.get_mesi_scad()}
                                  onChange={(val)=> {this.setIlMeseSc1(val)}}
                                  />
                  </FormControl><br/>
                  <FormControl style={{"padding-left": "30px", "padding-top": "8px"}}>
                        <SingleSelect
                                  label={'Mese fine'}
                                  style={{width: 300}}
                                  value={il_mese_sc2}
                                  defaltValue={il_mese_sc2}
                                  options={this.get_mesi_scad()}
                                  onChange={(val)=> {this.setIlMeseSc2(val)}}
                                  />
                  </FormControl><br/>
                  <FormControl style={{"text-align": "right", "padding-top": "8px", "padding-left": "190px"}}>
                    <Tooltip title="genera report">
                              <Button variant="contained" type="submit" size="small" color="primary">
                                Genera report
                              </Button>
                    </Tooltip>
                  </FormControl>
                      <input type={"hidden"} name={"user_id"} value={this.state.operatore.id} />
                      <input type={"hidden"} name={"mese_da"} value={mese_da} />
                      <input type={"hidden"} name={"mese_a"} value={mese_a} />
                      <input type={"hidden"} name={"sede"} value={la_sede_sc} />
                 </form>
              </Grid>
              <Grid item xs={6} style={{padding: "10px"}}>
                  <h3>Report appuntamenti per sede</h3>
                  <form method="POST" action={`${process.env.REACT_APP_API_URL}/dc/report_appuntamenti_sede/`}>
                  <FormControl style={{"padding-left": "30px", "padding-top": "8px"}}>
                        <SingleSelect
                                  label={'Sede'}
                                  style={{width: 300}}
                                  value={la_sede}
                                  defaltValue={la_sede}
                                  options={this.state.operatore.sedi.map((sede, indexidx) => {
                                      return {value: sede.id, label: sede.nome};
                                  })}
                                  onChange={(val)=> {this.setLaSede(val)}}
                                  />
                  </FormControl><br/>
                  <FormControl style={{"padding-left": "30px", "padding-top": "8px"}}>
                        <SingleSelect
                                  label={'Mese'}
                                  style={{width: 300}}
                                  value={il_mese}
                                  defaltValue={il_mese}
                                  options={this.get_mesi()}
                                  onChange={(val)=> {this.setIlMese(val)}}
                                  />
                  </FormControl><br/>
                  <FormControl style={{"text-align": "right", "padding-top": "8px", "padding-left": "190px"}}>
                    <Tooltip title="genera report">
                              <Button variant="contained" type="submit" size="small" color="primary">
                                Genera report
                              </Button>
                    </Tooltip>
                  </FormControl>
                      <input type={"hidden"} name={"mese"} value={il_mese} />
                      <input type={"hidden"} name={"sede_id"} value={la_sede} />
                 </form>
              </Grid>

              

              <Grid item xs={6} style={{padding: "10px"}}>
                  <h3>Report piani di cura per sede</h3>
                  <form method="POST" action={`${process.env.REACT_APP_API_URL}/dc/report_pdc_sede/`}>
                  <FormControl style={{"padding-left": "30px", "padding-top": "8px"}}>
                        <SingleSelect
                                  label={'Sede'}
                                  style={{width: 300}}
                                  value={la_sede_pdc}
                                  defaltValue={la_sede_pdc}
                                  options={this.state.operatore.sedi.map((sede, indexidx) => {
                                      return {value: sede.id, label: sede.nome};
                                  })}
                                  onChange={(val)=> {this.setLaSedePdc(val)}}
                                  />
                  </FormControl><br/>
                  <FormControl style={{"padding-left": "30px", "padding-top": "8px"}}>
                        <SingleSelect
                                  label={'Mese'}
                                  style={{width: 300}}
                                  value={il_mese_pdc}
                                  defaltValue={il_mese_pdc}
                                  options={this.get_mesi()}
                                  onChange={(val)=> {this.setIlMesePdc(val)}}
                                  />
                  </FormControl><br/>
                  <FormControl style={{"text-align": "right", "padding-top": "8px", "padding-left": "190px"}}>
                    <Tooltip title="genera report">
                              <Button variant="contained" type="submit" size="small" color="primary">
                                Genera report
                              </Button>
                    </Tooltip>
                  </FormControl>
                      <input type={"hidden"} name={"mese"} value={il_mese_pdc} />
                      <input type={"hidden"} name={"sede_id"} value={la_sede_pdc} />
                 </form>
              </Grid>
              {le_liste.map((lista_cr) => {
               if (criticita === undefined && lista_cr !== undefined && lista_cr.length > 0) {
                   let curr_tipo = "";
                   switch (lista_cr[0].stato) {
                    case 0:
                        curr_tipo = "IN SCADENZA"
                        break;
                    case 1:
                        curr_tipo = "ATTIVE"
                        break;
                    case 3:
                        curr_tipo = "SBLOCCATE TEMPORANEAMENTE"
                        break;
                    case 5:
                        curr_tipo = "PIANI DI CURA DA RIFARE A MANO"
                        break;
                    case 7:
                        curr_tipo = "SISTEMATA DA EMETTERE FATTURA A SALDO"
                        break;
                    default:
                        curr_tipo = "";
                    };

                   return (<Grid item xs={12}>
                       <h3>Criticità {curr_tipo}:</h3>
                       <Table dense={false}>
                           <TableHead>
                               <TableRow>
                                   <TableCell>Paziente</TableCell>
                                   <TableCell>Criticità</TableCell>
                                   <TableCell>Blocco</TableCell>
                                   <TableCell>Azione richiesta</TableCell>
                                   <TableCell>Tel.</TableCell>
                                   <TableCell>
                                       &nbsp;
                                   </TableCell>
                               </TableRow>
                           </TableHead>
                           <TableBody>
                               {lista_cr.map((item) => {
                                   return (<TableRow>
                                       <TableCell>{item.paziente.cognome} {item.paziente.nome}</TableCell>
                                       <TableCell>{item.motivo.nome}</TableCell>
                                       <TableCell>{this.strToDate(item.blocco)}</TableCell>
                                       <TableCell dangerouslySetInnerHTML={{ __html: item.descrizione }} />
                                       <TableCell>{item.paziente.cellulare || item.paziente.telefono}</TableCell>

                                       <TableCell>
                                           {item.descrizione.indexOf("AZIONE DA COMPIERE") < 0 &&
                                           <Tooltip title="dettagli criticità">
                                               <Button variant="contained" size="small" color="secondary"
                                                       onClick={() => {
                                                           this.setCriticita(item)
                                                       }}>
                                                   Dettagli
                                               </Button>
                                           </Tooltip>}
                                       </TableCell>
                                       <TableCell>
                                           {item.descrizione.indexOf("AZIONE DA COMPIERE") >= 0 &&
                                           <div>SEGUIRE LE ISTRUZIONI IN "AZIONE RICHIESTA"</div>
                                           }
                                           {item.descrizione.indexOf("AZIONE DA COMPIERE") < 0  &&
                                           <Tooltip title="risolvi criticità">
                                               <Button variant="contained" size="small" color="primary"
                                                       href={"/gui/pazienti/?pid=" + item.paziente.id}>
                                                   Risolvi
                                               </Button>
                                           </Tooltip>}
                                       </TableCell>
                                   </TableRow>)
                               })
                               }
                           </TableBody>
                       </Table>
                   </Grid>);
               }
            })
              }


                {this.state.criticita === undefined && this.state.lista_criticita_scad !== undefined && this.state.lista_criticita_scad.length > 0 && <Grid item xs={12}>
                  <h3>Criticità in scadenza:</h3>
                <Table dense={false}>
                    <TableHead>
                        <TableRow>
                          <TableCell>Paziente</TableCell>
                          <TableCell>Criticità</TableCell>
                          <TableCell>Blocco</TableCell>
                          <TableCell>Azione richiesta</TableCell>
                          <TableCell>Tel.</TableCell>
                          <TableCell>
                          &nbsp;
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.lista_criticita_scad.map((item) => {
                      return (<><TableRow>
                          <TableCell>{item.paziente.cognome} {item.paziente.nome}</TableCell>
                          <TableCell>{item.motivo.nome}</TableCell>
                           <TableCell>{this.strToDate(item.blocco)}</TableCell>
                          <TableCell dangerouslySetInnerHTML={{ __html: item.descrizione }} />
                          <TableCell>{item.paziente.cellulare || item.paziente.telefono}</TableCell>

                          <TableCell>
                              {item.descrizione.indexOf("AZIONE DA COMPIERE") < 0  &&
                            <Tooltip title="dettagli criticità">
                              <Button variant="contained" size="small" color="secondary" onClick={() => { this.setCriticita(item) }}>
                                Dettagli
                              </Button>
                            </Tooltip>}
                          </TableCell>
                          <TableCell>
                            {item.descrizione.indexOf("AZIONE DA COMPIERE") >= 0 &&
                                           <div>SEGUIRE LE ISTRUZIONI IN "AZIONE RICHIESTA"</div>
                                           }
                             {item.descrizione.indexOf("AZIONE DA COMPIERE") < 0  &&
                            <Tooltip title="risolvi criticità">
                              <Button variant="contained" size="small" color="primary" href={"/gui/pazienti/?pid=" + item.paziente.id}>
                                Risolvi
                              </Button>
                            </Tooltip>}
                          </TableCell>
                      </TableRow>
                      {item.messaggi && item.messaggi.map((msg) => {
                            return (
                                <TableRow>
                                    <TableCell>&nbsp;</TableCell>
                                    <TableCell>{msg.user?.first_name} {msg.user?.last_name}</TableCell>
                                    <TableCell>
                                        {msg.tipo === 1 && <span>Richiesta sblocco</span>}
                                        {msg.tipo !== 1 && <span>Messaggio</span>}
                                    </TableCell>
                                    <TableCell colspan={4}>{msg.testo}</TableCell>
                                </TableRow>)
                      })}
                      </>)
                    })
                    }
                    </TableBody>
                  </Table>
              </Grid>}
              {criticita !== undefined && <Grid item xs={12}>
                <h3>Criticità:</h3>
                <Grid container >
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Tooltip title="lista criticità">
                    <Button variant="contained" size="small" color="secondary" onClick={() => { this.setCriticita(undefined) }}>
                      Report Criticità
                    </Button>
                  </Tooltip>&nbsp;&nbsp;&nbsp;
                  <Tooltip title="risolvi criticità">
                    <Button variant="contained" size="small" color="primary" href={"/gui/pazienti/?pid=" + criticita.paziente.id}>
                      Risolvi
                    </Button>
                  </Tooltip>
                </Grid>
                </Grid>
                <Table dense={false}>
                  <TableRow>
                          <TableCell>Paziente</TableCell>
                          <TableCell>{criticita.paziente.cognome} {criticita.paziente.nome} (tel. {criticita.paziente.cellulare || criticita.paziente.telefono})</TableCell>
                  </TableRow>
                  <TableRow>
                          <TableCell>Criticità</TableCell>
                          <TableCell>{criticita.motivo.nome}</TableCell>
                  </TableRow>
                  <TableRow>
                          <TableCell>Azione richiesta</TableCell>
                          <TableCell>{criticita.descrizione}</TableCell>
                  </TableRow>

                </Table>
                {messaggi.length > 0 && <h5>Messaggi:</h5>}
                {messaggi.length > 0 && <Table dense={false}>
                    <TableHead>
                        <TableRow>
                          <TableCell>Utente</TableCell>
                          <TableCell>Tipo messaggio</TableCell>
                          <TableCell style={{width: '60%'}}>Testo</TableCell>
                          <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.messaggi.map((item, idx) => {
                      return (<TableRow>
                        <TableCell>{item.user?.first_name} {item.user?.last_name}</TableCell>
                          <TableCell>
                            {item.tipo === 1 && <span>Richiesta sblocco</span>}
                            {item.tipo !== 1 && <span>Messaggio</span>}
                          </TableCell>
                          <TableCell  style={{width: '60%'}}>{item.testo}</TableCell>
                          <TableCell>
                            {idx === 0 && item.user?.username === operatore.utente.username && <Button variant="contained" size="small" color="secondary"
                            onClick={this.props.confirm(() => { this.cancellaMessaggio(item.id) }, {
                              title: 'Task',
                              description: "Vuoi cancellare questo messaggio?",
                              confirmationText: 'Sì',
                              cancellationText: 'No'
                              })}
                            >cancella</Button>}
                          </TableCell>
                      </TableRow>)
                    })
                    }
                    </TableBody>
                  </Table>}
                <br/><br/>
                <Grid container spacing={0}>
                  <Grid item xs={12}><b>Invia richiesta:</b></Grid>
                  <Grid item xs={12}>
                      <FormControl >
                        <SingleSelect name={'tipo_messaggio'}
                                  label={'tipo'}
                                  style={{width: 470}}
                                  value={'1'}
                                  defaltValue={messaggio.tipo}
                                  options={[{value: '1', label: 'Richiesta sblocco'}, {value: '2', label: 'Messaggio'}]}
                                  onInput={this.handleInputPrestazione}
                                  onChange={(val)=> {this.setTipoMessaggio(val)}}
                                  />
                      </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                      <FormControl >
                        <TextareaAutosize
                    id='testo_libero'
                    label='Messaggio'
                    value={messaggio.testo}
                    rows={3}
                    style={{width: 470}}
                    placeholder="Messaggio"
                    onChange={(evt) => {
                      this.setTestoMessaggio(evt.target.value)
                    }}
                    />
                      </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={6} align="right">
                  <br/>
                <Tooltip title="invia messaggio">
                              <Button disabled={messaggio.testo.trim()=== ''} variant="contained" size="small" color="primary" onClick={() => { this.inviaMessaggio() }}>
                                Invia
                              </Button>
                            </Tooltip>
                </Grid>


              </Grid>}


                {this.state.ripetibilita === undefined && this.state.lista_ripetibilita !== undefined && this.state.lista_ripetibilita.length > 0 && <Grid item xs={12}>
                  <h2>Avvisi di superamento degli appuntamenti massimi previsti:</h2>
                <Table dense={false}>
                    <TableHead>
                        <TableRow>
                          <TableCell>Paziente</TableCell>
                          <TableCell>Trattamento</TableCell>
                          <TableCell>Elemento</TableCell>
                          <TableCell>Stato</TableCell>
                          <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.lista_ripetibilita.map((item) => {
                      return (<><TableRow>
                        <TableCell><a href={"/gui/pazienti/?pid=" + item.paziente_id}>{item.paziente}</a></TableCell>
                          <TableCell>{item.trattamento}</TableCell>
                           <TableCell>{item.applicato_a}</TableCell>
                        <TableCell><div className={item.class_name}>{item.descrizione_stato}</div></TableCell>
                          <TableCell></TableCell>

                          <TableCell>
                            <Tooltip title="Gestisci il problema legato alla ripetizione">
                              <Button variant="contained" size="small" color="secondary" onClick={() => { this.setRipetibilita(item) }}>
                                Gestisci
                              </Button>
                            </Tooltip>
                          </TableCell>
                      </TableRow>
                      </>)
                    })
                    }
                    </TableBody>
                  </Table>
              </Grid>}
              {ripetibilita !== undefined && <Grid item xs={12}>
                <h2>Dettagli problema ripetizione sedute:</h2>
                <Table dense={false}>
                  <TableRow>
                          <TableCell>Paziente</TableCell>
                          <TableCell>{ripetibilita.paziente})</TableCell>
                  </TableRow>
                  <TableRow>
                          <TableCell>Trattamento</TableCell>
                          <TableCell>{ripetibilita.trattamento}</TableCell>
                  </TableRow>
                  <TableRow>
                          <TableCell>Elemento</TableCell>
                          <TableCell>{ripetibilita.applicato_a}</TableCell>
                  </TableRow>
                  <TableRow>
                          <TableCell>Stato</TableCell>
                          <TableCell>{ripetibilita.descrizione_stato}</TableCell>
                  </TableRow>

                </Table>
                <br/><br/>
                <Grid container spacing={0}>
                  <Grid item xs={12}><b>Note (obbligatorie per la richiesta di autorizzazione):</b></Grid>
                  <Grid item xs={12}>
                      <FormControl >
                        <SingleSelect name={'problemi_trattamento'}
                                  label={'Problemi sul trattamento'}
                                  style={{width: 470}}
                                  value={'1'}
                                  defaltValue={messaggio.tipo}
                                  options={[
                                    {value: '0', label: '----'},
                                    {value: '1', label: 'Errori o mancanze imputabili al laboratorio'},
                                    {value: '2', label: 'Errori o mancanze imputabili all’Odontoiatra'},
                                    {value: '3', label: 'Errori o mancanze imputabili al personale di clinica'},
                                    {value: '4', label: 'Mancata presentazione o rifiuto da parte del paziente'}
                                  ]}
                                  onInput={this.handleInputPrestazione}
                                  onChange={(val)=> {this.setTipoMessaggio(val)}}
                                  />
                      </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                      <FormControl >
                        <TextareaAutosize
                    id='testo_libero_ripetibilita'
                    label='Messaggio'
                    value={messaggioRipetibilita}
                    rows={3}
                    style={{width: 470}}
                    placeholder="Messaggio"
                    onChange={(evt) => {
                      this.setTestoMessaggioRipetibilita(evt.target.value)
                    }}
                    />
                      </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12} align="left">
                <Tooltip title="Il trattamanto è sotto controllo.">
                              <Button variant="contained" size="small" color="primary" onClick={() => { this.dichiaraCompleta() }}>
                                Sotto controllo
                              </Button>
                            </Tooltip>
                  &nbsp;
                <Tooltip title="Il paziente ha determinato la sospensione del trattamento">
                              <Button disabled={messaggioRipetibilita.length < 20} variant="contained" size="small" color="primary" onClick={() => { this.pazienteHaSospeso() }}>
                                Paziente ha sospeso
                              </Button>
                            </Tooltip>
                  &nbsp;
                <Tooltip title="Sono occorsi problemi sul trattamento, si richiede l’aggiunta di sedute extra">
                              <Button disabled={messaggioRipetibilita.length < 20} variant="contained" size="small" color="primary" onClick={() => { this.richiediAutorizzazione() }}>
                                Richiedi autorizzazione
                              </Button>
                            </Tooltip>
                  &nbsp;
                <Tooltip title="Annulla e torna all'elenco">
                              <Button variant="contained" size="small" color="primary" onClick={() => { this.ripetibilitaElenco() }}>
                                Annulla
                              </Button>
                            </Tooltip>
                </Grid>


              </Grid>}






              <Grid item xs={12}>
                <h5>Informazioni applicazione</h5>
              </Grid> 
              <Grid item xs={12}>
                <b>Versione frontend:</b> {process.env.REACT_APP_VERSION}
              </Grid> 
              <Grid item xs={12}>
                <b>Versione backend:</b> {backend_version}
              </Grid>
              <Grid item xs={12}>
                <b>Puntamento backend:</b> {process.env.REACT_APP_API_URL}
              </Grid>
          </Grid>
      </Container>
    )
  }
}
export default withConfirm(withSnackbar(DatiSede))
