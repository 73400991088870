import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { SingleSelect } from "react-select-material-ui"
//import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AssignmentIcon from '@material-ui/icons/Assignment'; //ICONA PER IL TASK
import Account from '@material-ui/icons/AccountBox';
import CalendarIcon from '@material-ui/icons/CalendarTodayTwoTone';
import BusinessIcon from '@material-ui/icons/Business';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import DescriptionIcon from '@material-ui/icons/Description';
import PeopleIcon from '@material-ui/icons/People';
import PersonAdd from '@material-ui/icons/PersonAdd';
import MoreIcon from '@material-ui/icons/MoreVert';
import logo from '../img/logo.png'
import { Link } from '@material-ui/core'
import { BrowserRouter as Router, Route, Link as RouterLink } from "react-router-dom"
import withConfirm from 'material-ui-confirm'
import Tooltip from '@material-ui/core/Tooltip'
import api from '../api_calls/api'

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }
}));

function DCNavBar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  
  function refreshCalendar(event) {
    if (window.scheduler !== undefined) {
      //D.P. tentativo di fix per errore solo su agenda lucca
      try {
        window.scheduler.clearAll();
      } catch (e) {
        console.log(e)
      }
      try {
        window.scheduler.setCurrentView();
      } catch (e) {
        console.log(e)
      }
    }
  }

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleChangePin() {
    props.togglePin();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const menuId = 'primary-search-account-menu';

  const nomeOperatore = api.nomeOperatore()
  const sedeLogin = api.sedeLogin()
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose} component={RouterLink} to="/dati_sede/"> <BusinessIcon /> Sede</MenuItem>

      <MenuItem onClick={handleChangePin}>Gestisci PIN</MenuItem>


      <MenuItem onClick={props.confirm(() => { handleMenuClose(); props.handleLogout() }, {
        title: 'Logout',
        description: "Confermi il logout?",
        confirmationText: 'Sì',
        cancellationText: 'No'
        })}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Link color="inherit" component={RouterLink} to="/calendario/">
            <IconButton color="inherit">
                <CalendarIcon />
            </IconButton>
            Calendario
        </Link>  
      </MenuItem>
      <MenuItem>
        <Link color="inherit" component={RouterLink} to="/nuovo_paziente/">
            <IconButton color="inherit">
                <PersonAdd />
            </IconButton>
            Nuovo Paziente
        </Link>      
      </MenuItem>
      <MenuItem>
        <Link color="inherit" component={RouterLink} to="/pazienti/">
            <IconButton color="inherit">
                <PeopleIcon />
            </IconButton>
            Pazienti
        </Link>      
      </MenuItem>
      <MenuItem>
      <Link color="inherit" component={RouterLink} to="/lista_incassi/">
          <IconButton color="inherit">
              <EuroSymbolIcon />
          </IconButton>
          Incassi
      </Link> 
      </MenuItem> 
      <MenuItem>
      <Link color="inherit" component={RouterLink} to="/lista_fatture/">
          <IconButton color="inherit">
              <DescriptionIcon />
          </IconButton>
          Fatture
      </Link> 
      </MenuItem>           
      <MenuItem onClick={() => { handleMenuClose(); props.handleLogout() }}>
      <Link color="inherit">  
        <IconButton color="inherit">
          <Account />
        </IconButton>
            Logout
        </Link>  
      </MenuItem>
    </Menu>
  );

  let barCss = {}
  if (process.env.REACT_APP_API_URL.toLowerCase().indexOf("test") >= 0 || process.env.REACT_APP_API_URL.indexOf("127") >= 0) {
    barCss = {'background-color': '#A60000'}
  }

  return (
    <div className={classes.grow}>
      <AppBar position="static" style={barCss}>
        <Toolbar>
          {/*<IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Open drawer"
          >
            <MenuIcon />
          </IconButton>*/}
          <Typography className={classes.title} variant="h6" noWrap>
          <img width="100px" src={logo} alt="logo" />{process.env.REACT_APP_DISCLAIMER}
          </Typography>
          {false && props.logged_in && <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Cerca…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'Cerca' }}
            />
          </div>}
          <div id="tab_custom"  className={classes.search}>
          
          </div>
          <div className={classes.grow} />
          {props.logged_in && <div className={classes.sectionDesktop}> 
          <div style={{padding: "10px 0 10px"}}>
            <b>{nomeOperatore} - SEDE {sedeLogin}</b>
          </div> 
          <Tooltip title="Calendario">
            <Link color="inherit" component={RouterLink}  onClick={refreshCalendar} to="/calendario/">
                <IconButton color="inherit">
                    <CalendarIcon />
                </IconButton>
            </Link> 
          </Tooltip>
          <Tooltip title="Nuovo pazientee">
            <Link color="inherit" component={RouterLink} to="/nuovo_paziente/">
                <IconButton color="inherit">
                    <PersonAdd />
                </IconButton>
            </Link>
          </Tooltip>  
          <Tooltip title="Pazienti">
            <Link color="inherit" component={RouterLink} to="/pazienti/">
                <IconButton color="inherit">
                    <PeopleIcon />
                </IconButton>
            </Link>
          </Tooltip>  
          <Tooltip title="Incassi">
            <Link color="inherit" component={RouterLink} to="/lista_incassi/">
                <IconButton color="inherit">
                    <EuroSymbolIcon />
                </IconButton>
            </Link> 
          </Tooltip>
          <Tooltip title="Fatture">
            <Link color="inherit" component={RouterLink} to="/lista_fatture/">
                <IconButton color="inherit">
                    <DescriptionIcon />
                </IconButton>
            </Link> 
          </Tooltip>
          <Tooltip title="Sede corrente">
            <Link color="inherit" component={RouterLink} to="/dati_sede/">
                <IconButton color="inherit">
                    <BusinessIcon />
                </IconButton>
            </Link> 
          </Tooltip>
          <Tooltip  title={nomeOperatore}><>
            <IconButton
              edge="end"
              aria-label="Account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Account />
            </IconButton>
            </></Tooltip>   
          </div>}
          {props.logged_in && <div className={classes.sectionMobile}>
            <IconButton
              aria-label="Show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>}
        </Toolbar>
      </AppBar>
      {props.logged_in && renderMobileMenu}
      {props.logged_in && renderMenu}
    </div>
  );
}

export default withConfirm(DCNavBar)
