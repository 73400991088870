import React, { Component } from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import ListIcon from '@material-ui/icons/List'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
//import DialogPaziente from './dialogs/DialogPaziente'
import Fab from '@material-ui/core/Fab' 
import Tooltip from '@material-ui/core/Tooltip'
import NumberFormat from 'react-number-format'
import { BrowserRouter as Router, Route, Link as RouterLink } from "react-router-dom"
import { Link } from '@material-ui/core'
import { Add, Edit } from '@material-ui/icons'
import Container from '@material-ui/core/Container'
import api from "../api_calls/api"
import MomentUtils from '@date-io/moment'


const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    }, 
      palette: {
        primary: {
          light: '#0000',
          main: '#00000',
          dark: '#0000',
          contrastText: '#fff',
        },
        secondary: {
          light: '#ff7961',
          main: '#f44336',
          dark: '#ba000d',
          contrastText: '#000',
        },
      },
}));


class AppuntamentiPaziente extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      logged_in: localStorage.getItem('token') ? true : false,
      lista_appuntamenti: []
    } 
  }

  
  componentDidMount() {
        this.cercaAppuntamenti({target: {value: ''}})
  }

  vaiAdAppuntamento = (id, sede_id, data) => {
    const sede_corrente_id = parseInt(localStorage.getItem("sede_corrente_id"));
    localStorage.setItem('evt_id', id);
    if (sede_id!==sede_corrente_id) {
      const url_da_eseguire = `${process.env.REACT_APP_API_URL}/dc/set_sede?sede_id=` + sede_id;
      api.call_get(url_da_eseguire, (result) => {
          localStorage.setItem('sede_corrente_id', sede_id);
          let sede_corrente_nome_breve = "";
          let sede_corrente_nome = "";
          var operatore = api.getOperatore();
          for (var i = 0, len = operatore.sedi.length; i < len; i++) {
            if (operatore.sedi[i].id === sede_id) {
              sede_corrente_nome_breve = operatore.sedi[i].nome_breve;
              sede_corrente_nome = operatore.sedi[i].nome;
            }
          }
          localStorage.setItem('sede_corrente_id', sede_id);
          localStorage.setItem('sede_corrente_nome_breve', sede_corrente_nome_breve);
          localStorage.setItem('sede_corrente_nome', sede_corrente_nome);
          window.location.href='/gui/calendario?evt_id=' + id + '&evt_start_date=' + data + '&evt_sede=' + sede_id
      })
    } else {
      window.location.href='/gui/calendario?evt_id=' + id + '&evt_start_date=' + data + '&evt_sede=' + sede_id
    }
  };
  cercaAppuntamenti = (evt) => {
    const url_paz = `${process.env.REACT_APP_API_URL}/dc/appuntamenti_paziente/${this.props.paziente.id}/` 
    api.call_get(url_paz + evt.target.value, (result) => {
      this.setState({
        lista_appuntamenti: result.results
      })
    })
  };


  render() {
    const { paziente }  = this.props
    const moment = new MomentUtils()
    const now = new Date()
    
    return (
      <Container component="main" maxWidth="md">
          <Grid container xs={12}>
            <Grid item xs={12} >
                  <h3>Appuntamenti {paziente.cognome} {paziente.nome}</h3>
                  <Table dense={false}>
                    <TableHead>
                        <TableRow>
                          <TableCell>Sede</TableCell>
                          <TableCell>Data</TableCell>
                          <TableCell>Descrizione</TableCell>
                          <TableCell>
                          
                          <Tooltip title="nuovo appuntamento">
                              <Fab size="small" color="secondary" onClick={() => {window.location.href = "/gui/calendario"}}>
                                <Add/>
                              </Fab>
                            </Tooltip>  
                          
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.lista_appuntamenti.map((item) => {
                      return (<TableRow>
                        <TableCell>{item.sede_nome_breve}</TableCell>
                          {(moment.moment(item.start_date) > now) ? <TableCell style={{color: "#008500"}}>{moment.moment(item.start_date).format("dddd DD/MM/YYYY HH:mm")}</TableCell> : <TableCell>{moment.moment(item.start_date).format("dddd DD/MM/YYYY HH:mm")}</TableCell>}
                          <TableCell>
                            {item.text}<br/>\
                            {item.testo_libero!==null && item.testo_libero}
                          </TableCell>
                          <TableCell>
                            { item.stato !== 'Annullato' &&
                            <Tooltip title="seleziona appuntamento">
                              <Fab size="small" color="secondary" onClick={() => { this.vaiAdAppuntamento(item.id, item.sede, item.start_date) }}>
                                <CheckIcon/>
                              </Fab>
                            </Tooltip>
                            }
                          </TableCell>
                      </TableRow>)
                    })
                    }
                    </TableBody>
                  </Table>
            </Grid>  
             
            <Grid item xs={2} >
                
            </Grid>  
          </Grid>
        
      
      </Container>
    )
  }
}
export default AppuntamentiPaziente
