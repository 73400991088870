import React, {Component} from 'react'
import Scheduler from "../components/Scheduler"
import DatiAppuntamento from "../components/DatiAppuntamento"
import { Grid } from '@material-ui/core';
import { SingleSelect } from "react-select-material-ui"
import TextField from '@material-ui/core/TextField'


import Container from '@material-ui/core/Container' 
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import { blockStatement } from '@babel/types';
import { BrowserRouter as Router, Route, Link as RouterLink } from "react-router-dom"
import history from './history'
import DCNavBar from "./DCNavBar"
import api from "../api_calls/api"
//import { runInThisContext } from 'vm';
// import { gunzip } from 'zlib';


class Calendario extends Component {
/*  constructor(props) {
    super(props);
    console.log("Calendario.constructor");
    let search = window.location.search;
    let pars = new URLSearchParams(search);
    let evt_id = pars.get('evt_id');
    if (evt_id && evt_id !== this.state.evt_id) {
      console.log("Calendario.constructor loadAppuntamento");
      this.loadAppuntamento(evt_id)
    }
  } */
  state = {
    options_pazienti: [],
    evt_chiuso: undefined,
    evt: undefined,
    medici: [],
    sede_corrente: 0,
    survey_answer_id: 0,
    active: 'scheduler',
    evt_id: undefined,
    schedulerState: undefined,
    evt_prima_delle_modifiche: undefined
  }

  setEventPrimaDelleModifiche = (evt_prima_delle_modifiche) => {
    this.setState({
      evt_prima_delle_modifiche: evt_prima_delle_modifiche
    })

  };
  setEvent = (evt, medici, sede_corrente, schedulerState, options_pazienti) => {
    const clone_evt = {...evt}
    this.setState({
      evt: clone_evt,
      medici: medici,
      sede_corrente: sede_corrente,
      active: 'appointment',
      schedulerState: schedulerState,
      options_pazienti: options_pazienti
    })
  }

  salvaEvt = (evt) => {
    this.setState({evt: evt, active: 'scheduler'})
  }

  // evt_chiuso è l'evento che era nella form chiusa; se non è sul db va nascosto nello scheduler
  activateScheduler = (evt_chiuso) => {
    this.setState({
      evt_chiuso: evt_chiuso,
      evt: undefined,
      active: 'scheduler'
    })
  }

  handleInputPazienti = (evt) => {
    const url_paz = `${process.env.REACT_APP_API_URL}/dc/autocomplete_pazienti?q=`
    api.call_get(url_paz + evt.target.value, (result) => {
      const pazienti = result.results.map((row, index) => {
        return (
          {
            value: row,
            label: row.text
          }
        )
      })
      this.setState({
        options_pazienti: pazienti
      })
    })
  }


  componentDidMount() {
/*    let search = window.location.search
    let pars = new URLSearchParams(search)
    let evt_id = pars.get('evt_id')
    if (evt_id && evt_id !== this.state.evt_id) {
      console.log("componentDidMount loadAppuntamento");
      this.loadAppuntamento(evt_id)
    } */
  }

  componentDidUpdate(old) {
/*    let search = window.location.search
    let pars = new URLSearchParams(search)
    let evt_id = pars.get('evt_id')
    if (evt_id) {
      console.log("componentDidUpdate loadAppuntamento");
      this.loadAppuntamento(evt_id)
    }*/
  }

  loadAppuntamento(evt_id) {
    if (this.state.evt === undefined || (this.state.evt !== undefined && this.state.evt.id != evt_id)) {
      const url_app = `${process.env.REACT_APP_API_URL}/dc/appuntamento/${evt_id}/`
      api.call_get(url_app, (res) => {
        if (false && res !== undefined) {
          console.log("loadAppuntamento returned res: " + JSON.stringify(res));
          /*        this.state.schedulerState
                  schedulerState.date
                  schedulerState.mode*/

          this.setState({
            schedulerState: {"mode": "dottori", "date": "2019-1-13T13:31:53.714Z"} // res.start_date
            //evt: res,
            //active: 'appointment'
          }, () => console.log("loadAppuntamento state is set"))
        }
      })
    }
  }

  render() {
    const {options_pazienti} = this.state;
    const {evt, evt_chiuso, active, medici, sede_corrente, survey_answer_id} = this.state;
    return (
      <>
        {active === 'scheduler' &&
        <Scheduler evt={evt} evt_chiuso={evt_chiuso} active={active} schedulerState={this.state.schedulerState}
                   survey_answer_id={survey_answer_id} setEvent={this.setEvent.bind(this)}
                   setEventPrimaDelleModifiche={this.setEventPrimaDelleModifiche.bind(this)}
                   history={history} evt_prima_delle_modifiche={this.state.evt_prima_delle_modifiche} />}
        {active === 'appointment' && <DatiAppuntamento evt={evt} medici={medici} salvaEvt={this.salvaEvt.bind(this)}
                                             evt_prima_delle_modifiche={this.state.evt_prima_delle_modifiche}
                                             sede_corrente={sede_corrente}
                                             handleInputPazienti={this.handleInputPazienti}
                                             options_pazienti={options_pazienti}
                                             activateScheduler={this.activateScheduler.bind(this)}/>}
      </>
    )
  }
}

export default Calendario
