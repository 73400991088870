import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import NumberFormat from 'react-number-format'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import { SingleSelect } from "react-select-material-ui"
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Checkbox from '@material-ui/core/Checkbox'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ListIcon from '@material-ui/icons/List'
import CreateIcon from '@material-ui/icons/Edit'
import PdfIcon from '@material-ui/icons/PictureAsPdf'
import PreviewPdf from '../components/PreviewPdf'
import PreviewImg from '../components/PreviewImg'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Fatture from '../components/Fatture'
import api from '../api_calls/api'

class Fattura extends Component {
    state = {
        paziente: undefined,
        lista_pazienti: [] 
    }


    cercaPazienti = (evt) => {
      const url_paz = `${process.env.REACT_APP_API_URL}/dc/ricerca_pazienti?q=` 
      api.call_get(url_paz + evt.target.value, (result) => {
        const pazienti = result.results
        this.setState({
          lista_pazienti: pazienti
        })
      })
    }
  
    componentDidMount() {
      if (this.props.match && this.props.match.params && this.props.match.params.fattura_id) {
        const fattura_id = this.props.match.params.fattura_id
        
      }
    }

    componentWillUpdate(old) {
      if(old != this.props) {
        if (this.props.match && this.props.match.params && this.props.match.params.fattura_id) {
          const fattura_id = this.props.match.params.fattura_id
        }
      }
    }

    
    render() {
        const options_pazienti = this.state.lista_pazienti.map((row, index) => {
          return (
            {
                value: row,
                label: row.text
            }
          )
        })
        return (
        <Container component="main" maxWidth="md">    
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <FormControl >    
          
          <SingleSelect
                    variant="filled"
                    color="inherit"
                    name='paziente' 
                    label='Seleziona il Paziente'
                    fullWidth={true}
                    value={this.props.paziente}
                    style={{width: "400px"}}
                    options={options_pazienti}
                    onInput={
                      this.cercaPazienti
                    } 
                    onChange={(val) => {
                      this.props.setPaziente(val)
                    }}
                    SelectProps={{
                      msgNoOptionsAvailable: "Digita parte del nome del paziente",
                      msgNoOptionsMatchFilter: "Non ci sono pazienti che corrispondono alla ricerca"
                    }}
           />
          </FormControl>
          </Grid>
          <Grid>
            {this.props.paziente &&
              <Fatture paziente_id={this.props.paziente.id} paziente={this.props.paziente}/>
            }
          </Grid>
        </Grid> 
        </Container> 
        )

    }
      

}
export default Fattura;